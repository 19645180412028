import React from 'react';
import { AuthGuard } from 'src/domains/system/auth/guards/AuthGuard';

/**
 * Higher-order component that wraps a component with full authentication guard.
 * @template P - The props type of the component.
 * @param Component - The component to be wrapped.
 * @returns A new component that includes the full authentication guard.
 */
export const withFullAuthGuard = <P extends object>(Component: React.FC<P>): React.FC<P> => {
  return function WithFullAuthGuard(props: P) {
    return (
      <AuthGuard fullAuthentication={true}>
        <Component {...props} />
      </AuthGuard>
    );
  };
};

/**
 * Higher-order component that adds a two-factor authentication guard to a component.
 * @template P - The props type of the component.
 * @param {React.FC<P>} Component - The component to wrap with the two-factor authentication guard.
 * @returns {React.FC<P>} - The wrapped component with the two-factor authentication guard.
 */
export const withTwoFactorAuthGuard = <P extends object>(Component: React.FC<P>): React.FC<P> => {
  return function WithTwoFactorAuthGuard(props: P) {
    return (
      <AuthGuard fullAuthentication={false}>
        <Component {...props} />
      </AuthGuard>
    );
  };
};
