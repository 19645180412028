import Box from '@mui/material/Box';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Stepper from '@mui/material/Stepper';
import { t } from 'i18next';
import * as React from 'react';
import { tokens } from 'src/locales/tokens';

const config = [
  {
    id: 1,
    label: t(tokens.domains.contract.stepper.stepOne),
  },
  {
    id: 2,
    label: t(tokens.domains.contract.stepper.stepTwo),
  },
  {
    id: 3,
    label: t(tokens.domains.contract.stepper.stepThree),
  },
];

interface VerticalLinearStepperProps {
  step: number;
}

export const VerticalLinearStepper: React.FC<VerticalLinearStepperProps> = ({ step }) => {
  return (
    <Box sx={{ maxWidth: 400 }}>
      <Stepper
        activeStep={step - 1}
        orientation="vertical"
      >
        {config.map((item) => (
          <Step key={item.label}>
            <StepLabel
              sx={{
                '& .MuiStepLabel-label.Mui-active': {
                  color: item.id === step ? 'primary.main' : 'neutral.400',
                },
                '& .MuiSvgIcon-root': {
                  color: item.id === step ? 'primary.main' : 'neutral.400',
                },
              }}
            >
              {item.label}
            </StepLabel>
          </Step>
        ))}
      </Stepper>
    </Box>
  );
};
