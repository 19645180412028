import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider } from '@mui/material/styles';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import React from 'react';
import { Helmet } from 'react-helmet-async';
import { useRoutes } from 'react-router';
import { RTL } from 'src/components/rtl';
import { AuthProvider } from 'src/contexts/auth/jwt';
import 'src/locales/i18n';
import './global.css';

import { useCreateTheme } from './hooks/useCreateTheme';
import { useMaintenance } from './hooks/useMaintenance';
import { useSettings } from './hooks/useSettings';
import { routes } from './routes';

const App: React.FC = () => {
  const element = useRoutes(routes);
  const settings = useSettings();
  const theme = useCreateTheme(settings);
  useMaintenance();

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <AuthProvider>
        <ThemeProvider theme={theme}>
          <Helmet>
            <meta
              name="color-scheme"
              content={settings.paletteMode}
            />
            <meta
              name="theme-color"
              content={theme.palette.neutral[900]}
            />
          </Helmet>
          <CssBaseline />
          <RTL direction={settings.direction}>{element}</RTL>
        </ThemeProvider>
      </AuthProvider>
    </LocalizationProvider>
  );
};

export default App;
