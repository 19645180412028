import { Box } from '@mui/material';
import React from 'react';
import { ListBase, useResourceContext } from 'react-admin';

interface ListBaseLayoutProps {
  children: React.ReactNode;
}

export const ListBaseLayout: React.FC<ListBaseLayoutProps> = ({ children }) => {
  const resource = useResourceContext();

  return (
    <Box sx={{ position: 'relative' }}>
      <ListBase resource={resource}>{children}</ListBase>
    </Box>
  );
};
