// RegisterComponent.tsx
import React, { useCallback, useEffect, useState } from 'react';
import { AsSplitButtonFilterRenderOption } from 'src/common/components/filters/SplitButtonFilter/SplitButtonFilterRenderOption';
import { useTaggedFilterContext } from 'src/contexts/common/filters/tagged-filter-context';
import {
  TaggedFilterConfiguration,
  TaggedFilterOption,
} from 'src/contexts/common/filters/tagged-filter-context/types';
import { compareArraysOfObjects } from 'src/utils/arrayCompare';

interface WithValueComponentProps {
  value: string;
  label: string;
  options: TaggedFilterOption[];
  onOptionChanged: (options: TaggedFilterOption[]) => void;
}

interface TaggedFilterComponentProps {
  component: React.FC<WithValueComponentProps>;
  value: string;
  label: string;
  options?: TaggedFilterOption[];
  showLabel?: boolean;
  setFilters: (
    filters: { [key: string]: string[] | Date },
    displayedFilters: unknown,
    debounce?: boolean
  ) => void;
  filterValues: { [key: string]: string[] | Date };
  optionLabel?: string;
  renderOption?: React.FC<AsSplitButtonFilterRenderOption>;
  resource?: string;
  minDate?: Date;
  maxDate?: Date;
  setStartDate?: (date: Date | undefined) => void;
  setEndDate?: (date: Date | undefined) => void;
}

const TaggedFilter: React.FC<TaggedFilterComponentProps> = ({
  component: Component,
  value,
  label,
  showLabel = false,
  options = [],
  ...rest
}) => {
  const { registerFilter, setOptionsToFilter } = useTaggedFilterContext();

  const [stateOptions, setStateOptions] = useState<TaggedFilterOption[]>(options);

  useEffect(() => {
    const filterConfig: TaggedFilterConfiguration = {
      label: label,
      showLabel: showLabel,
    };

    if (options.length > 0) {
      filterConfig.options = options;
    }

    registerFilter(value, filterConfig);
  }, [Component, registerFilter, value, label, showLabel, options, setStateOptions, stateOptions]);

  const setOptions = useCallback(
    (options: TaggedFilterOption[]) => {
      setOptionsToFilter(value, options);
      if (!compareArraysOfObjects(stateOptions, options)) {
        setStateOptions(options);
      }
    },
    [setOptionsToFilter, stateOptions, value]
  );

  return (
    <Component
      {...rest}
      value={value}
      options={stateOptions}
      label={label}
      onOptionChanged={setOptions}
    />
  );
};

export default TaggedFilter;
