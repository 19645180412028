import CloseIcon from '@mui/icons-material/Close';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import IconButton from '@mui/material/IconButton';
import { useMemo } from 'react';

export interface UseAsyncAlertParams {
  isLoading: boolean;
  isSuccess: boolean;
  isError: boolean;
  loadingMessage: string;
  successMessage: string;
  errorMessage: string;
  clearMessages: () => void;
  resetStatus: () => void;
}

export const useAsyncAlert = ({
  isLoading,
  isSuccess,
  isError,
  loadingMessage,
  successMessage,
  errorMessage,
  clearMessages,
  resetStatus,
}: UseAsyncAlertParams) => {
  const alertStatus = useMemo(() => {
    if (isLoading) {
      return { severity: 'info', message: loadingMessage };
    } else if (isError) {
      return { severity: 'error', message: errorMessage };
    } else if (isSuccess) {
      return { severity: 'success', message: successMessage };
    }
    return null;
  }, [isLoading, isError, isSuccess, loadingMessage, errorMessage, successMessage]);

  const AlertComponent = useMemo(() => {
    if (!alertStatus) return null;
    if (isLoading && !successMessage)
      return (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          height="100%"
        >
          <CircularProgress />
        </Box>
      );

    return (
      <Alert
        severity={alertStatus.severity as 'error' | 'info' | 'success'}
        action={
          <IconButton
            aria-label="close"
            color="inherit"
            size="small"
            onClick={() => {
              clearMessages();
              resetStatus();
            }}
          >
            <CloseIcon fontSize="inherit" />
          </IconButton>
        }
      >
        {alertStatus.message}
      </Alert>
    );
  }, [alertStatus, clearMessages, isLoading, resetStatus, successMessage]);

  return AlertComponent;
};
