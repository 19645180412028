import React from 'react';
import { SettingsDrawer } from 'src/components/settings/settings-drawer';
import { SettingsButton } from 'src/components/settings/SettingsButton';
import { Toaster } from 'src/components/toaster';
import { withFullAuthGuard } from 'src/domains/system/auth/hocs/withFullAuthGuard';
import { useSettings } from 'src/hooks/useSettings';

import Admin from './admin';

const AdminContainer: React.FC = () => {
  const settings = useSettings();
  return (
    <>
      <Admin />
      <SettingsButton onClick={settings.handleDrawerOpen} />
      <SettingsDrawer
        canReset={settings.isCustom}
        onClose={settings.handleDrawerClose}
        onReset={settings.handleReset}
        onUpdate={settings.handleUpdate}
        open={settings.openDrawer}
        values={{
          colorPreset: settings.colorPreset,
          contrast: settings.contrast,
          direction: settings.direction,
          paletteMode: settings.paletteMode,
          responsiveFontSizes: settings.responsiveFontSizes,
          stretch: settings.stretch,
          layout: settings.layout,
          navColor: settings.navColor,
        }}
      />
      <Toaster />
    </>
  );
};

export default withFullAuthGuard(AdminContainer);
