import { Card, Stack } from '@mui/material';
import { t } from 'i18next';
import { CardTitle } from 'src/domains/club/members/detail/common/CardTitle';
import { CustomTextLabelSkeleton } from 'src/domains/club/members/skeletons/CustomTextLabelSkeleton';
import { tokens } from 'src/locales/tokens';

export const PracticalInfosSkeleton: React.FC = () => {
  return (
    <Card
      sx={{
        display: 'flex',
        flexDirection: 'column',
        flexBasis: '50%',
      }}
    >
      <CardTitle text={t(tokens.domains.clubs.detail.practicalInfoCardtitle)} />
      <Stack flexDirection={'row'}>
        <Stack
          flexGrow="1"
          padding={'0px 24px 30px 24px'}
          spacing={4}
          flexBasis={'50%'}
        >
          <CustomTextLabelSkeleton label={t(tokens.domains.members.detail.label.clubAddress)} />
          <CustomTextLabelSkeleton label={t(tokens.domains.members.detail.label.meetings)} />
        </Stack>
      </Stack>
    </Card>
  );
};
