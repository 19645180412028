/**
 * Function to capitalize the first letter of a string.
 * @param str - The string to capitalize.
 * @returns A new string with the first letter capitalized.
 * @example capitalizeFirstLetter('hello world') // Output: "Hello world"
 */
export const capitalizeFirstLetter = (str: string) => {
  if (!str) return str;
  return str.charAt(0).toUpperCase() + str.slice(1);
};
