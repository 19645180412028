import { Box } from '@mui/material';
import { t } from 'i18next';
import React from 'react';
import { Identifier, RaRecord, TextField } from 'react-admin';
import { TopLabelField } from 'src/components/react-admin/core/fields';
import { tokens } from 'src/locales/tokens';

interface SubAgencyFieldsProps {
  record: RaRecord<Identifier>;
}

export const SubAgencyFields: React.FC<SubAgencyFieldsProps> = ({ record }) => {
  const parentAgencyName = record?.club?.parentAgencyName as string;

  return (
    <>
      {parentAgencyName ? (
        <Box flexBasis="25%">
          <TopLabelField label={t(tokens.domains.members.detail.label.subAgency)}>
            <TextField source={'club.agencyName'} />
          </TopLabelField>
        </Box>
      ) : (
        <Box flexBasis="25%">
          <TopLabelField label={t(tokens.domains.members.detail.label.subAgency)}>-</TopLabelField>
        </Box>
      )}
    </>
  );
};
