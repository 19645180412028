import Grid from '@mui/material/Grid';
import { t } from 'i18next';
import { TextField } from 'react-admin';
import { PhoneField } from 'src/components/react-admin/core/fields';
import { LabeledField } from 'src/components/react-admin/core/fields/layout/LabeledField';
import { TicketUserEmailField } from 'src/domains/club/helpdesk/components/show/content/user/TicketUserEmailField';
import { tokens } from 'src/locales/tokens';

export const HelpdeskLoggedUserCard = () => {
  return (
    <Grid
      container
      spacing={2}
    >
      <Grid
        item
        xl={4}
        md={6}
        xs={12}
      >
        <LabeledField
          source={'fullName'}
          component={TextField}
          label={t(tokens.domains.helpdesk.detail.applicant.fullname)}
        />
      </Grid>
      <Grid
        item
        xl={4}
        md={6}
        xs={12}
      >
        <LabeledField
          source={'jobTitle'}
          component={TextField}
          label={t(tokens.domains.helpdesk.detail.applicant.job)}
        />
      </Grid>
      <Grid
        item
        xl={4}
        md={6}
        xs={12}
      >
        <LabeledField
          source={'companyName'}
          label={t(tokens.domains.helpdesk.detail.applicant.organization)}
          component={TextField}
        />
      </Grid>
      <Grid
        item
        xl={4}
        md={6}
        xs={12}
      >
        <LabeledField
          source={'phone'}
          label={t(tokens.domains.helpdesk.detail.applicant.phone)}
          component={PhoneField}
        />
      </Grid>
      <Grid
        item
        xl={4}
        md={6}
        xs={12}
      >
        <LabeledField
          source={'email'}
          label={t(tokens.domains.helpdesk.detail.applicant.email)}
          component={TicketUserEmailField}
        />
      </Grid>
      <Grid
        item
        xl={4}
        md={6}
        xs={12}
      >
        <LabeledField label={t(tokens.domains.helpdesk.detail.applicant.department)}>
          <TextField source={'department'} />
        </LabeledField>
      </Grid>
    </Grid>
  );
};

export default HelpdeskLoggedUserCard;
