import { ActionType, AuthAction, InitialState } from 'src/types/contexts/auth';

export const initialState: InitialState = {
  isAuthenticatedFully: false,
  isInitialized: false,
  isAuthenticatedTwoFactorInProgress: false,
  user: null,
  email: null,
  passwordReset: false,
};

/**
 * Reducer function for the authentication state.
 * @param state - The current state of the authentication.
 * @param action - The action to be performed on the authentication state.
 * @returns The new state after applying the action.
 */
export const authReducer = (state: InitialState, action: AuthAction): InitialState => {
  switch (action.type) {
    case ActionType.INITIALIZE: {
      const { isAuthenticatedFully, user } = action.payload;
      return {
        ...state,
        isAuthenticatedFully: isAuthenticatedFully,
        isInitialized: true,
        user,
      };
    }
    case ActionType.SIGN_IN: {
      return {
        ...state,
        isAuthenticatedTwoFactorInProgress: true,
      };
    }
    case ActionType.SIGN_IN_2FA: {
      const { user } = action.payload;
      return {
        ...state,
        isAuthenticatedFully: true,
        isAuthenticatedTwoFactorInProgress: false,
        user,
      };
    }
    case ActionType.SIGN_OUT: {
      return {
        ...state,
        isAuthenticatedFully: false,
        isAuthenticatedTwoFactorInProgress: false,
        user: null,
      };
    }

    case ActionType.RESET_PASSWORD: {
      const { email, passwordReset } = action.payload;
      return {
        ...state,
        email,
        passwordReset,
      };
    }
    default:
      return state;
  }
};
