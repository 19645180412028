import { useTheme } from '@mui/material/styles';
import PropTypes from 'prop-types';

export const LogoDynabuyIco = ({ overflow = 'hidden' }) => {
  const {
    palette: { mode },
  } = useTheme();

  const fillColor = mode === 'light' ? '#000' : '#fff';

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      overflow={overflow}
      viewBox="0 0 45.844 23.7"
    >
      <g
        id="D__SurFondMyrtille"
        data-name="D+_SurFondMyrtille"
        transform="translate(22.922 11.85)"
      >
        <g
          id="Groupe_3604"
          data-name="Groupe 3604"
          transform="translate(-22.922 -11.85)"
        >
          <path
            id="top_logo"
            data-name="Tracé 23058"
            d="M11.684,5.249a4.729,4.729,0,0,0-1.7-3.835A6.3,6.3,0,0,0,5.815,0,6.011,6.011,0,0,0,1.7,1.493,5.04,5.04,0,0,0,0,5.464,4.734,4.734,0,0,0,1.008,8.639,3.528,3.528,0,0,0,3.849,9.823h7.835Z"
            fill="#ee4540"
          />
          <path
            id="Tracé_23059"
            data-name="Tracé 23059"
            d="M486.5,184.671V173.1h10a8.168,8.168,0,0,1,6.4,2.578,9.664,9.664,0,0,1,2.317,6.716,8.675,8.675,0,0,1-3,6.926,10.564,10.564,0,0,1-7.135,2.578,10.1,10.1,0,0,1-6.206-1.782,6.358,6.358,0,0,1-2.382-5.444m4.345-8.039v7.619A3.427,3.427,0,0,0,492,187.013a4.623,4.623,0,0,0,3.09.983,4.893,4.893,0,0,0,3.861-1.649,5.845,5.845,0,0,0,1.453-4.005,6.135,6.135,0,0,0-1.374-4.059,4.6,4.6,0,0,0-3.73-1.651Z"
            transform="translate(-472.72 -168.197)"
            fill={fillColor}
          />
        </g>
      </g>
    </svg>
  );
};

LogoDynabuyIco.propTypes = {
  overflow: PropTypes.string,
};
