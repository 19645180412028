import { Stack } from '@mui/material';
import { t } from 'i18next';
import { ShowBase } from 'react-admin';
import { BreadCrumb } from 'src/common/components/breadcrumb';
import { DetailLayout } from 'src/common/layouts/react-admin/DetailLayout';
import { tokens } from 'src/locales/tokens';
import { paths } from 'src/paths';

import { ClubDetailHeader } from './components/ClubDetailHeader';
import { ClubInfos, PracticalInfos, PresidentDetail } from './detail/siege';

export const ClubShow = () => {
  const path = paths.club.clubs.list;
  return (
    <ShowBase>
      <DetailLayout seoTitle={t(tokens.seo.club.title)}>
        <BreadCrumb
          linkText={t(tokens.breadcrumb.clubList)}
          to={path}
        />
        <ClubDetailHeader />
        <Stack
          flexDirection={'row'}
          gap={'24px'}
        >
          <PresidentDetail />
          <ClubInfos />
        </Stack>
        <PracticalInfos />
      </DetailLayout>
    </ShowBase>
  );
};
