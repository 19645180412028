import Stack from '@mui/system/Stack';
import React, { useCallback } from 'react';
import { useNotify, useRecordContext, useResourceContext, useUpdate } from 'react-admin';
import HelpdeskTicketStatusChipField from 'src/domains/club/helpdesk/components/list/fields/HelpdeskTicketStatusChipField';
import { getLocalHelpdeskTicketStatusList } from 'src/domains/club/helpdesk/data';
import { HelpdeskStatus } from 'src/domains/club/helpdesk/types';

const HelpdeskTicketStatusToggleField: React.FC = () => {
  const resource = useResourceContext();
  const availableStatuses = getLocalHelpdeskTicketStatusList();
  const record = useRecordContext();
  const [update, { isLoading }] = useUpdate();
  const notify = useNotify();

  const handleUpdate = useCallback(
    (status: HelpdeskStatus) => {
      const { id } = record;
      void update(resource, {
        id,
        data: { status: status.value },
        previousData: record,
      }).then(() => notify('La demande a été mise à jour'));
    },
    [record, update, resource, notify]
  );

  return (
    <Stack
      direction={'column'}
      spacing={1}
    >
      {availableStatuses.map((item) => (
        <HelpdeskTicketStatusChipField
          key={`${item.value}_select`}
          status={item}
          disabled={isLoading || status === item.value}
          onClick={() => handleUpdate(item)}
        />
      ))}
    </Stack>
  );
};
export default HelpdeskTicketStatusToggleField;
