import { Card, Stack } from '@mui/material';
import { t } from 'i18next';
import { EditBase } from 'react-admin';
import { useMutationOptions } from 'src/common/hooks';
import { CardTitle } from 'src/domains/club/members/detail/common/CardTitle';
import { tokens } from 'src/locales/tokens';
import { deepEqual } from 'src/utils/deepEqual';

import { ClubInfoContent } from '.';

export const ClubInfos = () => {
  const transform = <T,>(data: Partial<T>, options?: { previousData?: Partial<T> }): string => {
    const previousData: Partial<T> = options?.previousData ?? {};

    const patchData: Partial<T> = {};

    Object.keys(data).forEach((key) => {
      const dataValue = data[key as keyof T];
      const prevDataValue = previousData[key as keyof T];

      if (!deepEqual(dataValue, prevDataValue)) {
        patchData[key as keyof T] = dataValue;
      }
    });
    return JSON.stringify(patchData);
  };

  const mutationOptions = useMutationOptions({
    vertical: 'top',
    horizontal: 'center',
    successLabel: t(tokens.domains.clubs.detail.notify.success),
    failLabel: t(tokens.domains.clubs.detail.notify.fail),
  });

  return (
    <Card
      sx={{
        display: 'flex',
        flexDirection: 'column',
        flexBasis: '50%',
      }}
    >
      <CardTitle text={t(tokens.domains.clubs.detail.clubCardtitle)} />
      <Stack flexDirection={'row'}>
        <Stack
          flexGrow="1"
          padding={'0px 24px 30px 24px'}
          spacing={4}
          flexBasis={'50%'}
        >
          <EditBase
            transform={transform}
            redirect={false}
            mutationMode="pessimistic"
            mutationOptions={mutationOptions}
          >
            <ClubInfoContent />
          </EditBase>
        </Stack>
      </Stack>
    </Card>
  );
};
