import { CircularProgress, Typography } from '@mui/material';
import React from 'react';
import { useRecordContext } from 'react-admin';
import { Member } from 'src/admin-api-types';
import { HeaderLayout } from 'src/common/layouts/react-admin';

export const DetailHeader: React.FC = () => {
  const record = useRecordContext<Member>();

  if (!record) {
    return <CircularProgress size={24} />;
  }

  return (
    <HeaderLayout>
      <>
        <Typography variant="h4">{`${record.user?.fullName}`}</Typography>
      </>
      <></>
    </HeaderLayout>
  );
};
