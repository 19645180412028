import { useReducer, Reducer } from 'react';
import {
  asyncStatusReducer,
  asyncStatusReducerInitialState,
} from 'src/common/reducers/async-status-reducer';
import {
  AsyncStatusReducerAction,
  AsyncStatusReducerState,
} from 'src/types/reducers/async-status-reducer-types';

/**
 * Custom hook for managing the async status of an operation.
 * It provides the current status and a dispatch function to update the status.
 */
export const useAsyncStatus = () => {
  const [asyncStatusState, dispatch] = useReducer<
    Reducer<AsyncStatusReducerState, AsyncStatusReducerAction>
  >(asyncStatusReducer, asyncStatusReducerInitialState);
  const { isInitial, isLoading, isSuccess, isError } = asyncStatusState;

  return { isInitial, isLoading, isSuccess, isError, dispatch };
};
