import React, { useCallback, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useAuth } from 'src/domains/system/auth/hooks/useAuth';
import { useRouter } from 'src/hooks/useRouter';
import { paths } from 'src/paths';

interface AuthGuardProps {
  children: React.ReactNode;
  fullAuthentication: boolean;
}

const loginPaths = {
  login: paths.auth.login,
  two_factor: paths.auth.two_factor,
  forgot_password: paths.auth.forgot_password,
};

const getReturnToRoute = (targetRoute: string) => {
  const searchParams = new URLSearchParams({ returnTo: window.location.pathname }).toString();
  return targetRoute + `?${searchParams}`;
};

/**
 * A guard component that checks the authentication status and redirects the user if necessary.
 * @param {Object} props - The component props.
 * @param {ReactNode} props.children - The child components to render if the user is authenticated.
 * @param {boolean} props.fullAuthentication - Flag indicating whether full authentication is required.
 * @returns {ReactNode|null} - The child components if the user is authenticated, otherwise null.
 */
export const AuthGuard: React.FC<AuthGuardProps> = (props) => {
  const { children, fullAuthentication } = props;
  const router = useRouter();
  const location = useLocation();
  const currentPath = location.pathname;
  const { isAuthenticatedFully, isAuthenticatedTwoFactorInProgress, isInitialized } = useAuth();
  const [authenticated, setAuthenticated] = useState(false);
  const check = useCallback(
    (fullAuthentication: boolean) => {
      if (fullAuthentication) {
        if (!isAuthenticatedFully) {
          if (currentPath === loginPaths.forgot_password) {
            return;
          }
          router.replace(
            getReturnToRoute(
              isAuthenticatedTwoFactorInProgress ? loginPaths.two_factor : loginPaths.login
            )
          );
        } else {
          setAuthenticated(true);
        }
      } else {
        if (!isAuthenticatedTwoFactorInProgress && !isAuthenticatedFully) {
          router.replace(getReturnToRoute(loginPaths.login));
        } else {
          if (isAuthenticatedFully) {
            router.replace('/');
          } else {
            setAuthenticated(isAuthenticatedTwoFactorInProgress);
          }
        }
      }
    },
    [currentPath, isAuthenticatedFully, isAuthenticatedTwoFactorInProgress, router]
  );

  // Only check on mount, this allows us to redirect the user manually when auth state changes
  useEffect(
    () => {
      if (isInitialized) {
        check(fullAuthentication);
      }
    }, // eslint-disable-next-line react-hooks/exhaustive-deps
    [isAuthenticatedFully, isAuthenticatedTwoFactorInProgress, isInitialized]
  );

  if (!authenticated) {
    return null;
  }
  // If got here, it means that the redirect did not occur, and that tells us that the user is
  // authenticated / authorized.

  return <>{children}</>;
};
