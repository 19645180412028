import { t } from 'i18next';
import { tokens } from 'src/locales/tokens';

export const presidentRemunerationOptions = [
  {
    id: 'value0',
    name: t(
      tokens.domains.contract.stepOne.generalInfos.presidentRemuneration.options.defaultOption
    ),
    disabled: true,
  },
  { id: 'value1', name: '20%' },
  { id: 'value2', name: '25%' },
  { id: 'value3', name: '30%' },
  { id: 'value4', name: '35%' },
  { id: 'value5', name: '40%' },
  { id: 'value6', name: '45%' },
  { id: 'value7', name: '50%' },
];
