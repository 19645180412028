export const mapOwnerRate = (ownerRate: string): number => {
  const ownerRateMapping: { [key: string]: number } = {
    value1: 20,
    value2: 25,
    value3: 30,
    value4: 35,
    value5: 40,
    value6: 45,
    value7: 50,
  };
  return ownerRateMapping[ownerRate] || 0;
};
