export enum FormatOptionValuesEnum {
  monthly = 'monthly',
  fortnightly = 'fortnightly',
  weekly = 'weekly',
}

export enum StatusOptionValuesEnum {
  ACTIVE = 'active',
  INACTIVE = 'inactive',
  INITIAL = 'initial',
  DECLINED = 'declined',
  VOIDED = 'voided',
}

export enum TypeOptionValuesEnum {
  president = 'president',
  member = 'member',
}
