import { getRequestHeaders } from './getRequestHeaders';

interface OptionsConfig {
  headers: HeadersInit;
  method: string;
  body?: string;
}

export const getRequestOptions = (method: string, body?: unknown, contentType?: string) => {
  const options: OptionsConfig = {
    headers: getRequestHeaders(contentType),
    method: method,
  };

  if (body !== undefined) {
    options.body = JSON.stringify(body);
  }

  return options;
};
