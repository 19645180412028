import List from '@mui/material/List';
import React from 'react';

interface PropertyListProps {
  children: React.ReactNode;
}

export const PropertyList: React.FC<PropertyListProps> = ({ children }) => {
  return <List disablePadding>{children}</List>;
};
