export const translateContractStatus = (status: string | null | undefined) => {
  switch (status) {
    case 'start':
      return 'Démarré';
    case 'active':
      return 'En cours';
    case 'declined':
      return 'Refusé';
    case 'voided':
      return 'Annulé';
    case 'terminated':
      return 'Terminé';
    case 'error':
      return 'Erreur';
    case 'transition_active':
      return 'Transition en cours';
    case 'transition_declined':
      return 'Transition refusée';
    case 'transition_voided':
      return 'Transition annulée';
    case 'transition_terminated':
      return 'Transition terminée';
    case 'transition_error':
      return 'Transition erreur';
    default:
      return 'Inconnu';
  }
};
