import { ReactNode } from 'react';
import { useAsyncAlert } from 'src/common/hooks/useAsyncAlert';
import { useAsyncMessage } from 'src/common/hooks/useAsyncMessage';
import { useAsyncStatus } from 'src/common/hooks/useAsyncStatus';
import { AsyncStatusAction } from 'src/types/enums';

import { AsyncFeedbackContext } from './AsyncFeedbackContext';

export const AsyncFeedbackProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const { isLoading, isSuccess, isError, dispatch } = useAsyncStatus();

  const {
    loadingMessage,
    successMessage,
    errorMessage,
    noUpdateMessage,
    clearMessages,
    defineLoading,
    defineSuccess,
    defineError,
    defineNoUpdate,
  } = useAsyncMessage();

  const AlertComponent = useAsyncAlert({
    isLoading,
    isSuccess,
    isError,
    loadingMessage,
    successMessage,
    errorMessage,
    clearMessages,
    resetStatus: () => dispatch({ type: AsyncStatusAction.INIT }),
  });

  return (
    <AsyncFeedbackContext.Provider
      value={{
        isLoading,
        isSuccess,
        isError,
        loadingMessage,
        successMessage,
        errorMessage,
        noUpdateMessage,
        clearMessages,
        dispatch,
        defineLoading,
        defineSuccess,
        defineError,
        defineNoUpdate,
        AlertComponent,
      }}
    >
      {children}
    </AsyncFeedbackContext.Provider>
  );
};
