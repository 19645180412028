import { Typography } from '@mui/material';
import { t } from 'i18next';
import React from 'react';
import { HeaderLayout } from 'src/common/layouts/react-admin/HeaderLayout';
import { tokens } from 'src/locales/tokens';

export const Header: React.FC = () => {
  return (
    <HeaderLayout>
      <>
        <Typography variant="h4">{t(tokens.domains.contract.contractHeaderTitle)}</Typography>
      </>
      <></>
    </HeaderLayout>
  );
};
