import { t } from 'i18next';
import { FirstStepType, ValidationError } from 'src/domains/club/contract/context';
import { tokens } from 'src/locales/tokens';

export const validateDayMatches = (values: FirstStepType, errors: ValidationError) => {
  const selectedDate = values.dayWeek;
  if (!selectedDate) {
    return;
  }
  const match = selectedDate.match(/\d+$/);
  if (match) {
    const selectedDay = parseInt(match[0], 10);
    const date = new Date(values.startEvent || '');
    const dayOfWeek = date.getDay();
    if (dayOfWeek !== selectedDay) {
      errors.startEvent = t(tokens.domains.contract.validators.startEventNotMatchingDayWeek);
    }
  }
};
