import { Box, Typography } from '@mui/material';

interface CardTitleProps {
  text: string;
}

export const CardTitle: React.FC<CardTitleProps> = ({ text }) => {
  return (
    <Box padding="32px 24px">
      <Typography
        fontSize="18px"
        fontWeight="bold"
      >
        {text}
      </Typography>
    </Box>
  );
};
