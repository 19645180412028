import { useTheme } from '@mui/material/styles';

export const LogoDynabuyFull = () => {
  const {
    palette: { mode },
  } = useTheme();

  const fillColor = mode === 'light' ? '#000' : '#fff';
  const fillColorRed = '#EE4540'; // You can adjust this if needed
  const fillColorDarkRed = '#C72C41'; // You can adjust this if needed

  return (
    <svg
      className="fill-base"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 735.61 356.67"
      style={{ enableBackground: 'new 0 0 735.61 356.67' }}
      xmlSpace="preserve"
    >
      <style type="text/css">
        {`
          .st0{fill:${fillColor};}
          .st1{fill:${fillColorRed};}
          .st2{fill:#FFFFFF;}
          .st3{fill:${fillColorDarkRed};}
        `}
      </style>
      <path
        className="st0"
        d="M59.45,245.2v-51.32h44.36c12.08,0,21.54,3.81,28.39,11.44c6.85,7.63,10.28,17.55,10.28,29.78
        c0,12.85-4.43,23.09-13.3,30.71c-8.86,7.63-19.41,11.44-31.64,11.44c-11.3,0-20.48-2.63-27.52-7.9
        C62.97,264.09,59.45,256.04,59.45,245.2z M78.73,209.55v33.79c0,5.27,1.7,9.35,5.11,12.25c3.41,2.9,7.97,4.35,13.7,4.35
        c7.12,0,12.83-2.44,17.13-7.31c4.3-4.88,6.44-10.8,6.44-17.77c0-7.12-2.03-13.12-6.1-18c-4.06-4.88-9.58-7.31-16.55-7.31H78.73z"
      />
      <path
        className="st0"
        d="M198.44,297.22h-20.78c0.39-0.31,0.87-0.75,1.45-1.34c0.58-0.58,1.63-1.82,3.14-3.72
        c1.51-1.9,2.85-3.87,4.01-5.92c1.16-2.05,2.26-4.61,3.31-7.66c1.04-3.06,1.61-6.13,1.68-9.23c-3.87,5.11-9.64,7.66-17.3,7.66
        c-16.02,0-24.04-8.17-24.04-24.5v-37.5h17.77v32.05c0,4.8,0.81,8.44,2.44,10.91c1.63,2.48,4.57,3.72,8.82,3.72
        c8.44,0,12.66-4.84,12.66-14.51v-32.16h17.77v51.67c0,5.57-0.91,10.9-2.73,15.97c-1.82,5.07-3.66,8.77-5.52,11.09L198.44,297.22z"
      />
      <path
        className="st0"
        d="M248.6,229.29c-7.97,0-11.96,4.76-11.96,14.28v32.28h-17.77v-36.69c0-8.44,2.73-14.76,8.19-18.98
        c5.46-4.22,12.64-6.33,21.54-6.33c8.9,0,16.08,2.11,21.54,6.33c5.46,4.22,8.19,10.55,8.19,18.98v36.69h-17.77v-32.28
        C260.56,234.05,256.57,229.29,248.6,229.29z"
      />
      <path
        className="st0"
        d="M354.84,247.17v28.68h-17.42v-7.55c-5.19,5.65-12.12,8.48-20.78,8.48c-9.14,0-16.66-2.82-22.58-8.48
        c-5.92-5.65-8.88-13.12-8.88-22.41c0-9.37,3.19-17.05,9.58-23.05c6.39-6,14.77-9,25.14-9c10.84,0,19.37,3.02,25.6,9.06
        C351.72,228.94,354.84,237.03,354.84,247.17z M331.33,257.16c2.98-3.02,4.47-6.93,4.47-11.73c0-4.8-1.49-8.71-4.47-11.73
        c-2.98-3.02-6.87-4.53-11.67-4.53c-4.72,0-8.55,1.55-11.5,4.64c-2.94,3.1-4.41,6.97-4.41,11.61c0,4.8,1.45,8.71,4.35,11.73
        c2.9,3.02,6.75,4.53,11.55,4.53C324.46,261.69,328.35,260.18,331.33,257.16z"
      />
      <path
        className="st0"
        d="M364.01,242.06v-48.19h17.77v27.17c4.64-4.8,11.26-7.2,19.86-7.2c9.44,0,16.95,2.92,22.53,8.77
        c5.57,5.85,8.36,13.57,8.36,23.16c0,9.06-3.18,16.53-9.52,22.41c-6.35,5.88-14.4,8.82-24.15,8.82c-10.91,0-19.45-3.08-25.6-9.23
        C367.09,261.63,364.01,253.06,364.01,242.06z M386.36,233.7c-2.98,3.02-4.47,6.93-4.47,11.73c0,4.8,1.49,8.71,4.47,11.73
        c2.98,3.02,6.87,4.53,11.67,4.53c4.72,0,8.55-1.55,11.5-4.64c2.94-3.1,4.41-6.97,4.41-11.61c0-4.8-1.45-8.71-4.35-11.73
        c-2.9-3.02-6.75-4.53-11.55-4.53C393.23,229.18,389.34,230.68,386.36,233.7z"
      />
      <path
        className="st0"
        d="M469.09,261.69c7.97,0,11.96-4.76,11.96-14.28v-32.4h17.77v36.81c0,8.36-2.71,14.65-8.13,18.87
        c-5.42,4.22-12.62,6.33-21.6,6.33c-8.98,0-16.18-2.11-21.6-6.33c-5.42-4.22-8.13-10.51-8.13-18.87v-36.81h17.77v32.4
        C457.13,256.93,461.12,261.69,469.09,261.69z"
      />
      <path
        className="st0"
        d="M556.88,297.22h-20.78c0.39-0.31,0.87-0.75,1.45-1.34c0.58-0.58,1.63-1.82,3.14-3.72
        c1.51-1.9,2.85-3.87,4.01-5.92c1.16-2.05,2.26-4.61,3.31-7.66c1.04-3.06,1.61-6.13,1.68-9.23c-3.87,5.11-9.64,7.66-17.3,7.66
        c-16.02,0-24.04-8.17-24.04-24.5v-37.5h17.77v32.05c0,4.8,0.81,8.44,2.44,10.91c1.63,2.48,4.57,3.72,8.82,3.72
        c8.44,0,12.66-4.84,12.66-14.51v-32.16h17.77v51.67c0,5.57-0.91,10.9-2.73,15.97c-1.82,5.07-3.66,8.77-5.52,11.09L556.88,297.22z"
      />
      <path
        className="st1"
        d="M567.78,131.21c0-22.08-7.73-39.56-23.18-52.44c-15.46-12.88-34.47-19.32-57.04-19.32
        c-22.08,0-40.85,6.81-56.3,20.42c-15.46,13.62-23.18,31.71-23.18,54.28c0,18.16,4.6,32.63,13.8,43.42
        c9.2,10.8,22.14,16.19,38.82,16.19h107.08V131.21z"
      />
      <g>
        <path
          className="st2"
          d="M539.73,157.95v8.59c-4.84,2.44-9.24,4.3-13.21,5.57c-3.97,1.28-8.18,1.92-12.63,1.92
          c-4.45,0-8.68-0.63-12.69-1.89c-4.01-1.26-8.39-3.11-13.15-5.54v-8.59c3.6,1.94,7.65,3.47,12.16,4.62
          c4.51,1.14,9.07,1.71,13.67,1.71c4.61,0,9.16-0.58,13.67-1.74C532.07,161.44,536.13,159.89,539.73,157.95z"
        />
      </g>
      <path
        className="st3"
        d="M588.99,216.34v-53.89h46.57c12.68,0,22.62,4,29.81,12.01c7.19,8.01,10.79,18.43,10.79,31.27
        c0,13.49-4.65,24.24-13.96,32.25c-9.31,8.01-20.38,12.01-33.22,12.01c-11.87,0-21.5-2.76-28.9-8.29
        C592.68,236.17,588.99,227.72,588.99,216.34z"
      />
    </svg>
  );
};
