import { List, Tooltip } from '@mui/material';
import Card from '@mui/material/Card';
import React, { useState } from 'react';
import { AccessRights } from 'src/types/pages/users';

import { ButtonTab } from './ButtonTab';

interface ButtonTabsProps {
  accessRights: AccessRights | null;
  onTabChange: (label: string) => void;
}

export const ButtonTabs: React.FC<ButtonTabsProps> = ({ accessRights, onTabChange }) => {
  const [activeIndex, setActiveIndex] = useState<number>(0);

  const handleButtonClick = (index: number, label: string) => {
    setActiveIndex(index);
    onTabChange(label);
  };
  return (
    <Card sx={{ width: { xs: '100%', sm: '250px' }, padding: '10px 20px' }}>
      <List>
        {accessRights?.map((accessRight, index) => {
          return (
            <Tooltip
              key={index}
              title="Il n'y a pas de droits d'accès associés à cet utilisateur."
              disableHoverListener={accessRight.accessRights.length !== 0}
              placement="top-end"
              arrow
              componentsProps={{
                tooltip: {
                  sx: {
                    bgcolor: 'black',
                    '& .MuiTooltip-arrow': {
                      color: 'common.black',
                    },
                  },
                },
              }}
            >
              <div>
                <ButtonTab
                  title={accessRight ? accessRight.label : ''}
                  active={index === activeIndex}
                  onClick={() => handleButtonClick(index, accessRight.label)}
                  disabled={accessRight.accessRights.length === 0}
                />
              </div>
            </Tooltip>
          );
        })}
      </List>
    </Card>
  );
};
