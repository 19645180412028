import { t } from 'i18next';
import React, { useEffect } from 'react';
import {
  AutocompleteFilter,
  DatePickerFilter,
  SearchFilter,
  SplitButtonFilter,
} from 'src/common/components/filters';
import ReferenceSplitButtonFilter from 'src/common/components/filters/SplitButtonFilter/ReferenceSplitButtonFilter';
import ResetFiltersButton from 'src/common/components/ResetFiltersButton';
import { TagSummary } from 'src/common/components/TagSummary';
import { TotalItems } from 'src/common/components/TotalItems';
import { FilterLayout } from 'src/common/layouts/react-admin';
import { useListExportModalContext } from 'src/common/modals/hooks/useListExportModalContext';
import TaggedFilter from 'src/contexts/common/filters/tagged-filter-context/components/TaggedFilter';
import { TaggedFilterOption } from 'src/contexts/common/filters/tagged-filter-context/types';
import ClubResourceEnum from 'src/data/enum/remote/club/club-resource-enum';
import { HelpdeskTopicSplitButtonFilterRenderOption } from 'src/domains/club/helpdesk/components/list/filters/filter/HelpdeskTopicSplitButtonFilterRenderOption';
import {
  getLocalHelpdeskTicketStatusList,
  ticketTypeOptions,
} from 'src/domains/club/helpdesk/data';
import { useDatePickerRangeHandler } from 'src/hooks/useDatePickerRangeHandler';
import { useReactAdminFilters } from 'src/hooks/useReactAdminFilters';
import { tokens } from 'src/locales/tokens';
import { FilterLabels, FilterValues } from 'src/types/enums';
import { getDateYearsAgo } from 'src/utils/getDateYearsAgo';

export const HelpdeskFilters: React.FC = () => {
  const { filterValues, setFilters } = useReactAdminFilters();
  const { startDate, endDate, setStartDate, setEndDate } = useDatePickerRangeHandler();
  const { setGetFilters } = useListExportModalContext();

  useEffect(() => {
    setGetFilters(() => () => filterValues);
  }, [filterValues, setGetFilters]);

  return (
    <FilterLayout>
      <>
        <SearchFilter
          filterName={'ticketNumber'}
          filterValues={filterValues}
          placeholder={'Numéro de ticket'}
        />
        <AutocompleteFilter
          resource="admin_users"
          itemLabel="fullName"
          order="ASC"
          label="Géré par"
          filterKey="managedBy"
          filterValue="id"
          placeholder={t(tokens.placeholder.adminUserSearch)}
        />
      </>
      <>
        <TotalItems />
      </>
      <>
        <TaggedFilter
          component={SplitButtonFilter as React.FC}
          label={FilterLabels.Status}
          value={FilterValues.STATUS}
          options={getLocalHelpdeskTicketStatusList() as TaggedFilterOption[]}
          filterValues={filterValues}
          setFilters={setFilters}
        />
        <TaggedFilter
          component={SplitButtonFilter as React.FC}
          label={FilterLabels.Type}
          value={FilterValues.TYPE}
          options={ticketTypeOptions as TaggedFilterOption[]}
          filterValues={filterValues}
          setFilters={setFilters}
        />
        <TaggedFilter
          component={ReferenceSplitButtonFilter as React.FC}
          label={'Motif'}
          optionLabel={'label'}
          value={'topic'}
          renderOption={HelpdeskTopicSplitButtonFilterRenderOption}
          resource={ClubResourceEnum.HELPDESK_TOPIC}
          setFilters={setFilters}
          filterValues={filterValues}
        />
        <TaggedFilter
          component={DatePickerFilter as React.FC}
          label={FilterLabels.CreatedAt}
          value={FilterValues.CREATED_AFTER}
          minDate={getDateYearsAgo(15)}
          maxDate={endDate}
          showLabel
          setStartDate={setStartDate}
          filterValues={filterValues}
          setFilters={setFilters}
        />
        <TaggedFilter
          component={DatePickerFilter as React.FC}
          minDate={startDate}
          label={FilterLabels.EndAt}
          value={FilterValues.CREATED_BEFORE}
          showLabel
          setEndDate={setEndDate}
          filterValues={filterValues}
          setFilters={setFilters}
        />
        <ResetFiltersButton
          setFilters={setFilters}
          filterValues={filterValues}
          setStartDate={setStartDate}
          setEndDate={setEndDate}
        />
      </>
      <>
        <TagSummary
          filterValues={filterValues}
          setFilters={setFilters}
        />
      </>
    </FilterLayout>
  );
};
