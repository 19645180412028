import { ContractClubMember } from 'src/admin-api-types';

export const translatePaymentPeriod = (record: ContractClubMember) => {
  switch (record.paymentPeriod) {
    case 'yearly':
      return 'Annuel';
    case 'monthly':
      return 'Mensuel';
    default:
      return '-';
  }
};
