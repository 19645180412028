import { Card } from '@mui/material';
import React from 'react';

interface ThemedCardProps {
  children: React.ReactNode;
}

export const ThemedCard: React.FC<ThemedCardProps> = ({ children }) => {
  return (
    <Card
      sx={{
        display: 'flex',
        flexDirection: 'column',
        flexBasis: '50%',
      }}
    >
      {children}
    </Card>
  );
};
