import { Stack, StackProps } from '@mui/material';

interface BaseListWithCustomScrollbarProps extends StackProps {
  children: React.ReactNode;
}

export const BaseListWithCustomScrollbar: React.FC<BaseListWithCustomScrollbarProps> = ({
  children,
  ...props
}) => (
  <Stack
    spacing={1}
    sx={{
      overflowY: 'auto',
      '&::-webkit-scrollbar': {
        width: '5px',
      },
      '&::-webkit-scrollbar-track': {
        background: '#f1f1f1',
      },
      '&::-webkit-scrollbar-thumb': {
        background: '#888',
      },
      '&::-webkit-scrollbar-thumb:hover': {
        background: '#555',
      },
    }}
    {...props}
  >
    {children}
  </Stack>
);
