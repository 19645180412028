import React from 'react';
import { SelectInput, SelectInputProps } from 'react-admin';
import { TopLabelInput } from 'src/components/react-admin/core/inputs/TopLabelInput';

interface TopLabelSelectInputProps extends SelectInputProps {
  label: string;
}

export const TopLabelSelectInput: React.FC<TopLabelSelectInputProps> = ({ label, ...rest }) => {
  return (
    <TopLabelInput label={label}>
      <SelectInput
        {...rest}
        fullWidth
        helperText={false}
        label={false}
        sx={{
          '& .MuiSelect-select': {
            paddingY: 2,
            fontWeight: 'regular',
          },
        }}
      />
    </TopLabelInput>
  );
};
