import { t } from 'i18next';
import { tokens } from 'src/locales/tokens';
import { isEmptyOrSpaces } from 'src/utils/isEmptyOrSpaces';
import { isRnaValid } from 'src/utils/isRnaValid';
import { isSiretValidLuhn } from 'src/utils/isSiretValidLuhn';

import { FirstStepType, ValidationError } from '../../../context';

/**
 * Validates the SIRET and RNA values.
 * @param values - The values to validate.
 * @param errors - The object to store validation errors.
 */
export const validateSiretAndRna = (values: FirstStepType, errors: ValidationError) => {
  if (isEmptyOrSpaces(values.businessIdentifier) && isEmptyOrSpaces(values.associationIdentifier)) {
    errors.businessIdentifier = t(tokens.domains.contract.validators.siretOrRna);
    errors.associationIdentifier = t(tokens.domains.contract.validators.siretOrRna);
  } else {
    if (!isEmptyOrSpaces(values.businessIdentifier)) {
      if (!isSiretValidLuhn(values.businessIdentifier)) {
        errors.businessIdentifier = t(tokens.domains.members.detail.alertMessage.invalidSiret);
      } else {
        delete errors.businessIdentifier;
      }
    }

    if (!isEmptyOrSpaces(values.associationIdentifier)) {
      if (!isRnaValid(values.associationIdentifier)) {
        errors.associationIdentifier = t(tokens.domains.members.detail.alertMessage.invalidRna);
      } else {
        delete errors.associationIdentifier;
      }
    }
  }
};
