import { t } from 'i18next';
import React from 'react';
import { Header } from 'src/common/components/grid/Header';
import { ListLayout } from 'src/common/layouts/react-admin';
import ClubResourceEnum from 'src/data/enum/remote/club/club-resource-enum';
import { HelpdeskTicketListTable } from 'src/domains/club/helpdesk/components/list/HelpdeskTicketListTable';
import { tokens } from 'src/locales/tokens';

const HelpdeskTicketList = () => {
  return (
    <ListLayout seoTitle={t(tokens.seo.helpdesk.title)}>
      <Header
        title={t(tokens.domains.helpdesk.helpdeskHeaderTitle)}
        resource={ClubResourceEnum.HELPDESK_TICKET}
        hasExport={false}
      />
      <HelpdeskTicketListTable />
    </ListLayout>
  );
};

export default HelpdeskTicketList;
