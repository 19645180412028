import { useMemo } from 'react';
import { SettingsContextValue } from 'src/domains/system/settings/settingsTypes';

import { createTheme } from '../theme';

/**
 * Custom hook that creates a theme based on the provided settings.
 * @param settings - The settings object containing the theme configuration.
 * @returns The created theme.
 */
export const useCreateTheme = (settings: SettingsContextValue) => {
  const theme = useMemo(() => {
    return createTheme({
      colorPreset: settings.colorPreset,
      contrast: settings.contrast,
      direction: settings.direction,
      paletteMode: settings.paletteMode,
      responsiveFontSizes: settings.responsiveFontSizes,
    });
  }, [settings]);

  return theme;
};
