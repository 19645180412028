import { Skeleton } from '@mui/material';
import { HeaderLayout } from 'src/common/layouts/react-admin/HeaderLayout';

export const HeaderSkeleton: React.FC = () => {
  return (
    <HeaderLayout>
      <>
        <Skeleton
          variant="rounded"
          sx={{ width: '250px', height: '40px' }}
        />
      </>
      <></>
    </HeaderLayout>
  );
};
