import { t } from 'i18next';
import { Organization } from 'src/admin-api-types';
import { ValidationError } from 'src/domains/club/contract/context';
import { tokens } from 'src/locales/tokens';
import { isEmptyOrSpaces } from 'src/utils/isEmptyOrSpaces';
import { isRnaValid } from 'src/utils/isRnaValid';

export const validateRna = (values: Partial<Organization>, errors: ValidationError) => {
  if (isEmptyOrSpaces(values.associationIdentifier) && isEmptyOrSpaces(values.businessIdentifier)) {
    errors.associationIdentifier = t(tokens.utils.form.required);
  }
  if (!isEmptyOrSpaces(values.associationIdentifier)) {
    if (!isRnaValid(values.associationIdentifier)) {
      errors.associationIdentifier = t(tokens.domains.members.detail.alertMessage.invalidRna);
    }
  }
};
