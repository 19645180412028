// status.ts
import { t } from 'i18next';
import { HelpdeskStatus } from 'src/domains/club/helpdesk/types/ticket-status';
import { tokens } from 'src/locales/tokens';
import { purple, warning, green, error } from 'src/theme/colors';
import { getEnumValues } from 'src/utils/enumUtils';

// Define enum for helpdesk ticket status options
export enum HelpdeskTicketStatusOption {
  CREATED = 'created',
  IN_PROGRESS = 'in_progress',
  DONE = 'done',
  ABORTED = 'aborted',
}

// Define interface for helpdesk status format
export interface HelpdeskStatusFormat {
  value: HelpdeskTicketStatusOption;
  label: string;
  color: {
    background: string;
    text: string;
  };
}

// Define the status information array
const availableStatuses: HelpdeskStatusFormat[] = [
  {
    value: HelpdeskTicketStatusOption.CREATED,
    label: t(tokens.domains.helpdesk.list.filters.status.created),
    color: {
      background: purple.alpha30 as string,
      text: purple.main,
    },
  },
  {
    value: HelpdeskTicketStatusOption.IN_PROGRESS,
    label: t(tokens.domains.helpdesk.list.filters.status.in_progress),
    color: {
      background: warning.alpha12 as string,
      text: warning.main,
    },
  },
  {
    value: HelpdeskTicketStatusOption.DONE,
    label: t(tokens.domains.helpdesk.list.filters.status.done),
    color: {
      background: green.alpha8 as string,
      text: green.main,
    },
  },
  {
    value: HelpdeskTicketStatusOption.ABORTED,
    label: t(tokens.domains.helpdesk.list.filters.status.aborted),
    color: {
      background: error.alpha30 as string,
      text: error.main,
    },
  },
];

// Function to get helpdesk ticket status information
export const getHelpdeskTicketStatusInformation = (
  value: HelpdeskTicketStatusOption
): HelpdeskStatus => {
  const defaultStatus = {
    value,
    label: '?',
    color: {
      background: purple.light,
      text: purple.dark,
    },
  };
  return availableStatuses.find((item) => item.value === value) || defaultStatus;
};

// Function to get helpdesk ticket status
export const getHelpdeskTicketStatus = (status: HelpdeskTicketStatusOption): HelpdeskStatus => {
  return getHelpdeskTicketStatusInformation(status);
};

// Function to get local helpdesk ticket status list
export const getLocalHelpdeskTicketStatusList = (): HelpdeskStatus[] => {
  return availableStatuses;
};

// Function to get all helpdesk ticket status values
export const getHelpdeskTicketStatusValues = (): HelpdeskTicketStatusOption[] => {
  return getEnumValues(HelpdeskTicketStatusOption);
};
