import { Box } from '@mui/material';
import * as React from 'react';
import { useEffect } from 'react';
import { FilterOption } from 'src/common/components/filters/SplitButtonFilter/FilterOption';
import { SplitButtonComponentButtonGroup } from 'src/common/components/filters/SplitButtonFilter/SplitButtonComponentButtonGroup';
import { SplitButtonComponentMenu } from 'src/common/components/filters/SplitButtonFilter/SplitButtonComponentMenu';
import { useMenuListLogicDisplay } from 'src/hooks/useMenuListLogicDisplay';

export interface SplitButtonFilterProps {
  label: string;
  value: string;
  options: FilterOption[];
  renderOption?: React.FC;
  isLoading?: boolean;
  setFilters: (
    filters: { [key: string]: string[] | Date },
    displayedFilters: unknown,
    debounce?: boolean
  ) => void;
  filterValues: { [key: string]: string[] | Date };
}

export const SplitButtonFilter: React.FC<SplitButtonFilterProps> = ({
  label,
  value,
  options,
  setFilters,
  renderOption,
  filterValues,
  isLoading,
}) => {
  const [selectedIndices, setSelectedIndices] = React.useState<number[]>([]);
  const { open, anchorRef, handleToggle, handleClose } = useMenuListLogicDisplay();

  useEffect(() => {
    // Get the selected values from the filterValues as an array of strings
    const selectedValues = (filterValues[value] || []) as string[];
    // Get the indices of the selected values
    const newSelectedIndices = options.reduce((acc, option, index) => {
      if (selectedValues.includes(option.value as string)) {
        acc.push(index);
      }
      return acc;
    }, [] as number[]);
    setSelectedIndices(newSelectedIndices);
  }, [filterValues, options, value]);

  const handleMenuItemClick = (
    _event: React.MouseEvent<HTMLLIElement, MouseEvent>,
    index: number
  ) => {
    const currentIndex = selectedIndices.indexOf(index);
    let newSelectedIndices;

    // Determine if the item should be added or removed
    if (currentIndex === -1) {
      newSelectedIndices = [...selectedIndices, index]; // Add the index if not found
    } else {
      newSelectedIndices = selectedIndices.filter((_, i) => i !== currentIndex); // Remove the index if found
    }

    setSelectedIndices(newSelectedIndices);

    // Convert indices to array of string values for React Admin
    const selectedValues = newSelectedIndices.map((i) => options[i].value);
    setFilters({ ...filterValues, [value]: selectedValues }, {}); // Automatic fetch with new filters
  };

  return (
    <Box sx={{ position: 'relative', display: 'inline-flex' }}>
      <SplitButtonComponentButtonGroup {...{ anchorRef, label, open, handleToggle, isLoading }} />
      <SplitButtonComponentMenu
        {...{
          open,
          anchorRef,
          handleClose,
          options,
          selectedIndices,
          handleMenuItemClick,
          isLoading,
          renderOption,
        }}
      />
    </Box>
  );
};
