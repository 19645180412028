import Box from '@mui/material/Box';
import {
  Datagrid,
  DatagridRowProps,
  EditButton,
  EmailField,
  FunctionField,
  ListBase,
  Pagination,
  ShowButton,
  TextField,
  useResourceContext,
} from 'react-admin';
import { User } from 'src/types/contexts/auth';
// import RolesField from 'src/components/react-admin/RolesField';

export const UserListTable = () => {
  const resource = useResourceContext();
  return (
    <Box sx={{ position: 'relative' }}>
      {/*<Scrollbar>*/}
      <ListBase resource={resource}>
        <Datagrid bulkActionButtons={false}>
          <TextField
            source="firstname"
            label="Prénom"
          />
          <TextField
            source="lastname"
            label="Nom"
          />
          <EmailField
            source="email"
            label="E-mail"
          />
          {/* <RolesField/> */}
          {/* <FunctionField
            label="Actions"
            render={(record: User) => <ActionButtons record={record} />}
          /> */}
        </Datagrid>
        <Pagination />
      </ListBase>
      {/*</Scrollbar>*/}
    </Box>
  );
};

// const ActionButtons = (props: DatagridRowProps) => (
//   <div>
//     <EditButton record={props.record} />
//     <ShowButton record={props.record} />
//   </div>
// );
