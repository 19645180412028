/**
 * Returns a new Date object representing a date that is `years` years ago from the current date.
 * @param years - The number of years ago.
 * @returns A new Date object representing the date `years` years ago.
 */
export const getDateYearsAgo = (years: number) => {
  const date = new Date();
  date.setFullYear(date.getFullYear() - years);
  return date;
};
