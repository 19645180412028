import { createContext } from 'react';

import { contractContextType } from './contractContextType';
import { initialFirstStep, initialSecondStep } from './initialValues';

export const contractContext = createContext<contractContextType>({
  firstStep: initialFirstStep,
  setFirstStep: () => {},
  secondStep: initialSecondStep,
  setSecondStep: () => {},
  formErrors: {},
  setFormErrors: () => {},
  isCheckboxChecked: false,
  setIsCheckboxChecked: () => {},
});
