import { Typography } from '@mui/material';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import Stack from '@mui/material/Stack';
import { LogoNavV2 } from 'src/components/logos/LogoNavV2';
import { RouterLink } from 'src/components/RouterLink';
import { Scrollbar } from 'src/components/scrollbar';
import { NavColor } from 'src/hooks/theme/useCssVarsDesktop';
import { useCssVars } from 'src/hooks/theme/useCssVarsMobile';
import { usePathname } from 'src/hooks/usePathname';
import { paths } from 'src/paths';

import { Section } from '../config';
import { SideNavSection } from '../vertical-layout/SideNavSection';

const MOBILE_NAV_WIDTH = 280;

interface MobileNavProps {
  color?: NavColor;
  sections: Section[];
  open: boolean;
  onClose: () => void;
}

export const MobileNav: React.FC<MobileNavProps> = (props) => {
  const { color = 'evident', open, onClose, sections = [] } = props;
  const pathname = usePathname();
  const cssVars = useCssVars(color);

  return (
    <Drawer
      anchor="left"
      onClose={onClose}
      open={open}
      PaperProps={{
        sx: {
          ...cssVars,
          backgroundColor: 'var(--nav-bg)',
          color: 'var(--nav-color)',
          width: MOBILE_NAV_WIDTH,
        },
      }}
      variant="temporary"
    >
      <Stack sx={{ height: '100%' }}>
        <Stack
          alignItems="center"
          direction="row"
          spacing={2}
          sx={{ p: 3 }}
        >
          <Box
            component={(props: React.ComponentPropsWithoutRef<'a'>) => (
              <RouterLink
                href={paths.index}
                {...props}
              />
            )}
            sx={{
              borderColor: 'var(--nav-logo-border)',
              borderRadius: 1,
              borderStyle: 'solid',
              borderWidth: 1,
              display: 'flex',
              height: 40,
              p: '4px',
              width: 40,
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <LogoNavV2 />
          </Box>
          <Box>
            <Typography fontWeight="500">Dynadmin</Typography>
          </Box>
        </Stack>
        <Stack
          component="nav"
          spacing={2}
          sx={{
            flexGrow: 1,
            px: 2,
          }}
        >
          {sections.map((section, index) => (
            <SideNavSection
              items={section.items || []}
              key={index}
              pathname={pathname}
              subheader={section.subheader}
            />
          ))}
        </Stack>
      </Stack>
    </Drawer>
  );
};
