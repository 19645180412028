import { Box, Typography } from '@mui/material';
import { t } from 'i18next';
import React, { Dispatch, SetStateAction } from 'react';
import { required, SelectInput, Validator } from 'react-admin';
import { FirstStepType } from 'src/domains/club/contract/context';
import { tokens } from 'src/locales/tokens';

import { AsyncSelectInput } from './AsyncSelectInput';

interface CustomSelectLabelProps {
  source: string;
  label: string;
  choices?: { id: string; name: string }[];
  validate?: Validator | Validator[];
  name?: string;
  firstStep?: FirstStepType;
  isFirstStepClicked?: boolean;
  setFirstStep?: Dispatch<SetStateAction<FirstStepType>>;
  errorMessage?: string;
  dataSource?: string;
  disabled?: boolean;
}

export const CustomSelectLabel: React.FC<CustomSelectLabelProps> = ({
  source,
  label,
  choices,
  validate,
  name,
  firstStep,
  isFirstStepClicked,
  errorMessage,
  dataSource,
  disabled,
}) => {
  const validateSelection = (value: string) =>
    value === 'value0' ? t(tokens.utils.form.selectElementFromList) : undefined;

  const validators: Validator[] = [
    required(t(tokens.utils.form.required)),
    validateSelection,
    ...(Array.isArray(validate) ? validate : validate ? [validate] : []),
  ];
  return (
    <Box
      sx={{
        width: '100%',
        marginLeft: '0px !important',
      }}
    >
      <Typography
        variant={'body2'}
        fontWeight={600}
        gutterBottom
      >
        {label}
      </Typography>
      {dataSource ? (
        <AsyncSelectInput
          source={source}
          name={name}
          validate={validators}
          dataSource={dataSource}
        />
      ) : (
        <SelectInput
          source={source}
          name={name}
          choices={choices}
          label={false}
          helperText={false}
          disabled={disabled}
          defaultValue="value0"
          fullWidth
          sx={{
            marginTop: '0px',
            minHeight: '56px',
          }}
          inputProps={{
            sx: {
              minHeight: '56px !important',
              paddingTop: '0',
              paddingBottom: '0',
              display: 'flex',
              alignItems: 'center',
              color: 'inherit',
              fontWeight: 'normal',
            },
          }}
          validate={validators}
        />
      )}
      {firstStep && !firstStep[name as keyof FirstStepType] && isFirstStepClicked && (
        <Typography
          color="red"
          fontSize="14px"
          fontStyle="bold"
          paddingTop="12px"
        >
          {errorMessage || t(tokens.utils.form.required)}
        </Typography>
      )}
    </Box>
  );
};
