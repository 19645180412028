import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import Modal from '@mui/material/Modal';
import Stack from '@mui/system/Stack';
import React from 'react';

import { style } from '../styles/style';

interface BaseModalProps {
  isOpen: boolean;
  closeModal: () => void;
  ariaLabelledby: string;
  ariaDescribedby: string;
  children: React.ReactNode;
}

export const BaseModal: React.FC<BaseModalProps> = ({
  isOpen,
  closeModal,
  ariaLabelledby,
  ariaDescribedby,
  children,
}) => (
  <Modal
    open={isOpen}
    onClose={closeModal}
    aria-labelledby={ariaLabelledby}
    aria-describedby={ariaDescribedby}
  >
    <Stack
      sx={style}
      spacing={2}
    >
      <IconButton
        aria-label="close"
        onClick={closeModal}
        sx={{
          position: 'absolute',
          right: 8,
          top: 8,
        }}
      >
        <CloseIcon />
      </IconButton>
      {children}
    </Stack>
  </Modal>
);
