export const translatePaymentType = (paymentType: string | null | undefined) => {
  switch (paymentType) {
    case 'card':
      return 'Carte';
    case 'cash':
      return 'Espèce';
    case 'cheque':
      return 'Chèque';
    case 'transfer':
      return 'Virement';
    case 'levy':
      return 'Prélèvement';
    default:
      return '-';
  }
};
