import { t } from 'i18next';
import { EditBase, useRecordContext } from 'react-admin';
import { Member } from 'src/admin-api-types';
import { useMutationOptions } from 'src/common/hooks';
import AdminResourceEnum from 'src/data/enum/remote/admin/adminResourceEnum';
import { tokens } from 'src/locales/tokens';
import { transform } from 'src/utils/deepTransform';

import { OrganizationCardEditContentSkeleton } from '../../skeletons/OrganizationCardEditContentSkeleton';

import { OrganizationDetailContent } from '.';

interface OrganizationDetailProps {
  source: keyof Member;
}

export const OrganizationDetail: React.FC<OrganizationDetailProps> = ({ source }) => {
  const record = useRecordContext<Member>();

  const mutationOptions = useMutationOptions({
    vertical: 'top',
    horizontal: 'center',
    successLabel: t(tokens.domains.members.detail.notify.success),
    failLabel: t(tokens.domains.members.detail.notify.fail),
  });

  if (!record) {
    return <OrganizationCardEditContentSkeleton />;
  }

  return (
    <EditBase
      redirect={false}
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      id={record[source]}
      resource={AdminResourceEnum.ORGANIZATION}
      transform={transform}
      mutationMode="pessimistic"
      mutationOptions={mutationOptions}
    >
      <OrganizationDetailContent />
    </EditBase>
  );
};
