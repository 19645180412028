import { Box, Typography } from '@mui/material';
import React from 'react';
import { TextField } from 'react-admin';

interface CustomTextLabelProps {
  source?: string;
  label: string;
  value?: string;
  link?: boolean;
}

export const CustomTextLabel: React.FC<CustomTextLabelProps> = ({ source, label, value, link }) => {
  const renderContent = () => {
    if (link && source) {
      return (
        <a
          href={value}
          target="_blank"
          rel="noopener noreferrer"
          style={{ textDecoration: 'none', color: 'inherit' }}
        >
          <Typography
            variant="body2"
            color="primary"
            sx={{
              color: 'text.primary',
              '&:hover': {
                color: 'primary.main',
              },
            }}
          >
            {value}
          </Typography>
        </a>
      );
    } else if (value) {
      return <Typography variant="body2">{value}</Typography>;
    } else {
      return (
        <TextField
          source={source}
          label={false}
          sx={{
            width: '100%',
          }}
        />
      );
    }
  };

  return (
    <Box
      sx={{
        width: '100%',
      }}
    >
      <Typography
        variant="body2"
        fontWeight={600}
        gutterBottom
      >
        {label}
      </Typography>
      {renderContent()}
    </Box>
  );
};
