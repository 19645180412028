import { Alert } from '@mui/material';
import { useNotify } from 'react-admin';

type Position =
  | 'top'
  | 'bottom'
  | 'left'
  | 'right'
  | 'top-left'
  | 'top-right'
  | 'bottom-left'
  | 'bottom-right';

interface useMutationOptionsProps {
  vertical: 'top' | 'bottom';
  horizontal: 'left' | 'center' | 'right';
  style?: { left: Position; width: Position };
  successLabel: string;
  failLabel: string;
}

export const useMutationOptions = ({
  vertical,
  horizontal,
  style,
  successLabel,
  failLabel,
}: useMutationOptionsProps) => {
  const notify = useNotify();

  return {
    onSuccess: () => {
      notify(
        <Alert
          sx={{
            width: '100%',
          }}
          severity="success"
        >
          {successLabel}
        </Alert>,
        {
          type: 'success',
          anchorOrigin: { vertical, horizontal },
          style,
        }
      );
    },
    onError: () => {
      notify(
        <Alert
          sx={{
            width: '100%',
          }}
          severity="warning"
        >
          {failLabel}
        </Alert>,
        {
          type: 'error',
          anchorOrigin: { vertical, horizontal },
          style,
        }
      );
    },
  };
};
