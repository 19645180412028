import { jwtDecode } from 'jwt-decode';
import {
  ACCESS_TOKEN_STORAGE_KEY,
  REFRESH_TOKEN_STORAGE_KEY,
} from 'src/contexts/auth/jwt/AuthProvider';
import { ENTRYPOINT, getAuthenticationHeader } from 'src/data/provider/remote/dataProvider';
import {
  LoginErrorResponse,
  LoginSuccessResponse,
  RequestForgotPasswordResponse,
  RequestForgotPasswordResponseFront,
  ResetForgotPasswordResponseFront,
  ResetForgotPasswordSuccessResponse,
  VerifyTwoFactorCodeResponse,
} from 'src/types/config';
import { User } from 'src/types/contexts/auth';

/**
 * This function is intended to be used in a catch block to handle API errors.
 * It checks if the error is an instance of Error and, if so, throws a new error with the same message.
 * Otherwise, it throws a new error with a generic message.
 *
 * @param err - The error to handle.
 */
export const handleCatchBlockError = (err: unknown) => {
  if (err instanceof Error) {
    throw new Error(err.message);
  } else {
    throw new Error('An error occurred');
  }
};

const remoteAuthProvider = {
  logout: () => {
    localStorage.removeItem('token');
    localStorage.removeItem('loggedUser');
    return Promise.resolve();
  },
  verifyTwoFactorCode: async (code: string): Promise<VerifyTwoFactorCodeResponse> => {
    const request = new Request(`${ENTRYPOINT}/auth/2fa`, {
      method: 'POST',
      body: JSON.stringify({ authCode: code }),
      credentials: 'include',
      headers: new Headers({
        'Content-Type': 'application/ld+json',
        'Access-Control-Allow-Credentials': 'true',
      }),
    });
    try {
      const response = await fetch(request);
      const data = (await response.json()) as VerifyTwoFactorCodeResponse;
      if (!response.ok) {
        throw new Error(data.message);
      }
      return data;
    } catch (err: unknown) {
      throw err;
    }
  },

  login: async ({
    email,
    password,
  }: {
    email: string;
    password: string;
  }): Promise<LoginSuccessResponse> => {
    const request = new Request(`${ENTRYPOINT}/auth/login`, {
      method: 'POST',
      body: JSON.stringify({ email: email, password }),
      credentials: 'include',
      headers: new Headers({
        'Content-Type': 'application/ld+json',
        'Access-Control-Allow-Credentials': 'true',
      }),
    });
    try {
      const response = await fetch(request);

      if (!response.ok) {
        const data = (await response.json()) as LoginErrorResponse;
        throw new Error(data.message);
      }
      const data = (await response.json()) as LoginSuccessResponse;
      return data;
    } catch (err) {
      throw new Error(err instanceof Error ? err.message : 'An error occurred');
    }
  },

  requestForgotPassword: async (email: string): Promise<RequestForgotPasswordResponseFront> => {
    const request = new Request(`${ENTRYPOINT}/auth/forgotten_password/request`, {
      method: 'POST',
      body: JSON.stringify({ email }),
      credentials: 'include',
      headers: new Headers({
        'Content-Type': 'application/ld+json',
        'Access-Control-Allow-Credentials': 'true',
      }),
    });
    try {
      const response = await fetch(request);
      const data = (await response.json()) as RequestForgotPasswordResponse;
      if (!response.ok) {
        throw new Error('An error occurred');
      }
      return { email: data.email, expiresAt: data.expiresAt } as RequestForgotPasswordResponseFront;
    } catch (err: unknown) {
      throw new Error('An error occurred');
    }
  },

  resetForgotPassword: async (
    password: string,
    token: string
  ): Promise<ResetForgotPasswordResponseFront> => {
    const request = new Request(`${ENTRYPOINT}/auth/forgotten_password/reset`, {
      method: 'POST',
      body: JSON.stringify({ password, token }),
      credentials: 'include',
      headers: new Headers({
        'Content-Type': 'application/ld+json',
        'Access-Control-Allow-Credentials': 'true',
      }),
    });
    try {
      const response = await fetch(request);
      const data = (await response.json()) as ResetForgotPasswordSuccessResponse;
      if (!response.ok) {
        throw new Error(data.detail);
      }
      return { passwordReset: data.passwordReset } as ResetForgotPasswordResponseFront;
    } catch (err: unknown) {
      handleCatchBlockError(err);
      throw err;
    }
  },
  checkAuth: () => {
    try {
      const token = localStorage.getItem(ACCESS_TOKEN_STORAGE_KEY);
      if (token === null) {
        return Promise.reject();
      }

      const decodedToken = jwtDecode(token);
      if (decodedToken.exp === undefined || new Date().getTime() / 1000 > decodedToken.exp) {
        return Promise.reject();
      }

      return Promise.resolve();
    } catch (e) {
      // override possible jwtDecode error
      return Promise.reject();
    }
  },
  checkError: (err: { status?: number; response?: { status?: number } }) => {
    const status = err.status ?? err.response?.status;
    if (status !== undefined && [401].includes(status)) {
      localStorage.removeItem(ACCESS_TOKEN_STORAGE_KEY);
      localStorage.removeItem(REFRESH_TOKEN_STORAGE_KEY);
      return Promise.reject();
    }
    return Promise.resolve();
  },
  me: async (): Promise<User> => {
    const request = new Request(`${ENTRYPOINT}/me`, {
      method: 'GET',
      headers: getAuthenticationHeader(),
    });
    try {
      const response = await fetch(request);
      if (!response.ok) {
        throw new Error(
          response.statusText || 'Erreur lors de la récupération des données utilisateur'
        );
      }
      const identity = (await response.json()) as User;
      return identity;
    } catch (err: unknown) {
      handleCatchBlockError(err);
      throw err;
    }
  },
};

export default remoteAuthProvider;
