import Typography from '@mui/material/Typography';
import React from 'react';
import { FilterOption } from 'src/common/types/filterOptionTypes';

interface DefaultOptionRenderProps {
  option: FilterOption;
}

const DefaultRenderOption: React.FC<DefaultOptionRenderProps> = ({ option }) => {
  return <Typography>{option.label}</Typography>;
};

export default DefaultRenderOption;
