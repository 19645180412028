export const dateToGridFormat = (createdAt: string | null | undefined) => {
  if (!createdAt) {
    return '-';
  }
  const date = new Date(createdAt);
  const day = String(date.getDate()).padStart(2, '0');
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const year = date.getFullYear();

  return `${day}/${month}/${year}`;
};
