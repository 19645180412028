import React, { ComponentType, useContext } from 'react';
import { Navigate } from 'react-router-dom';
import { AuthContext } from 'src/contexts/auth/jwt/authContext';

interface WithAccessGuardProps {
  requiredAccessCode: string;
}

/**
 * Higher-order component that adds access control to a wrapped component.
 * @template P - The props type of the wrapped component.
 * @param WrappedComponent - The component to be wrapped.
 * @param requiredAccessCode - A string require to access the wrapped component.
 * @returns A new component that includes access control.
 */
export const withAccessGuard = <P extends object>(
  WrappedComponent: ComponentType<P>,
  requiredAccessCode: string
) => {
  const Guard: React.FC<P & WithAccessGuardProps> = (props) => {
    const { user } = useContext(AuthContext);

    const hasAccess = user?.accessRights.some(
      (accessRight) => accessRight.code === requiredAccessCode
    );

    if (!hasAccess) {
      return (
        <Navigate
          to="/401"
          replace
        />
      );
    }

    return <WrappedComponent {...props} />;
  };

  return Guard;
};

export default withAccessGuard;
