import { Box, Typography } from '@mui/material';
import { t } from 'i18next';
import { SetStateAction } from 'react';
import { DateInput, Dispatch, Validator } from 'react-admin';
import { FirstStepType } from 'src/domains/club/contract/context';
import { tokens } from 'src/locales/tokens';

interface CustomInputLabelProps {
  source: string;
  name?: string;
  label: string;
  validate?: Validator | Validator[];
  disabled?: boolean;
  placeholder?: string;
  firstStep?: FirstStepType;
  isFirstStepClicked?: boolean;
  setFirstStep?: Dispatch<SetStateAction<FirstStepType>>;
  errorMessage?: string;
}

export const CustomDateInput: React.FC<CustomInputLabelProps> = ({
  source,
  label,
  name,
  firstStep,
  isFirstStepClicked,
  errorMessage,
}) => {
  const today = new Date().toISOString().split('T')[0];
  return (
    <Box
      sx={{
        width: '100%',
        marginLeft: '0px !important',
        '& svg': {
          color: 'red',
        },
      }}
    >
      <Typography
        variant={'body2'}
        fontWeight={600}
        gutterBottom
      >
        {label}
      </Typography>
      <DateInput
        source={source}
        helperText={false}
        label={false}
        sx={{
          width: '100%',
          minHeight: '56px !important',
          '& input[type="date"]::-webkit-calendar-picker-indicator': {
            fontSize: '20px',
            '&:hover': {
              cursor: 'pointer',
            },
          },
        }}
        inputProps={{
          min: today,
          sx: {
            padding: '0 16px',
            minHeight: '56px !important',
            color: 'inherit',
            fontWeight: 'normal',
          },
        }}
      />
      {firstStep && !firstStep[name as keyof FirstStepType] && isFirstStepClicked && (
        <Typography
          color="red"
          fontSize="14px"
          fontStyle="bold"
          paddingTop="12px"
        >
          {errorMessage || t(tokens.utils.form.required)}
        </Typography>
      )}
    </Box>
  );
};
