import { t } from 'i18next';
import { EditBase, useRecordContext } from 'react-admin';
import { Member } from 'src/admin-api-types';
import { useMutationOptions } from 'src/common/hooks';
import ClubResourceEnum from 'src/data/enum/remote/club/club-resource-enum';
import { tokens } from 'src/locales/tokens';
import { deepEqual } from 'src/utils/deepEqual';

import { MemberShowContentAgencySkeleton } from '../../skeletons/MemberShowContentAgencySkeleton';

import { MemberDetailAgencyContent } from '.';

interface OrganizationCardProps {
  source: keyof Member;
}

export const MemberDetailAgency: React.FC<OrganizationCardProps> = ({ source }) => {
  const record = useRecordContext<Member>();

  const transform = <T,>(data: Partial<T>, options?: { previousData?: Partial<T> }): string => {
    const previousData: Partial<T> = options?.previousData ?? {};

    const patchData: Partial<T> = {};

    Object.keys(data).forEach((key) => {
      const dataValue = data[key as keyof T];
      const prevDataValue = previousData[key as keyof T];

      if (!deepEqual(dataValue, prevDataValue)) {
        patchData[key as keyof T] = dataValue;
      }
    });
    return JSON.stringify(patchData);
  };
  const mutationOptions = useMutationOptions({
    vertical: 'top',
    horizontal: 'center',
    successLabel: t(tokens.domains.members.detail.notify.success),
    failLabel: t(tokens.domains.members.detail.notify.fail),
  });

  if (!record) {
    return <MemberShowContentAgencySkeleton />;
  }
  return (
    <EditBase
      redirect={false}
      id={record[source]}
      resource={ClubResourceEnum.MEMBERS}
      transform={transform}
      mutationMode="pessimistic"
      mutationOptions={mutationOptions}
    >
      <MemberDetailAgencyContent record={record} />
    </EditBase>
  );
};
