import { AsyncStatusAction } from 'src/types/enums/async-status-action-enum';
import {
  AsyncStatusReducerAction,
  AsyncStatusReducerState,
} from 'src/types/reducers/async-status-reducer-types';

export const asyncStatusReducerInitialState: AsyncStatusReducerState = {
  isInitial: false,
  isLoading: false,
  isSuccess: false,
  isError: false,
};

export const asyncStatusReducer = (
  state: AsyncStatusReducerState,
  action: AsyncStatusReducerAction
): AsyncStatusReducerState => {
  switch (action.type) {
    case AsyncStatusAction.INIT:
      return asyncStatusReducerInitialState;

    case AsyncStatusAction.IS_INITIAL:
      return {
        ...state,
        isInitial: action.payload,
      };
    case AsyncStatusAction.IS_LOADING:
      return { ...state, isLoading: action.payload };
    case AsyncStatusAction.IS_SUCCESS:
      return { ...state, isSuccess: action.payload };
    case AsyncStatusAction.IS_ERROR:
      return { ...state, isError: action.payload };

    default:
      return state;
  }
};
