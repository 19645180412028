import Alert from '@mui/material/Alert';
import Card from '@mui/material/Card';
import Stack from '@mui/system/Stack';
import React from 'react';
import { AccessRights } from 'src/types/pages/users';

import { ButtonTabs } from './ButtonTabs';
import { UserAccessRightsTable } from './UserAccessRightsTable';

export interface UserAccessRightsProps {
  accessRights: AccessRights | null;
  isFetchingAccessRights: boolean;
  selectedGroupLabel: string | null;
  handleAccessRightChange: (label: string) => void;
  handleGlobalSwitchChange: (checked: boolean) => void;
  handleSwitchChange: (id: string, checked: boolean) => void;
}

const UserAccessRights: React.FC<UserAccessRightsProps> = ({
  accessRights,
  isFetchingAccessRights,
  selectedGroupLabel,
  handleAccessRightChange,
  handleGlobalSwitchChange,
  handleSwitchChange,
}) => {
  return (
    <Stack
      flexDirection={{ xs: 'column', sm: 'row' }}
      gap={{ xs: '0', sm: '50px' }}
      width={{ xs: '100%', sm: 'auto' }}
    >
      {accessRights?.length ?? 0 > 0 ? (
        <>
          <Stack>
            <ButtonTabs
              accessRights={accessRights}
              onTabChange={handleAccessRightChange}
            />
          </Stack>
          <Stack flexGrow={1}>
            <Card>
              <UserAccessRightsTable
                selectedGroupLabel={selectedGroupLabel}
                handleGlobalSwitchChange={handleGlobalSwitchChange}
                handleSwitchChange={handleSwitchChange}
                accessRights={accessRights}
                isFetchingAccessRights={isFetchingAccessRights}
              />
            </Card>
          </Stack>
        </>
      ) : (
        <Alert severity="warning">{"Aucun droit d'accès associé à cet utilisateur"}</Alert>
      )}
    </Stack>
  );
};

export default UserAccessRights;
