import Typography from '@mui/material/Typography';
import { t } from 'i18next';
import {
  DateField,
  FunctionField,
  RecordContextProvider,
  ShowButton,
  TextField,
} from 'react-admin';
import { Ticket } from 'src/admin-api-types';
import { ColumnHeaderLabel } from 'src/common/enums/react-admin';
import { DatagridLayout } from 'src/common/layouts/react-admin';
import AdminUserAvatar from 'src/components/react-admin/representations/admin/AdminUserAvatar';
import ClubUserRecordRepresentation from 'src/components/react-admin/representations/club/ClubUserRecordRepresentation';
import HelpdeskTicketStatusField from 'src/domains/club/helpdesk/components/common/fields/HelpdeskTicketStatusField';
import { tokens } from 'src/locales/tokens';

export const HelpdeskGrid = () => {
  return (
    <DatagridLayout content={t(tokens.domains.helpdesk.list.grid.noResult)}>
      <TextField
        source={'ticketNumber'}
        label={'# Ticket'}
        variant={'caption'}
      />
      <FunctionField
        label={"Nom de l'utilisateur"}
        render={(record: Ticket) => {
          const { fullName, email } = record;
          return (
            <RecordContextProvider value={{ fullName, email: email }}>
              <ClubUserRecordRepresentation width={220} />
            </RecordContextProvider>
          );
        }}
      />
      <TextField
        source={'companyName'}
        label={t(tokens.domains.helpdesk.list.grid.company)}
      />
      <TextField
        source={'type'}
        label={t(tokens.domains.helpdesk.list.grid.type)}
      />
      <TextField
        source={'topic'}
        label={t(tokens.domains.helpdesk.list.grid.topic)}
      />

      <FunctionField
        label={'Responsable'}
        align={'center'}
        render={(record: Ticket) => {
          const { managedByFullName } = record;
          if (managedByFullName) {
            return (
              <RecordContextProvider value={{ fullName: managedByFullName }}>
                <AdminUserAvatar />
              </RecordContextProvider>
            );
          } else {
            return <Typography>-</Typography>;
          }
        }}
      />
      <TextField
        source={'department'}
        label={t(tokens.domains.helpdesk.list.grid.department)}
      />
      <HelpdeskTicketStatusField label={t(tokens.domains.helpdesk.detail.info.status)} />
      <DateField
        source={'createdAt'}
        label={t(tokens.domains.helpdesk.list.grid.createdAt)}
      />
      <FunctionField
        label={ColumnHeaderLabel.action}
        render={() => <ShowButton />}
      />
    </DatagridLayout>
  );
};
