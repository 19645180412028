import Chip from '@mui/material/Chip';
import React from 'react';
import { useRecordContext } from 'react-admin';
import { clubStatusOptions } from 'src/domains/club/clubs/utils/clubOptions';

interface ClubStatusFieldProps {
  source: string;
}

export const ClubStatusField: React.FC<ClubStatusFieldProps> = () => {
  const record = useRecordContext();
  const statusOption = clubStatusOptions.find((option) => option.value === record.status);

  if (!statusOption) {
    return null;
  }

  const { tagColor } = statusOption;

  return (
    <Chip
      label={statusOption.label.toUpperCase()}
      style={{
        backgroundColor: tagColor?.background || 'defaultBackground',
        color: tagColor?.text || 'defaultText',
        height: '32px',
        fontWeight: 'bold',
        paddingLeft: '12px',
        paddingRight: '12px',
      }}
    />
  );
};
