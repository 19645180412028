import React from 'react';
import { AutocompleteInput, ReferenceInput, ReferenceInputProps } from 'react-admin';
import { TopLabelInput } from 'src/components/react-admin/core/inputs/TopLabelInput';

interface TopLabelReferenceInputProps extends ReferenceInputProps {
  label: string;
  filterToQuery?: (searchText: string) => object;
}

export const TopLabelReferenceInput: React.FC<TopLabelReferenceInputProps> = ({
  label,
  filterToQuery,
  name,
  ...rest
}) => {
  return (
    <TopLabelInput label={label}>
      <ReferenceInput {...rest}>
        <AutocompleteInput
          fullWidth
          filterToQuery={filterToQuery}
          name={name}
          label={false}
          sx={{
            '& .MuiInputBase-root': {
              padding: '10px',
            },
          }}
        />
      </ReferenceInput>
    </TopLabelInput>
  );
};
