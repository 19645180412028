import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { t } from 'i18next';
import React from 'react';
import { tokens } from 'src/locales/tokens';

const options = [
  {
    label: t(tokens.settingsDrawer.nav.blendIn),
    value: 'blend-in',
  },
  {
    label: t(tokens.settingsDrawer.nav.discrete),
    value: 'discrete',
  },
  {
    label: t(tokens.settingsDrawer.nav.evident),
    value: 'evident',
  },
];

interface OptionsNavColorProps {
  onChange: (value: string) => void;
  value: string;
}

export const OptionsNavColor: React.FC<OptionsNavColorProps> = ({ onChange, value }) => {
  return (
    <Stack spacing={1}>
      <Typography
        color="text.secondary"
        variant="overline"
      >
        {t(tokens.settingsDrawer.nav.title)}
      </Typography>
      <Stack
        alignItems="center"
        direction="row"
        flexWrap="wrap"
        gap={2}
      >
        {options.map((option) => (
          <Chip
            key={option.label}
            label={option.label}
            onClick={() => onChange?.(option.value)}
            sx={{
              borderColor: 'transparent',
              borderRadius: 1.5,
              borderStyle: 'solid',
              borderWidth: 2,
              ...(option.value === value && {
                borderColor: 'primary.main',
              }),
            }}
          />
        ))}
      </Stack>
    </Stack>
  );
};
