import { t } from 'i18next';
import React, { useState } from 'react';
import dataProvider from 'src/data/provider/remote/dataProvider';
import { tokens } from 'src/locales/tokens';
import { AsyncStatusAction } from 'src/types/enums/async-status-action-enum';
import { AsyncStatusReducerAction } from 'src/types/reducers/async-status-reducer-types';
import { containsRestrictedCharacters } from 'src/utils/containsRestrictedCharacters';

import { useListExportModalContext } from './useListExportModalContext';

interface ExportNameProps {
  resource: string;
  isSuccess: boolean;
  isError: boolean;
  dispatch: React.Dispatch<AsyncStatusReducerAction>;
  defineError: (error: string) => void;
  defineSuccess: (message: string) => void;
}

interface ExportNameReturn {
  exportName: string;
  hasStartedTyping: boolean;
  hasRestrictedCharacters: boolean;
  handleExportNameChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleExport: () => Promise<void>;
}

/**
 * Custom hook for handling export name and export functionality.
 *
 * @param {ExportNameProps} props - The props containing resource, getFilters, and setExportCreatedSuccessfully.
 * @returns {ExportNameReturn} - The object containing exportName, hasStartedTyping, errorMessage, successMessage, isLoading, isSuccess, isError, handleExportNameChange, and handleExport.
 */
export const useExportName = ({
  resource,
  isSuccess,
  isError,
  dispatch,
  defineError,
  defineSuccess,
}: ExportNameProps): ExportNameReturn => {
  const [hasStartedTyping, setHasStartedTyping] = useState(false);
  const [hasRestrictedCharacters, setHasRestrictedCharacters] = useState(false);
  const [exportName, setExportName] = useState<string>('');
  const { getFilters, setIsExportCreatedSuccessfully } = useListExportModalContext();

  /**
   * Handles the change event of the export name input.
   *
   * @param {React.ChangeEvent<HTMLInputElement>} event - The change event.
   */
  const handleExportNameChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const newValue = event.target.value;
    if (!containsRestrictedCharacters(newValue)) {
      setHasRestrictedCharacters(false);
    } else {
      setHasRestrictedCharacters(true);
    }
    setExportName(event.target.value);
    setHasStartedTyping(true);

    if (isSuccess || isError) {
      dispatch({ type: AsyncStatusAction.IS_SUCCESS, payload: false });
      dispatch({ type: AsyncStatusAction.IS_ERROR, payload: false });
    }
  };

  /**
   * Handles the export action.
   */
  const handleExport = async (): Promise<void> => {
    dispatch({ type: AsyncStatusAction.IS_LOADING, payload: true });
    setHasStartedTyping(false);
    setExportName('');

    try {
      const filters = getFilters();
      await dataProvider.postClubExport(resource, { name: exportName, filters: filters });
      dispatch({ type: AsyncStatusAction.IS_LOADING, payload: false });
      dispatch({ type: AsyncStatusAction.IS_SUCCESS, payload: true });
      dispatch({ type: AsyncStatusAction.IS_ERROR, payload: false });
      setIsExportCreatedSuccessfully(true);
      defineSuccess(t(tokens.asyncMessages.FileCreationInProgress));
    } catch (error) {
      dispatch({ type: AsyncStatusAction.IS_LOADING, payload: false });
      dispatch({ type: AsyncStatusAction.IS_ERROR, payload: true });
      defineError(error as string);
    }
  };

  return {
    exportName,
    hasStartedTyping,
    hasRestrictedCharacters,
    handleExportNameChange,
    handleExport,
  };
};
