import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import Alert from '@mui/material/Alert';
import InputAdornment from '@mui/material/InputAdornment';
import { useTheme } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { t } from 'i18next';
import React, { useState } from 'react';
import withAsyncFeedback from 'src/common/hocs/withAsyncFeedback';
import { useExportName } from 'src/common/modals/hooks/useExportName';
import { tokens } from 'src/locales/tokens';
import { AsyncFeedback } from 'src/types/hocs';

import { ExportActionBar } from './ExportActionBar';

interface ExportProps {
  resource: string;
  asyncFeedback: AsyncFeedback;
}

const NewExport: React.FC<ExportProps> = ({ resource, asyncFeedback }) => {
  const theme = useTheme();
  const { isLoading, isSuccess, isError, dispatch, defineError, defineSuccess, AlertComponent } =
    asyncFeedback;
  const [isFocused, setIsFocused] = useState(false);
  const {
    exportName,
    handleExport,
    handleExportNameChange,
    hasRestrictedCharacters,
    hasStartedTyping,
  } = useExportName({
    resource,
    isSuccess,
    isError,
    dispatch,
    defineError,
    defineSuccess,
  });
  return (
    <>
      <Typography
        id="modal-title"
        variant="h6"
        component="h2"
      >
        {t(tokens.modale.export.list)}
      </Typography>
      <Typography
        id="modal-subtitle"
        variant="body1"
        component="h6"
        paddingBottom={1}
      >
        {t(tokens.modale.export.fileType)}
      </Typography>
      <TextField
        label="Nom de l'export"
        variant="outlined"
        value={exportName}
        onChange={handleExportNameChange}
        fullWidth
        disabled={isLoading}
        sx={{ backgroundColor: theme.palette.neutral[50] }}
        autoFocus
        InputProps={{
          style: { color: theme.palette.neutral[800] },
          startAdornment: (
            <InputAdornment position="start">
              <InsertDriveFileIcon />
            </InputAdornment>
          ),
        }}
        InputLabelProps={{
          shrink: isFocused || Boolean(exportName),
          style: { marginLeft: isFocused || Boolean(exportName) ? '0' : '2rem' },
        }}
        onFocus={() => setIsFocused(true)}
        onBlur={() => setIsFocused(false)}
      />
      {hasStartedTyping && !exportName && (
        <Alert
          severity="error"
          sx={{ width: '100%', marginTop: 2 }}
        >
          {t(tokens.modale.export.noEmptyFileName)}
        </Alert>
      )}
      {hasRestrictedCharacters && (
        <Alert
          severity="error"
          sx={{ width: '100%', marginTop: 2 }}
        >
          {t(tokens.modale.export.restrictedChar)}
        </Alert>
      )}
      {AlertComponent}
      <ExportActionBar
        exportName={exportName}
        handleExport={handleExport}
        isLoading={isLoading}
        hasRestrictedCharacters={hasRestrictedCharacters}
      />
    </>
  );
};

export const NewExportWithAsyncFeedback = withAsyncFeedback(NewExport) as React.ComponentType<
  Omit<ExportProps, 'asyncFeedback'>
>;
