import CardContent from '@mui/material/CardContent';
import React from 'react';
import { ThemedCard } from 'src/common/layouts/material-ui/card/ThemedCard';
import { ThemedCardTitle } from 'src/common/layouts/material-ui/card/ThemedCardTitle';

interface ContentCardProps {
  title: string;
  children: React.ReactNode;
}

export const ContentCard: React.FC<ContentCardProps> = ({ title, children }) => {
  return (
    <ThemedCard>
      <ThemedCardTitle>{title}</ThemedCardTitle>
      <CardContent sx={{ padding: 2 }}>{children}</CardContent>
    </ThemedCard>
  );
};
