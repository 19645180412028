import { Box, Typography } from '@mui/material';
import React from 'react';

interface ThemedCardTitleProps {
  children: React.ReactNode;
}

export const ThemedCardTitle: React.FC<ThemedCardTitleProps> = ({ children }) => {
  return (
    <Box sx={{ padding: 2 }}>
      <Typography
        fontSize="18px"
        fontWeight="bold"
      >
        {children}
      </Typography>
    </Box>
  );
};
