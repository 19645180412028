import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import React, { ForwardRefRenderFunction } from 'react';
import { TextField } from 'react-admin';
import AvatarField from 'src/components/react-admin/fields/common/AvatarField';

interface ClubUserRecordProps {
  width?: number;
}

const ClubUserRecordRepresentation: ForwardRefRenderFunction<
  HTMLDivElement,
  ClubUserRecordProps
> = ({ width = 200 }) => {
  return (
    <ListItem
      disablePadding
      dense
    >
      <ListItemAvatar>
        <AvatarField source={'fullName'} />
      </ListItemAvatar>
      <ListItemText
        primary={
          <TextField
            source={'fullName'}
            sx={{ maxWidth: width }}
          />
        }
        secondary={
          <TextField
            source={'email'}
            variant={'caption'}
            sx={{ color: 'text.primary' }}
          />
        }
      />
    </ListItem>
  );
};

export default ClubUserRecordRepresentation;
