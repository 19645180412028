import { t } from 'i18next';
import { tokens } from 'src/locales/tokens';
import { isEmptyOrSpaces } from 'src/utils/isEmptyOrSpaces';
import { isValidEmail } from 'src/utils/isValidEmail';

import { SecondStepType, ValidationError } from '../../../context';

export const validateSignatory = (values: SecondStepType, errors: ValidationError) => {
  if (isEmptyOrSpaces(values.firstName)) {
    errors.firstName = t(tokens.utils.form.required);
  }

  if (isEmptyOrSpaces(values.lastName)) {
    errors.lastName = t(tokens.utils.form.required);
  }

  if (isEmptyOrSpaces(values.email)) {
    errors.email = t(tokens.utils.form.required);
  } else if (!isValidEmail(values.email)) {
    errors.email = t(tokens.utils.form.invalidEmail);
  }

  if (isEmptyOrSpaces(values.number)) {
    errors.number = t(tokens.utils.form.required);
  }
};
