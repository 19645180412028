import { createContext } from 'react';

import { DefaultSettings, InitialState, SettingsContextValue } from './settingsTypes';

export const defaultSettings: DefaultSettings = {
  colorPreset: 'indigo',
  contrast: 'normal',
  direction: 'ltr',
  layout: 'vertical',
  navColor: 'evident',
  paletteMode: 'light',
  responsiveFontSizes: true,
  stretch: false,
};

export const initialState: InitialState = {
  ...defaultSettings,
  isInitialized: false,
  openDrawer: false,
};

export const SettingsContext = createContext<SettingsContextValue>({
  ...initialState,
  handleDrawerClose: () => {},
  handleDrawerOpen: () => {},
  handleReset: () => {},
  handleUpdate: () => {},
  isCustom: false,
});
