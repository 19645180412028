import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import React from 'react';

interface MailToFieldProps {
  receivers: string[];
  label: string;
  subject?: string;
}

export const MailtoField: React.FC<MailToFieldProps> = ({ receivers, label, subject }) => {
  if (!receivers.length) {
    return null;
  }

  let mailTo = 'mailto:';
  let isFirstReceiver = true;

  for (const receiver of receivers) {
    if (isFirstReceiver) {
      mailTo += '?to=' + receiver;
      isFirstReceiver = false;
    } else {
      mailTo += '&to=' + receiver;
    }
  }

  if (subject !== undefined) {
    mailTo += '&subject=' + subject;
  }

  return (
    <Link href={mailTo}>
      <Typography variant={'body2'}>{label}</Typography>
    </Link>
  );
};
