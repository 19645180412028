import { useAuth } from 'src/domains/system/auth/hooks/useAuth';
import { UserRole } from 'src/types/enums/user-roles-enum';

export const useRole = (requiredRole: UserRole): boolean => {
  const { user } = useAuth();
  const { roles } = user || {};
  return roles ? roles.includes(requiredRole) : false;
};

export default useRole;
