import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import Grid from '@mui/material/Grid';
import Stack from '@mui/system/Stack';
import React from 'react';

interface GeneralInformationWrapperProps {
  children: React.ReactNode;
}

export const GeneralInformationWrapper: React.FC<GeneralInformationWrapperProps> = ({
  children,
}) => {
  return (
    <Stack>
      <Grid
        container
        spacing={4}
      >
        <Grid
          item
          xs={12}
          lg={4}
        >
          <Card>
            <CardHeader title="Informations générales" />
            {children}
          </Card>
        </Grid>
      </Grid>
    </Stack>
  );
};
