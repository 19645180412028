import { fetchHydra as baseFetchHydra } from '@api-platform/admin';
import { parseHydraDocumentation } from '@api-platform/api-doc-parser';
import { Api } from '@api-platform/api-doc-parser/src';
import { t } from 'i18next';
import { DataProvider, fetchUtils, GetListParams } from 'react-admin';
import { resourceConfig } from 'src/config/resourceConfig';
import {
  ACCESS_TOKEN_STORAGE_KEY,
  REFRESH_TOKEN_STORAGE_KEY,
} from 'src/contexts/auth/jwt/AuthProvider';
import customDataProvider from 'src/data/provider/remote/hydra-provider';
import { FinalData } from 'src/domains/club/contract/context';
import { tokens } from 'src/locales/tokens';
import { CustomDataProvider } from 'src/types/config';
import { ListExport, ListExportRequest } from 'src/types/contexts/modals';
import { AccessRights } from 'src/types/pages/users';
import { getRequestOptions } from 'src/utils/getRequestOptions';
import { handleHttpError } from 'src/utils/handleHttpError';

export const ENTRYPOINT = import.meta.env.VITE_API_ENTRYPOINT;

export const getAuthenticationHeader = (): HeadersInit => {
  return localStorage.getItem(ACCESS_TOKEN_STORAGE_KEY)
    ? {
        Authorization: `Bearer ${localStorage.getItem(ACCESS_TOKEN_STORAGE_KEY)}`,
      }
    : {};
};

export const getHeaders = ({ options: { headers = {} } }): HeadersInit => {
  return { ...headers, ...getAuthenticationHeader() };
};

const fetchHydra = (url: URL, options = {}) =>
  baseFetchHydra(url, {
    ...options,
    headers: getHeaders({ options }),
  });

const apiDocumentationParser = async () => {
  try {
    return await parseHydraDocumentation(ENTRYPOINT, { headers: getAuthenticationHeader });
  } catch (result: unknown) {
    if (typeof result === 'object' && result !== null) {
      const { api, response, status } = result as {
        api: Api;
        response: Response;
        status: number;
      };
      if (status !== 401 || !response) {
        throw result;
      }

      // Prevent infinite loop if the token is expired
      localStorage.removeItem(ACCESS_TOKEN_STORAGE_KEY);
      localStorage.removeItem(REFRESH_TOKEN_STORAGE_KEY);

      return {
        api,
        response,
        status,
      };
    }
    throw result;
  }
};

const baseDataProvider: DataProvider = customDataProvider({
  entrypoint: ENTRYPOINT,
  httpClient: fetchHydra,
  apiDocumentationParser: apiDocumentationParser,
});

const dataProvider: CustomDataProvider = {
  ...baseDataProvider,
  getOne: (resource, params) =>
    baseDataProvider.getOne(resource, params).then(({ data }) => ({
      data: { ...data, id: data['@id'] as string },
    })),
  getList: (resource, params) => {
    const config = resourceConfig[resource];
    if (config) {
      let finalParams: GetListParams = { ...params };
      config.forEach((func) => {
        finalParams = func(finalParams);
      });

      return baseDataProvider.getList(resource, finalParams);
    }

    return baseDataProvider.getList(resource, params);
  },
  getAccessRights: async (userId: string) => {
    const url = `${ENTRYPOINT}/users/${userId}/access_rights`;
    try {
      const response = await fetchUtils.fetchJson(url, getRequestOptions('GET'));
      const accessRights = response.json['hydra:member'] as AccessRights;
      return accessRights;
    } catch (error) {
      throw error;
    }
  },
  updateAccessRights: async (userId: string, params: { accessRights: AccessRights }) => {
    const url = `${ENTRYPOINT}/users/${userId}/access_rights`;
    try {
      const response = await fetchUtils.fetchJson(
        url,
        getRequestOptions('PATCH', params, 'application/merge-patch+json')
      );
      const accessRights = response.json['hydra:member'] as AccessRights;
      return accessRights;
    } catch (error) {
      throw error;
    }
  },
  getExport: async (resource: string) => {
    const url = `${ENTRYPOINT}/${resource}/export/requests?order[createdAt]=desc`;
    try {
      const response = await fetchUtils.fetchJson(url, getRequestOptions('GET'));
      return response.json['hydra:member'] as ListExport[];
    } catch (error) {
      throw error;
    }
  },
  postClubExport: async (resource: string, params: ListExportRequest) => {
    const url = `${ENTRYPOINT}/${resource}/export/requests`;
    try {
      const response = await fetchUtils.fetchJson(
        url,
        getRequestOptions('POST', params, 'application/ld+json')
      );
      return response.json['hydra:member'] as ListExport;
    } catch (error) {
      handleHttpError(error, t(tokens.dataProvider.export.postError));
    }
  },
  retryClubExport: async (url: string) => {
    try {
      const response = await fetchUtils.fetchJson(
        url,
        getRequestOptions('PATCH', {}, 'application/merge-patch+json')
      );
      if (response.status >= 200 && response.status < 300) {
        console.log('Retry successful:', response.json);
        return response.json;
      } else {
        console.log('Unexpected status:', response.status);
      }
    } catch (error) {
      handleHttpError(error, t(tokens.dataProvider.export.retryError));
    }
  },
  cancelClubExport: async (url: string) => {
    try {
      const response = await fetchUtils.fetchJson(
        url,
        getRequestOptions('PATCH', {}, 'application/merge-patch+json')
      );
      if (response.status >= 200 && response.status < 300) {
        console.log('Retry successful:', response.json);
        return response.json;
      } else {
        console.log('Unexpected status:', response.status);
      }
    } catch (error) {
      handleHttpError(error, t(tokens.dataProvider.export.cancelError));
    }
  },
  downloadExport: async (url: string) => {
    try {
      const response = await fetchUtils.fetchJson(url, getRequestOptions('GET'));
      return response.json['url'] as string;
    } catch (error) {
      handleHttpError(error, t(tokens.dataProvider.export.downloadError));
    }
  },
  postContract: async (data: FinalData) => {
    const url = `${ENTRYPOINT}/contracts`;
    try {
      const response = await fetchUtils.fetchJson(
        url,
        getRequestOptions('POST', data, 'application/ld+json')
      );
      console.log('response', response);
      if (response.status === 204) {
        // Handle 204 No Content
        return { status: 204, headers: response.headers, body: null };
      }

      return response;
    } catch (error) {
      handleHttpError(error, t(tokens.dataProvider.contract.error));
    }
  },
};

export default dataProvider;
