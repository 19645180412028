import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { parseISO } from 'date-fns';
import React, { useCallback, useEffect, useState } from 'react';
import { DatePickerComponentStyles } from 'src/common/styles/date-picker-component-styles';
import { FilterLabels } from 'src/types/enums';

export interface DatePickerComponentProps {
  label: string;
  minDate?: Date;
  maxDate?: Date;
  onDateChange?: (date: Date | null) => void;
  setFilters: (
    filters: { [key: string]: string[] | Date },
    displayedFilters: unknown,
    debounce?: boolean
  ) => void;
  filterValues: { [key: string]: string[] | Date };
  value: string;
  setStartDate?: (date: Date | undefined) => void;
  setEndDate?: (date: Date | undefined) => void;
}

export const DatePickerFilter: React.FC<DatePickerComponentProps> = ({
  label,
  minDate,
  maxDate,
  onDateChange,
  setFilters,
  filterValues,
  value,
  setStartDate,
  setEndDate,
}) => {
  const [selectedDate, setSelectedDate] = useState<Date | null>(null);

  const updateDatePickerTagsAndQueryFilters = useCallback(
    (date: Date) => {
      setSelectedDate(date); // Update local state
      // Convertir en UTC
      const utcDate = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()));
      if (label === FilterLabels.CreatedAt) utcDate.setHours(0, 0, 0, 0);
      if (label === FilterLabels.EndAt) utcDate.setHours(23, 59, 59, 0);

      setFilters({ ...filterValues, [value]: utcDate }, {}); // Automatic fetch with new filters
    },
    [filterValues, label, setFilters, value]
  );

  useEffect(() => {
    if (filterValues[value]) {
      const dateAsString = filterValues[value] as unknown as string;
      const date = parseISO(dateAsString);

      // Set the start or end date to handle the min max date range in the date picker
      setStartDate?.(date);
      setEndDate?.(date);

      updateDatePickerTagsAndQueryFilters(date);
    } else {
      setSelectedDate(null);
    }
  }, [filterValues, setEndDate, setStartDate, updateDatePickerTagsAndQueryFilters, value]);

  const handleChange = (date: Date | null) => {
    if (date) {
      onDateChange?.(date);
      updateDatePickerTagsAndQueryFilters(date);
    }
  };

  return (
    <DatePicker
      label={label}
      value={selectedDate}
      onChange={handleChange}
      minDate={minDate}
      maxDate={maxDate}
      slotProps={DatePickerComponentStyles}
      format="dd-MM-yyyy"
      disableFuture={true}
    />
  );
};
