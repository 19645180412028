import { useIntrospect } from '@api-platform/admin';
import React, { useEffect, useState } from 'react';
import { CoreLayoutProps, LayoutComponent, Notification } from 'react-admin';
import { SplashScreen } from 'src/components/SplashScreen';
import { useSettings } from 'src/hooks/useSettings';

import { useSections } from './config';
import { VerticalLayout } from './vertical-layout';

export const Layout: LayoutComponent = (props: CoreLayoutProps) => {
  const settings = useSettings();
  const sections = useSections();
  const { refetch } = useIntrospect();
  const [initialized, setInitialized] = useState(false);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    if (!initialized && !loading) {
      setLoading(true);
      refetch()
        .then(() => {
          setInitialized(true);
          setLoading(false);
        })
        .catch(console.error);
    }
  }, [refetch, initialized, setInitialized, loading]);
  return (
    <>
      <SplashScreen
        open={!initialized}
        subtitle={'Chargement de votre espace...'}
      />
      {initialized ? (
        <VerticalLayout
          sections={sections}
          navColor={settings.navColor}
          {...props}
        />
      ) : (
        <></>
      )}
      <Notification />
    </>
  );
};
