import { Box, InputLabel, Typography } from '@mui/material';
import React from 'react';
import { useRecordContext } from 'react-admin';
import { Organization } from 'src/admin-api-types';

interface ConcatAddressProps {
  label: string;
}

export const ConcatAddressLabel: React.FC<ConcatAddressProps> = ({ label }) => {
  const record = useRecordContext<Organization>();

  if (!record || !record.postalAddress) {
    return null;
  }

  const { street, zipCode, city } = record.postalAddress;

  const Address = `${street}, ${zipCode}`;
  const City = `${city}`;

  return (
    <Box
      sx={{
        width: '100%',
      }}
    >
      <Typography
        variant={'body2'}
        fontWeight={600}
        gutterBottom
      >
        {label}
      </Typography>
      <Typography variant="body2">{Address}</Typography>
      <Typography variant="body2">{City}</Typography>
    </Box>
  );
};
