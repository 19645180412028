import ButtonBase from '@mui/material/ButtonBase';
import Collapse from '@mui/material/Collapse';
import { RouterLink } from 'src/components/RouterLink';
import useStartIcon from 'src/hooks/useStartIcon';
import ButtonContent from 'src/layouts/dashboard/vertical-layout/ButtonContent';

interface SideNavItemProps {
  active: boolean;
  children?: React.ReactNode;
  depth: number;
  disabled?: boolean;
  icon?: JSX.Element;
  label?: string;
  path: string;
  title: string;
  component?: React.ReactNode;
}

export const SideNavItem: React.FC<SideNavItemProps> = ({
  active,
  children,
  depth = 0,
  disabled,
  icon,
  label,
  path,
  title,
  component,
}) => {
  const startIcon = useStartIcon({ depth, active, icon });

  /**
   * Calculates the offset based on the depth of the side nav item.
   * If the depth is 0, the offset is 0. Otherwise, the offset is calculated as (depth - 1) * 16.
   * @param depth The depth of the side nav item.
   * @returns The calculated offset.
   */
  const offset = depth === 0 ? 0 : (depth - 1) * 16;

  /**
   * Defines the link properties for the side navigation item.
   * @param path - The path of the link.
   * @returns The link properties object.
   */
  const linkProps = { component: RouterLink, href: path };

  return (
    <li>
      {component ? (
        component
      ) : (
        <ButtonBase
          disabled={disabled}
          sx={{
            alignItems: 'center',
            borderRadius: 1,
            display: 'flex',
            justifyContent: 'flex-start',
            pl: `${16 + offset}px`,
            pr: '16px',
            py: '6px',
            textAlign: 'left',
            width: '100%',
            backgroundColor: active && depth === 0 ? 'var(--nav-item-active-bg)' : 'transparent',
            '&:hover': {
              backgroundColor: 'var(--nav-item-hover-bg)',
            },
          }}
          {...linkProps}
        >
          <ButtonContent
            startIcon={startIcon}
            active={active}
            title={title}
            label={label}
            depth={depth}
            disabled={disabled}
          />
        </ButtonBase>
      )}

      {children && (
        <Collapse
          in={true}
          sx={{ mt: 0.5 }}
        >
          {children}
        </Collapse>
      )}
    </li>
  );
};
