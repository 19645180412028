import { t } from 'i18next';
import { tokens } from 'src/locales/tokens';
import { isEmptyOrSpaces } from 'src/utils/isEmptyOrSpaces';
import { isValidPostalCode } from 'src/utils/isValidPostalCode';

import { FirstStepType, ValidationError } from '../../../context';

export const validateAddress = (
  values: FirstStepType,
  errors: ValidationError,
  isCheckboxChecked: boolean
) => {
  if (isEmptyOrSpaces(values.fullAddress) && !isCheckboxChecked) {
    errors.fullAddress = t(tokens.utils.form.required);
  }

  if (isEmptyOrSpaces(values.fullAddress) && isCheckboxChecked) {
    delete errors.fullAddress;
  }

  if (isCheckboxChecked) {
    delete errors.fullAddress;
    if (isEmptyOrSpaces(values.address)) {
      errors.address = t(tokens.utils.form.required);
    }

    if (isEmptyOrSpaces(values.postalCode)) {
      errors.postalCode = t(tokens.utils.form.required);
    } else if (!isValidPostalCode(values.postalCode)) {
      errors.postalCode = t(tokens.domains.contract.validators.invalidPostalcode);
    }

    if (isEmptyOrSpaces(values.city)) {
      errors.city = t(tokens.utils.form.required);
    }

    if (isEmptyOrSpaces(values.country) || values.country === 'value0') {
      errors.country = t(tokens.utils.form.required);
    }

    if (isEmptyOrSpaces(values.closestAddress)) {
      errors.closestAddress = t(tokens.utils.form.required);
    }
  }
};
