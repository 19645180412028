import InfoIcon from '@mui/icons-material/Info';
import { Box, Tooltip, Typography } from '@mui/material';
import React from 'react';

interface TopInputLabelLayoutProps {
  label: string;
  disabled?: boolean;
  tooltipTitle?: string;
  children: React.ReactNode;
}

export const TopLabelInputLayout: React.FC<TopInputLabelLayoutProps> = ({
  label,
  tooltipTitle,
  children,
}) => {
  return (
    <Box
      sx={{
        width: '100%',
        marginLeft: '0px !important',
      }}
    >
      <Box
        sx={{
          display: 'flex',
        }}
      >
        <Typography
          variant={'body2'}
          fontWeight={600}
          gutterBottom
        >
          {label}
        </Typography>
        {tooltipTitle && (
          <Tooltip
            title={tooltipTitle}
            placement="top-end"
            arrow
            componentsProps={{
              tooltip: {
                sx: {
                  padding: '16px',
                  bgcolor: 'neutral.200',
                  '& .MuiTooltip-arrow': {
                    color: 'neutral.200',
                  },
                  color: 'neutral.500',
                },
              },
            }}
          >
            <InfoIcon
              sx={{
                color: 'neutral.500',
                fontSize: '18px',
                marginLeft: '16px',
              }}
            />
          </Tooltip>
        )}
      </Box>
      {children}
    </Box>
  );
};
