import { Box, Container, Stack } from '@mui/material';
import { Seo } from 'src/components/Seo';

interface DetailLayoutProps {
  children: React.ReactNode;
  seoTitle: string;
}

export const DetailLayout: React.FC<DetailLayoutProps> = ({ children, seoTitle }) => {
  return (
    <>
      <Seo title={seoTitle} />
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          py: 8,
          width: '100%',
        }}
      >
        <Container maxWidth="xl">
          <Stack spacing={4}>{children}</Stack>
        </Container>
      </Box>
    </>
  );
};
