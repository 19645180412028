import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/system/Box';
import React, { useState, useEffect } from 'react';
import { AccessRightsAction } from 'src/types/enums';
import { AccessRightsReducerAction } from 'src/types/reducers';

interface UserAccessRightsAlertProps {
  areAccessRightsModified: boolean;
  isFetchingAccessRights: boolean;
  hasUpdatedAccessRights: boolean;
  handleAccessRightsCancel: () => void;
  handleAccessRightsSave: () => Promise<void>;
  dispatch: React.Dispatch<AccessRightsReducerAction>;
}

export const UserAccessRightsAlert: React.FC<UserAccessRightsAlertProps> = ({
  areAccessRightsModified,
  isFetchingAccessRights,
  hasUpdatedAccessRights,
  handleAccessRightsCancel,
  handleAccessRightsSave,
  dispatch,
}) => {
  const [showSuccessAlert, setShowSuccessAlert] = useState(hasUpdatedAccessRights);

  useEffect(() => {
    if (hasUpdatedAccessRights) {
      setShowSuccessAlert(true);
      const timer = setTimeout(() => {
        setShowSuccessAlert(false);
        dispatch({ type: AccessRightsAction.SET_HAS_UPDATED_ACCESS_RIGHTS, payload: false });
      }, 2000);
      return () => clearTimeout(timer);
    }
  }, [dispatch, hasUpdatedAccessRights]);
  return (
    <Box
      sx={{
        overflow: 'hidden',
        marginTop: '15px',
        marginBottom: '10px',
      }}
    >
      {areAccessRightsModified && (
        <Alert
          variant="outlined"
          severity="warning"
          sx={{
            opacity: areAccessRightsModified ? 1 : 0,
            transition: 'opacity 0.3s ease-in-out',
            alignItems: 'center',
            borderColor: 'warning.main',
            '.MuiAlert-action': {
              paddingTop: 0,
            },
          }}
          action={
            <>
              <Button
                size="small"
                color="inherit"
                onClick={handleAccessRightsCancel}
                sx={{
                  marginRight: '10px',
                }}
                disabled={isFetchingAccessRights}
              >
                Annuler
              </Button>
              <Button
                size="small"
                variant="contained"
                onClick={() => {
                  handleAccessRightsSave().catch((error) => {
                    console.error("Erreur lors de l'enregistrement des droits d'accès", error);
                  });
                }}
              >
                {!isFetchingAccessRights ? (
                  'Enregistrer'
                ) : (
                  <CircularProgress
                    size={24}
                    sx={{ color: 'white' }}
                  />
                )}
              </Button>
            </>
          }
        >
          {"Les droits d'accès doivent être enregistrés"}
        </Alert>
      )}
      {showSuccessAlert && (
        <Alert
          variant="outlined"
          severity="success"
          sx={{
            transition: 'opacity 0.3s ease-in-out',
            alignItems: 'center',
          }}
        >
          {"Les droits d'accès ont été enregistrés avec succès"}
        </Alert>
      )}
    </Box>
  );
};
