import { ClickAwayListener, tooltipClasses } from '@mui/material';
import { styled } from '@mui/material/styles';
import Tooltip, { TooltipProps } from '@mui/material/Tooltip';
import React, { useCallback, useState } from 'react';
import { useRecordContext } from 'react-admin';
import { Ticket } from 'src/admin-api-types';
import HelpdeskTicketStatusToggleField from 'src/domains/club/helpdesk/components/common/fields/HelpdeskTicketStatusToggleField';
import HelpdeskTicketStatusChipField from 'src/domains/club/helpdesk/components/list/fields/HelpdeskTicketStatusChipField';
import {
  getHelpdeskTicketStatus,
  HelpdeskTicketStatusOption,
} from 'src/domains/club/helpdesk/data';

const TransparentTooltip = styled(({ className, children, ...props }: TooltipProps) => (
  <Tooltip
    {...props}
    classes={{ popper: className }}
  >
    {children}
  </Tooltip>
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    padding: theme.spacing(1),
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[10],
  },
}));

interface HelpdeskTicketStatusFieldProps {
  label: string;
}

const HelpdeskTicketStatusField: React.FC<HelpdeskTicketStatusFieldProps> = () => {
  const { status } = useRecordContext<Ticket>();
  const [open, setOpen] = useState<boolean>(false);
  const handleClose = useCallback(() => {
    setOpen(false);
  }, [setOpen]);

  const handleOpen = useCallback(() => {
    setOpen(true);
  }, [setOpen]);

  if (status == null) {
    return null;
  }

  const validStatus = status as HelpdeskTicketStatusOption;

  return (
    <ClickAwayListener onClickAway={handleClose}>
      <div>
        <TransparentTooltip
          placement={'right-start'}
          arrow
          disableHoverListener
          disableTouchListener
          disableFocusListener
          open={open}
          title={<HelpdeskTicketStatusToggleField />}
        >
          <HelpdeskTicketStatusChipField
            status={getHelpdeskTicketStatus(validStatus)}
            onClick={handleOpen}
            disabled={false}
          />
        </TransparentTooltip>
      </div>
    </ClickAwayListener>
  );
};

export default HelpdeskTicketStatusField;
