import { useState } from 'react';

type FormData = {
  [key: string]: string | number | boolean;
};

export const useCreate = () => {
  const [successMessage, setSuccessMessage] = useState<string | null>(null);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const transform = (data: FormData) => ({
    ...data,
    extraData: `extra data`,
  });

  const onSuccess = () => {
    setSuccessMessage('Create successful');
  };

  const onError = () => {
    setErrorMessage('Create failed');
  };

  return { successMessage, errorMessage, transform, onSuccess, onError };
};
