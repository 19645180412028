import { Box } from '@mui/material';
import Typography from '@mui/material/Typography';
import { t } from 'i18next';
import { useEffect, useState } from 'react';
import { useListContext } from 'react-admin';
import { tokens } from 'src/locales/tokens';

export const TotalItems = () => {
  const [result, setResult] = useState<number | undefined>(undefined);
  const context = useListContext();

  useEffect(() => {
    setResult(context.total);
  }, [context]);

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'right',
      }}
    >
      <Typography variant="body1">{`${result || 0} ${t(tokens.domains.clubs.results)}`}</Typography>
    </Box>
  );
};
