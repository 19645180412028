import { useState } from 'react';

import {
  FirstStepType,
  initialFirstStep,
  initialFormErrors,
  initialIsCheckboxChecked,
  initialSecondStep,
  SecondStepType,
  ValidationError,
} from '../context';

/**
 * Custom hook for managing the state of a contract form.
 * @returns An object containing the form state and state update functions.
 */
export const useContractState = () => {
  const [formErrors, setFormErrors] = useState<ValidationError>(initialFormErrors);
  const [isCheckboxChecked, setIsCheckboxChecked] = useState(initialIsCheckboxChecked);
  const [firstStep, setFirstStep] = useState<FirstStepType>(initialFirstStep);
  const [secondStep, setSecondStep] = useState<SecondStepType>(initialSecondStep);

  return {
    formErrors,
    setFormErrors,
    isCheckboxChecked,
    setIsCheckboxChecked,
    firstStep,
    setFirstStep,
    secondStep,
    setSecondStep,
  };
};
