import { CircularProgress, Stack } from '@mui/material';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Typography from '@mui/material/Typography';
import { t } from 'i18next';
import React from 'react';
import { Button } from 'react-admin';
import { tokens } from 'src/locales/tokens';

export const ConfirmationModal: React.FC<{
  open: boolean;
  onClose: (event: object, reason: 'backdropClick' | 'escapeKeyDown') => void;
  handleConfirmation: () => void;
  isLoading: boolean;
}> = ({ open, onClose, handleConfirmation, isLoading }) => {
  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="confirmation-modal-title"
      aria-describedby="confirmation-modal-description"
    >
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 600,
          bgcolor: 'background.paper',
          boxShadow: 24,
          p: 4,
          textAlign: 'center',
          borderRadius: '10px',
        }}
      >
        <Box
          alt="Non autorisé"
          component="img"
          src="/assets/errors/red-alarm.svg"
          sx={{
            height: 'auto',
            width: '60px',
            paddingTop: '16px',
          }}
        />
        <Typography
          id="confirmation-modal-title"
          variant="h5"
          component="h1"
          textAlign="center"
          sx={{ mt: 2 }}
        >
          {t(tokens.domains.contract.modal.title)}
        </Typography>
        <Typography
          id="confirmation-modal-description"
          sx={{ mt: 8 }}
          textAlign="center"
        >
          {t(tokens.domains.contract.modal.description)}
        </Typography>
        <Stack
          flexDirection="row"
          gap={3}
          sx={{ mt: 8 }}
        >
          <Button
            variant="outlined"
            color="primary"
            onClick={onClose}
            label={t(tokens.domains.contract.modal.cancel)}
            fullWidth
            sx={{
              padding: '16px 0',
              fontSize: '14px',
            }}
            disabled={isLoading}
          />
          <Button
            variant="contained"
            color="primary"
            fullWidth
            onClick={handleConfirmation}
            sx={{
              padding: '16px 0',
              fontSize: '14px',
              position: 'relative',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
            disabled={isLoading}
          >
            {isLoading ? (
              <CircularProgress size={24} />
            ) : (
              <>{t(tokens.domains.contract.modal.confirm)}</>
            )}
          </Button>
        </Stack>
      </Box>
    </Modal>
  );
};
