import { t } from 'i18next';
import { Header } from 'src/common/components/grid/Header';
import { ListLayout } from 'src/common/layouts/react-admin/ListLayout';
import { tokens } from 'src/locales/tokens';

import { MemberListTable } from './components';

export const MemberList = () => {
  return (
    <ListLayout seoTitle={t(tokens.seo.member.title)}>
      <Header
        title={t(tokens.domains.members.memberHeaderTitle)}
        resource="members"
        hasExport={true}
      />
      <MemberListTable />
    </ListLayout>
  );
};
