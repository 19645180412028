import Stack from '@mui/system/Stack';
import { t } from 'i18next';
import { DateField, FunctionField, NumberField, ShowButton, TextField } from 'react-admin';
import { Club } from 'src/admin-api-types';
import { ColumnHeaderLabel } from 'src/common/enums/react-admin';
import { DatagridLayout } from 'src/common/layouts/react-admin';
import { ClubSortByEnum } from 'src/data/enum/local/orderFilterEnum';
import { ClubStatusField } from 'src/domains/club/clubs/components/react-admin/fields';
import { tokens } from 'src/locales/tokens';

import { ClubSourceEnum } from '../../enums/club-source-enum';

interface CenteredCellProps {
  children: React.ReactNode;
}

const CenteredCell: React.FC<CenteredCellProps> = ({ children }) => (
  <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
    {children}
  </div>
);

export const ClubGrid = () => {
  return (
    <DatagridLayout content={t(tokens.domains.clubs.list.grid.noResult)}>
      <TextField
        source={ClubSourceEnum.NAME}
        label={ColumnHeaderLabel.clubName}
      />

      <FunctionField
        label={ColumnHeaderLabel.president}
        sortBy={ClubSortByEnum.ORDER_BY_PRESIDENT_NAME}
        render={(record: Club) =>
          record.president ? (
            <Stack>
              <TextField source={'president.fullName'} />
              <TextField
                source={'president.email'}
                variant={'caption'}
              />
              <TextField
                source={'president.phone'}
                variant={'caption'}
              />
            </Stack>
          ) : (
            <>-</>
          )
        }
      ></FunctionField>

      <TextField
        label={ColumnHeaderLabel.agency}
        reference={''}
        source={'agency.name'}
        sortable={false}
      />
      <TextField
        source={ClubSourceEnum.FORMAT}
        sortBy={ClubSortByEnum.ORDER_BY_FORMAT}
        label={ColumnHeaderLabel.format}
      />
      <TextField
        label={ColumnHeaderLabel.department}
        source={ClubSourceEnum.DEPARTMENT}
        sortBy={ClubSortByEnum.ORDER_BY_DEPARTMENT_CODE}
      />
      <FunctionField
        label={ColumnHeaderLabel.status}
        sortBy={ClubSortByEnum.ORDER_BY_STATUS}
        render={() => (
          <CenteredCell>
            <ClubStatusField source={ClubSourceEnum.STATUS} />
          </CenteredCell>
        )}
      />

      <FunctionField
        label={ColumnHeaderLabel.members}
        sortBy={ClubSortByEnum.ORDER_BY_COUNT_ACTIVE_MEMBERS}
        render={() => (
          <CenteredCell>
            <NumberField source={ClubSourceEnum.COUNT_ACTIVE_MEMBERS} />
          </CenteredCell>
        )}
      />
      <DateField
        source={ClubSourceEnum.CREATED_AT}
        label={ColumnHeaderLabel.createdAt}
      />
      <FunctionField
        label={ColumnHeaderLabel.action}
        render={(record: Club) => (record.president ? <ShowButton /> : <></>)}
      />
    </DatagridLayout>
  );
};
