import { Link } from '@mui/material';
import { Trans } from 'react-i18next';
import { tokens } from 'src/locales/tokens';

export const PresidentAlert: React.FC = () => {
  return (
    <Trans
      i18nKey={tokens.domains.contract.info}
      components={[
        <Link
          key={'clubdaffaires'}
          href="https://www.clubsdaffaires.fr/"
          target="_blank"
          rel="noopener noreferrer"
          sx={{ color: 'inherit', textDecoration: 'underline' }}
        />,
      ]}
    />
  );
};
