import { t } from 'i18next';
import { Organization } from 'src/admin-api-types';
import { ValidationError } from 'src/domains/club/contract/context';
import { tokens } from 'src/locales/tokens';
import { isEmptyOrSpaces } from 'src/utils/isEmptyOrSpaces';

export const validateName = (values: Partial<Organization>, errors: ValidationError) => {
  if (isEmptyOrSpaces(values.name)) {
    errors.name = t(tokens.utils.form.required);
  }
};
