import { t } from 'i18next';
import { tokens } from 'src/locales/tokens';
import { isEmptyOrSpaces } from 'src/utils/isEmptyOrSpaces';

import { SecondStepType, ValidationError } from '../../../context';

export const validateRcsCity = (values: SecondStepType, errors: ValidationError) => {
  if (isEmptyOrSpaces(values.companyRegister)) {
    errors.companyRegister = t(tokens.utils.form.required);
  }
};
