import { ListItemText } from '@mui/material';
import React from 'react';

interface ButtonTabProps {
  title: string;
  active?: boolean;
  disabled: boolean;
  onClick: () => void;
}

export const ButtonTab: React.FC<ButtonTabProps> = ({ title, active, disabled, onClick }) => {
  return (
    <ListItemText
      primary={title}
      sx={{
        marginTop: '10px',
        color: disabled ? 'text.disabled' : active ? 'primary.main' : 'text.secondary',
        fontWeight: active ? 'bold' : 'normal',
        cursor: disabled ? 'default' : 'pointer',
        '&:hover': {
          color: disabled ? 'text.disabled' : 'primary.dark',
        },
      }}
      onClick={disabled ? undefined : onClick}
    >
      {title}
    </ListItemText>
  );
};
