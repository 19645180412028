import * as Sentry from '@sentry/react';
import { Suspense } from 'react';
import { createRoot } from 'react-dom/client';
import { HelmetProvider } from 'react-helmet-async';
import { BrowserRouter } from 'react-router-dom';

import App from './app';
import { SettingsProvider } from './domains/system/settings';
import { jwtDecode } from 'jwt-decode';

export const SENTRY_DSN = import.meta.env.ADMIN_APP_SENTRY_DSN as string;
export const SENTRY_ENVIRONMENT = import.meta.env.PLATFORM_ENVIRONMENT as string;

Sentry.init({
  dsn: SENTRY_DSN,
  integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
  environment: SENTRY_ENVIRONMENT,
  // Tracing
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

function setUserInSentry() {
  const token = localStorage.getItem('accessToken');

  if (token) {
    try {
      const decodedToken = jwtDecode(token);
      const email = decodedToken?.email as string;
      if (email) {
        Sentry.setUser({ email });
      }
    } catch (error) {
      console.error('Failed to decode JWT', error);
    }
  }
}

setUserInSentry();

const root = createRoot(document.getElementById('root') as HTMLElement);

root.render(
  <HelmetProvider>
    <BrowserRouter>
      <Suspense>
        <SettingsProvider>
          <App />
        </SettingsProvider>
      </Suspense>
    </BrowserRouter>
  </HelmetProvider>
);
