import Avatar from '@mui/material/Avatar';
import { useTheme } from '@mui/material/styles';
import { RaRecord } from 'ra-core';
import React from 'react';
import { useRecordContext } from 'react-admin';

function stringAvatar(name: string, size: number) {
  return {
    initials: `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`,
    sx: {
      width: size,
      height: size,
    },
  };
}

enum AvatarSizesEnum {
  xs = 24,
  sm = 32,
  md = 48,
  lg = 64,
}

interface AvatarFieldProps {
  source: string;
  size?: keyof typeof AvatarSizesEnum;
  center?: boolean;
}

const AvatarField: React.FC<AvatarFieldProps> = ({
  source,
  size = 'sm',
  center = false,
  ...rest
}) => {
  const record: RaRecord = useRecordContext() ?? [];

  const stringSource: string = (record[source] as string) ?? '? ?';
  const avatarPx: number = AvatarSizesEnum[size];

  const { sx, initials } = stringAvatar(stringSource, avatarPx);
  const {
    palette: {
      primary: { main },
      getContrastText,
    },
  } = useTheme();

  return (
    <Avatar
      sx={{ ...sx, bgcolor: main, color: getContrastText(main), margin: center ? '0 auto' : '0' }}
      {...rest}
    >
      {initials}
    </Avatar>
  );
};

export default AvatarField;
