import { t } from 'i18next';
import { tokens } from 'src/locales/tokens';
import { isEmptyOrSpaces } from 'src/utils/isEmptyOrSpaces';

import { SecondStepType, ValidationError } from '../../../context';

export const validateLegalForm = (values: SecondStepType, errors: ValidationError) => {
  if (isEmptyOrSpaces(values.legalForm) || values.legalForm === 'value0') {
    errors.legalForm = t(tokens.utils.form.required);
  }
};
