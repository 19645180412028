import Typography from '@mui/material/Typography';
import { t } from 'i18next';
import React, { useMemo } from 'react';
import { BaseListWithCustomScrollbar } from 'src/common/components/BaseListWithCustomScrollbar';
import { useAsyncFeedbackContext } from 'src/common/hooks/useAsyncFeedbackContext';
import { useAsyncStatus } from 'src/common/hooks/useAsyncStatus';
import { useExportList } from 'src/common/modals/hooks/useExportList';
import { tokens } from 'src/locales/tokens';

import { DividerStyled } from '../styles/style';

import { EmptyState } from './EmptyState';
import { ErrorState } from './ErrorState';
import { ExportListItem } from './ExportListItem';
import { LoadingState } from './LoadingState';

interface ListExportModalProps {
  resource: string;
}

export const ExportsList: React.FC<ListExportModalProps> = ({ resource }) => {
  const { isLoading, isError, dispatch } = useAsyncStatus();
  const { exportHistory, errorMessage } = useExportList(dispatch, resource);

  const { AlertComponent } = useAsyncFeedbackContext();

  const exportList = useMemo(() => {
    if (isLoading && exportHistory?.length === 0) return <LoadingState />;
    if (isError) return <ErrorState message={errorMessage} />;
    if (!exportHistory?.length && !isLoading) return <EmptyState />;

    return (
      exportHistory &&
      exportHistory.length > 0 &&
      exportHistory.map((item, index) => (
        <ExportListItem
          key={index}
          item={item}
          resource={resource}
        />
      ))
    );
  }, [errorMessage, exportHistory, isError, isLoading, resource]);
  return (
    <>
      <DividerStyled sx={{ my: 2, width: '100%' }} />
      <Typography
        variant="h6"
        paddingTop={1}
        paddingBottom={1}
      >
        {t(tokens.modale.export.historyTitle)}
      </Typography>
      {AlertComponent}
      <BaseListWithCustomScrollbar>{exportList}</BaseListWithCustomScrollbar>
    </>
  );
};
