import React from 'react';
import { Pagination } from 'react-admin';
import { Navigate } from 'react-router-dom';
import { ListBaseLayout } from 'src/common/layouts/react-admin';
import { HelpdeskFilters } from 'src/domains/club/helpdesk/components/list/filters/HelpdeskFilters';
import { HelpdeskGrid } from 'src/domains/club/helpdesk/components/list/HelpdeskGrid';
import useRole from 'src/domains/system/auth/hooks/useRole';
import { UserRole } from 'src/types/enums/user-roles-enum';

export const HelpdeskTicketListTable: React.FC = () => {
  const isAgency = useRole(UserRole.AGENCY);
  if (isAgency) {
    return (
      <Navigate
        to="/401"
        replace
      />
    );
  }
  return (
    <ListBaseLayout>
      <HelpdeskFilters />
      <HelpdeskGrid />
      <Pagination />
    </ListBaseLayout>
  );
};
