import Typography from '@mui/material/Typography';
import { Box } from '@mui/system';
import React, { ReactNode } from 'react';

import { PhoneFieldProps } from '../text';

interface TopLabelTextFieldProps<T> {
  component?: React.FC<T>;
  children?: ReactNode;
  componentProps?: object;
  label: string | boolean;
  source?: string;
}

export const TopLabelField: React.FC<TopLabelTextFieldProps<PhoneFieldProps>> = ({
  label,
  source,
  component: Component,
  componentProps,
  children,
}) => {
  return (
    <Box>
      <Typography
        variant={'body2'}
        fontWeight={600}
        gutterBottom
      >
        {label}
      </Typography>
      {Component !== undefined ? (
        <Component
          source={source || ''}
          {...componentProps}
        />
      ) : (
        children
      )}
    </Box>
  );
};
