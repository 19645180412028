import { Box, Typography } from '@mui/material';
import { Identifier, RaRecord, useRecordContext } from 'react-admin';
import { formatNumberWithThousandSeparator } from 'src/utils/numberWithThousandSeparator';

interface FormattedNumberLabelProps {
  source: string;
  label: string;
  currency?: string;
}

export const FormattedNumberLabel: React.FC<FormattedNumberLabelProps> = ({
  source,
  label,
  currency,
}) => {
  const record = useRecordContext<RaRecord<Identifier>>();
  if (!record) {
    return null;
  }
  const value = record[source] as number | null | undefined;
  const formattedValue = formatNumberWithThousandSeparator(value);

  return (
    <Box
      sx={{
        width: '100%',
      }}
    >
      <Typography
        variant={'body2'}
        fontWeight={600}
        gutterBottom
      >
        {label}
      </Typography>
      <Typography variant="body2">
        {`${formattedValue}`} {currency}
      </Typography>
    </Box>
  );
};
