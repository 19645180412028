import { t } from 'i18next';
import { tokens } from 'src/locales/tokens';
import { isEmptyOrSpaces } from 'src/utils/isEmptyOrSpaces';

import { FirstStepType, ValidationError } from '../../../context';

export const validateMeetingName = (values: FirstStepType, errors: ValidationError) => {
  if (isEmptyOrSpaces(values.recipient)) {
    errors.recipient = t(tokens.utils.form.required);
  }
};
