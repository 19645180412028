export function getNestedProperty<T>(record: Record<string, any>, source: string): T | null {
  if (!record || !source) {
    return null;
  }

  const keys = source.split('.');
  let current: any = record;

  for (const key of keys) {
    if (current[key] === undefined) {
      return null;
    }
    current = current[key];
  }

  return current;
}
