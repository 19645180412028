import { Box, Menu, MenuItem, IconButton } from '@mui/material';
import React from 'react';
import { ActionItem } from 'src/types/sections/generic-types';

interface MenuProps {
  anchorEl: null | HTMLElement;
  open: boolean;
  handleClose: () => void;
  actions: ActionItem[];
}

export const GenericMenu: React.FC<MenuProps> = ({ anchorEl, open, handleClose, actions }) => {
  return (
    <Menu
      anchorEl={anchorEl}
      open={open}
      onClose={handleClose}
    >
      {actions.map((action, index) => (
        <MenuItem
          key={index}
          onClick={() => {
            action.onClick();
          }}
          disabled={action.disabled}
        >
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              width: '100%',
              justifyContent: 'space-between',
            }}
          >
            {action.label}
            {action.icon && <IconButton>{action.icon}</IconButton>}
          </Box>
        </MenuItem>
      ))}
    </Menu>
  );
};
