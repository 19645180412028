import { Stack } from '@mui/material';

interface HeaderLayoutProps {
  children: React.ReactNode[];
}

export const HeaderLayout: React.FC<HeaderLayoutProps> = ({
  children: [pageTitle, ExportButton],
}) => {
  return (
    <Stack
      direction="row"
      justifyContent="space-between"
      spacing={4}
    >
      <Stack spacing={1}>{pageTitle}</Stack>
      <Stack
        alignItems="center"
        direction="row"
        spacing={3}
      >
        {ExportButton}
      </Stack>
    </Stack>
  );
};
