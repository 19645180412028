import { useContext } from 'react';
import { ListExportModalContext } from 'src/contexts/modals/ListExportModalContext';

/**
 * Custom hook that provides access to the ListExportModalContext.
 * @returns The ListExportModalContext object.
 * @throws {Error} If used outside of a ListExportModalContextProvider.
 */
export const useListExportModalContext = () => {
  const context = useContext(ListExportModalContext);
  if (context === undefined) {
    throw new Error('useListExportModalContext must be used within a ListExportModalProvider');
  }
  return context;
};
