import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import Stack from '@mui/system/Stack';
import { t } from 'i18next';
import { tokens } from 'src/locales/tokens';

interface ExportActionBarProps {
  exportName: string;
  handleExport: () => Promise<void>;
  isLoading: boolean;
  hasRestrictedCharacters: boolean;
}

export const ExportActionBar: React.FC<ExportActionBarProps> = ({
  exportName,
  handleExport,
  isLoading,
  hasRestrictedCharacters,
}) => {
  return (
    <Stack
      direction="row"
      spacing={2}
      paddingTop={1}
      paddingBottom={1}
      width="100%"
      alignItems="center"
      justifyContent="end"
    >
      <Tooltip
        title={t(tokens.modale.export.noEmptyFileName)}
        disableHoverListener={exportName !== ''}
        placement="top-end"
        arrow
        componentsProps={{
          tooltip: {
            sx: {
              bgcolor: 'black',
              '& .MuiTooltip-arrow': {
                color: 'common.black',
              },
            },
          },
        }}
      >
        <div>
          <Button
            size="small"
            variant="contained"
            onClick={() => {
              handleExport().catch((error) =>
                console.error(t(tokens.asyncMessages.exportError), error)
              );
            }}
            disabled={!exportName || isLoading || hasRestrictedCharacters}
          >
            {t(tokens.modale.export.generate)}
          </Button>
        </div>
      </Tooltip>
    </Stack>
  );
};
