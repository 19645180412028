import { Button, Stack, SvgIcon } from '@mui/material';
import ChevronDownIcon from '@untitled-ui/icons-react/build/esm/ChevronDown';
import { usePopover } from 'src/hooks/usePopover';
import { useUserActions } from 'src/hooks/useUserActions';
import { useUserActionsHandlers } from 'src/hooks/useUserActionsHandlers';
import { EnhancedUser, User } from 'src/types/contexts/auth';

import { BreadCrumb } from '../../../../common/components/breadcrumb';
import { GenericMenu } from '../../../../common/components/GenericMenu';

import { UserAvatarWithName } from './UserAvatarWithName';
import { UserDeactivatedAlert } from './UserDeactivatedAlert';

export type ActionMenuItem = {
  label: string;
  onClick: () => void;
};

interface UserHeaderProps {
  record: User;
  recordCopy: EnhancedUser;
}

export const UserHeader: React.FC<UserHeaderProps> = ({ record, recordCopy }) => {
  const { anchorRef, handleClose, handleOpen, open } = usePopover();
  const { handleActivateDeactivate, handleResetPassword } = useUserActionsHandlers();
  const actionMenuItems = useUserActions({
    record,
    handleActivateDeactivate,
    handleResetPassword,
  });
  return (
    <Stack spacing={4}>
      <BreadCrumb
        linkText="Users"
        to="/admin_users"
      />
      <Stack
        alignItems="center"
        direction="row"
        justifyContent="space-between"
        spacing={4}
      >
        <UserAvatarWithName
          firstname={record.firstname}
          lastname={record.lastname}
        />
        <Stack
          alignItems="center"
          direction={{
            xs: 'column',
            md: 'row',
          }}
          spacing={2}
        >
          <Button
            endIcon={
              <SvgIcon>
                <ChevronDownIcon />
              </SvgIcon>
            }
            variant="text"
            onClick={handleOpen}
            ref={anchorRef}
          >
            Actions
          </Button>
          <GenericMenu
            anchorEl={anchorRef.current}
            open={open}
            handleClose={handleClose}
            actions={actionMenuItems}
          />
        </Stack>
      </Stack>
      <UserDeactivatedAlert
        active={record.active}
        date={recordCopy.date || new Date()}
      />
    </Stack>
  );
};
