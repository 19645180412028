import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import CircularProgress from '@mui/material/CircularProgress';
import { ArrowDropDownIcon } from '@mui/x-date-pickers';

interface SplitButtonComponentButtonGroupProps {
  anchorRef: React.RefObject<HTMLDivElement>;
  label: string;
  open: boolean;
  isLoading?: boolean;
  handleToggle: () => void;
}

export const SplitButtonComponentButtonGroup: React.FC<SplitButtonComponentButtonGroupProps> = ({
  anchorRef,
  label,
  open,
  handleToggle,
  isLoading,
}) => {
  return (
    <ButtonGroup
      variant="outlined"
      ref={anchorRef}
      disabled={isLoading}
      aria-label="Button group with a nested menu"
      sx={{
        '.MuiButton-outlined': {
          borderColor: 'neutral.200',
          color: 'inherit',
          '&:hover': {
            borderColor: 'neutral.200',
          },
        },
        '.MuiButtonGroup-grouped:not(:last-of-type)': {
          borderColor: 'neutral.200', // Changing the color of the separator
        },
      }}
    >
      <Button>{label}</Button>
      <Button
        size="small"
        aria-controls={open ? 'split-button-menu' : undefined}
        aria-expanded={open ? 'true' : undefined}
        aria-label="select merge strategy"
        aria-haspopup="menu"
        onClick={handleToggle}
        sx={{
          color: 'neutral.500', // Changing the color of the arrow
        }}
      >
        {isLoading ? <CircularProgress size={20} /> : <ArrowDropDownIcon />}
      </Button>
    </ButtonGroup>
  );
};
