import DownloadIcon from '@mui/icons-material/Download';
import ErrorIcon from '@mui/icons-material/Error';
import HourglassEmptyIcon from '@mui/icons-material/HourglassEmpty';
import CircularProgress from '@mui/material/CircularProgress';
import IconButton from '@mui/material/IconButton';
import { ReactElement } from 'react';
import { ExportStatus, ListExport } from 'src/types/contexts/modals';
import { ExportStatusEnum } from 'src/types/enums';

interface ExportStateConfig {
  statusIcon: ReactElement;
}

export const exportStateConfig: Record<
  ExportStatus,
  (item?: ListExport, getDownloadLink?: () => void) => ExportStateConfig
> = {
  [ExportStatusEnum.Waiting]: () => ({
    statusIcon: (
      <HourglassEmptyIcon
        color="warning"
        style={{ marginRight: 13 }}
      />
    ),
  }),
  [ExportStatusEnum.In_Progress]: () => ({
    statusIcon: (
      <CircularProgress
        size={20}
        style={{ marginRight: 13 }}
      />
    ),
    action: () => (
      <IconButton
        disabled={true}
        disableRipple
        style={{ marginRight: 13 }}
      >
        <DownloadIcon />
      </IconButton>
    ),
  }),
  [ExportStatusEnum.Success]: (item?: ListExport, getDownloadLink?: () => void) => ({
    statusIcon: (
      <IconButton
        disabled={item?.downloadUrl === ''}
        onClick={() => {
          if (getDownloadLink) getDownloadLink();
        }}
        style={{ marginRight: 5 }}
      >
        <DownloadIcon color="success" />
      </IconButton>
    ),
  }),
  [ExportStatusEnum.Failed]: () => ({
    statusIcon: (
      <ErrorIcon
        color="error"
        style={{ marginRight: 13 }}
      />
    ),
  }),
};
