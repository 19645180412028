import { Button } from '@mui/material';
import { t } from 'i18next';
import { tokens } from 'src/locales/tokens';

interface ResetFiltersButtonProps {
  filterValues: { [key: string]: string[] | Date };
  setFilters: (filters: { [key: string]: string[] | Date }, displayedFilters: unknown) => void;
  setStartDate?: (date: Date | undefined) => void;
  setEndDate?: (date: Date | undefined) => void;
}

export const ResetFiltersButton: React.FC<ResetFiltersButtonProps> = ({
  filterValues,
  setFilters,
  setStartDate,
  setEndDate,
}) => {
  const resetFilters = () => {
    setStartDate?.(undefined);
    setEndDate?.(undefined);
    setFilters({}, {});
  };

  return (
    <Button
      variant="text"
      color="primary"
      onClick={resetFilters}
      disabled={Object.keys(filterValues).length === 0}
      sx={{
        marginLeft: '0px !important',
      }}
    >
      {t(tokens.filters.resetFilters)}
    </Button>
  );
};

export default ResetFiltersButton;
