import { Box, Stack } from '@mui/material';
import { t } from 'i18next';
import React from 'react';
import { required } from 'react-admin';
import { contractContext } from 'src/domains/club/contract/context';
import { SectionLayout } from 'src/domains/club/contract/layouts/SectionLayout';
import { CustomInputLabel } from 'src/domains/club/members/detail/fields/CustomInputLabel';
import { CustomSelectLabel } from 'src/domains/club/members/detail/fields/CustomSelectLabel';
import { tokens } from 'src/locales/tokens';

import { presidentRemunerationOptions } from '../utils/presidentRemunerationOptions';

export const GeneralInfos: React.FC = () => {
  const { firstStep, setFirstStep } = React.useContext(contractContext);
  return (
    <SectionLayout title={t(tokens.domains.contract.stepOne.generalInfos.title)}>
      <Stack flexDirection={'row'}>
        <Stack
          flexGrow="1"
          padding={'0px 24px 30px 24px'}
          spacing={4}
        >
          <Stack
            direction={'row'}
            spacing={3}
            gap="55px"
          >
            <CustomInputLabel
              source="name"
              name="name"
              label={`${t(tokens.domains.contract.stepOne.generalInfos.clubName.label)} *`}
              placeholder={t(tokens.domains.contract.stepOne.generalInfos.clubName.placeholder)}
              startAdornment
              prefix="DYNABUY CLUB"
            />
            <CustomSelectLabel
              source="ownerRate"
              name="ownerRate"
              label={`${t(
                tokens.domains.contract.stepOne.generalInfos.presidentRemuneration.label
              )} *`}
              choices={presidentRemunerationOptions}
              firstStep={firstStep}
              setFirstStep={setFirstStep}
              validate={required()}
            />
          </Stack>
          <Stack
            direction={'row'}
            spacing={3}
            gap="16px"
          >
            <CustomInputLabel
              source="businessIdentifier"
              name="businessIdentifier"
              label={`${t(tokens.domains.contract.stepOne.generalInfos.siret.label)} *`}
              placeholder={t(tokens.domains.contract.stepOne.generalInfos.siret.placeholder)}
            />
            <Box
              display="flex"
              alignItems="center"
              paddingTop="24px"
              fontWeight="bold"
              marginLeft="0px !important"
            >
              OU
            </Box>
            <CustomInputLabel
              source="associationIdentifier"
              name="associationIdentifier"
              label={`${t(tokens.domains.contract.stepOne.generalInfos.rna.label)} *`}
              placeholder={t(tokens.domains.contract.stepOne.generalInfos.rna.placeholder)}
            />
          </Stack>
        </Stack>
      </Stack>
    </SectionLayout>
  );
};
