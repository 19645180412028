import Alert from '@mui/material/Alert';
import { t } from 'i18next';
import { tokens } from 'src/locales/tokens';

export const EmptyState: React.FC = () => (
  <Alert
    sx={{ padding: 0 }}
    severity="warning"
  >
    {t(tokens.modale.export.emptyState)}
  </Alert>
);
