// import { useTheme } from '@mui/material/styles';

export const LogoNavV2 = () => {
  // const {
  //   palette: { mode },
  // } = useTheme();

  // const fillColor = mode === 'light' ? '#000' : '#fff';
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 189 264"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.638672 115.92V0H100.824C128.099 0 149.474 8.61067 164.949 25.832C180.423 43.0533 188.16 65.476 188.158 93.1C188.158 122.127 178.148 145.25 158.13 162.469C138.111 179.688 114.29 188.3 86.6657 188.304C61.1323 188.304 40.412 182.359 24.5047 170.468C8.59734 158.577 0.642005 140.395 0.638672 115.92ZM44.1737 35.405V111.724C44.1737 123.615 48.0203 132.838 55.7137 139.391C63.407 145.944 73.7237 149.223 86.6637 149.227C102.746 149.227 115.64 143.72 125.346 132.705C135.052 121.69 139.903 108.315 139.901 92.578C139.901 76.494 135.311 62.943 126.132 51.925C116.952 40.907 104.495 35.4003 88.7607 35.405H44.1737Z"
        fill="white"
      />
      <path
        d="M188.805 205.037V236.437C171.122 245.349 155.035 252.138 140.543 256.803C125.623 261.535 110.052 263.897 94.4 263.803C78.6873 263.886 63.0546 261.562 48.046 256.91C33.4073 252.317 17.392 245.562 0 236.647V205.247C14.0658 212.672 28.9916 218.339 44.441 222.12C60.7694 226.273 77.5517 228.375 94.4 228.377C111.256 228.374 128.043 226.235 144.361 222.012C159.805 218.166 174.728 212.467 188.806 205.041"
        fill="#EE4540"
      />
    </svg>
  );
};
