import { useState } from 'react';

export const useDatePickerRangeHandler = () => {
  const [startDate, setStartDate] = useState<Date | undefined>(undefined);
  const [endDate, setEndDate] = useState<Date | undefined>(undefined);

  const handleStartDateChange = (date: Date | null) => {
    const newStartDate = date ?? undefined;
    setStartDate(newStartDate);
    if (newStartDate && endDate && newStartDate > endDate) {
      setEndDate(undefined);
    }
  };

  const handleEndDateChange = (date: Date | null) => {
    const newEndDate = date ?? undefined;
    setEndDate(newEndDate);
    if (newEndDate && startDate && newEndDate < startDate) {
      setStartDate(undefined);
    }
  };

  return {
    startDate,
    endDate,
    setStartDate,
    setEndDate,
    handleStartDateChange,
    handleEndDateChange,
  };
};
