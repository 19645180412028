import { t } from 'i18next';
import { tokens } from 'src/locales/tokens';

export const memberTypeOptionsTrad = {
  president: t(tokens.filters.type.president),
  member: t(tokens.filters.type.member),
} as const;
export const activeMemberOptionsTrad = {
  isActive: t(tokens.filters.active.isActive),
  isNotActive: t(tokens.filters.active.isNotActive),
} as const;

export const memberTypeOptionValues = {
  president: 'president',
  member: 'member',
} as const;

export const memberTypeOption = [
  {
    label: memberTypeOptionsTrad.president,
    value: memberTypeOptionValues.president,
  },
  {
    label: memberTypeOptionsTrad.member,
    value: memberTypeOptionValues.member,
  },
];

export const activeMemberOption = [
  {
    label: activeMemberOptionsTrad.isActive,
    value: true,
  },
  {
    label: activeMemberOptionsTrad.isNotActive,
    value: false,
  },
];
