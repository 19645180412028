import React from 'react';
import { FilterOption } from 'src/common/types/filterOptionTypes';

export interface AsSplitButtonFilterRenderOption {
  option: FilterOption;
}

interface SplitButtonFilterOptionProps {
  option: FilterOption;
  component: React.FC<AsSplitButtonFilterRenderOption>;
}

const SplitButtonFilterRenderOption: React.FC<SplitButtonFilterOptionProps> = ({
  option,
  component: Component,
}) => {
  return <Component option={option}></Component>;
};

export default SplitButtonFilterRenderOption;
