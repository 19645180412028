import { t } from 'i18next';
import { tokens } from 'src/locales/tokens';

export const legalOption = [
  {
    id: 'value0',
    name: t(tokens.domains.contract.stepTwo.companyInfos.legalForm.options.defaultOption),
  },
  {
    id: 'Entreprise individuelle',
    name: t(tokens.domains.contract.stepTwo.companyInfos.legalForm.options.individual),
  },
  {
    id: 'EIRL',
    name: t(tokens.domains.contract.stepTwo.companyInfos.legalForm.options.EIRL),
  },
  {
    id: 'EURL',
    name: t(tokens.domains.contract.stepTwo.companyInfos.legalForm.options.EURL),
  },
  {
    id: 'SARL',
    name: t(tokens.domains.contract.stepTwo.companyInfos.legalForm.options.SARL),
  },
  {
    id: 'SASU',
    name: t(tokens.domains.contract.stepTwo.companyInfos.legalForm.options.SASU),
  },
  {
    id: 'SAS',
    name: t(tokens.domains.contract.stepTwo.companyInfos.legalForm.options.SAS),
  },
  {
    id: 'SA',
    name: t(tokens.domains.contract.stepTwo.companyInfos.legalForm.options.SA),
  },
  {
    id: 'SCS',
    name: t(tokens.domains.contract.stepTwo.companyInfos.legalForm.options.SCS),
  },
  {
    id: 'SCA',
    name: t(tokens.domains.contract.stepTwo.companyInfos.legalForm.options.SCA),
  },
  {
    id: 'SNC',
    name: t(tokens.domains.contract.stepTwo.companyInfos.legalForm.options.SNC),
  },
  {
    id: 'Association',
    name: t(tokens.domains.contract.stepTwo.companyInfos.legalForm.options.association),
  },
];
