import { Dispatch, useReducer } from 'react';
import { AuthAction, InitialState } from 'src/types/contexts/auth';

import { authReducer, initialState } from '../../../../contexts/auth/jwt/authReducer';

/**
 * Custom hook that returns the state and dispatch function of an authentication reducer.
 * @returns {[InitialState, Dispatch<AuthAction>]} An array containing the current state and dispatch function.
 */
export const useAuthReducer = (): [InitialState, Dispatch<AuthAction>] => {
  const [state, dispatch] = useReducer(authReducer, initialState);
  return [state, dispatch];
};
