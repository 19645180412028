import AvailableFilterEnum from 'src/data/enum/local/availableFilterEnum';
import AdminResourceEnum from 'src/data/enum/remote/admin/adminResourceEnum';
import ClubResourceEnum from 'src/data/enum/remote/club/club-resource-enum';
import ApiResourceDefinition from 'src/data/model/local/common/resourceDefinitionInterface';

const resources: ApiResourceDefinition[] = [
  new ApiResourceDefinition(AdminResourceEnum.ADMIN_USER, [AvailableFilterEnum.ID_FILTER]),
  new ApiResourceDefinition(ClubResourceEnum.CLUB_USER, [AvailableFilterEnum.ID_FILTER]),
  new ApiResourceDefinition(ClubResourceEnum.HELPDESK_TOPIC, [AvailableFilterEnum.ID_FILTER]),
  new ApiResourceDefinition(AdminResourceEnum.AGENCY, [AvailableFilterEnum.ID_FILTER]),
  new ApiResourceDefinition(ClubResourceEnum.DEPARTMENT, [AvailableFilterEnum.ID_FILTER]),
  new ApiResourceDefinition(AdminResourceEnum.ORGANIZATION, [AvailableFilterEnum.ID_FILTER]),
  new ApiResourceDefinition(ClubResourceEnum.AGENCIES, [AvailableFilterEnum.ID_FILTER]),
  new ApiResourceDefinition(ClubResourceEnum.CLUBS, [AvailableFilterEnum.ID_FILTER]),
];
export default resources;
