import React from 'react';
import { TextField } from 'react-admin';
import AvatarField from 'src/components/react-admin/fields/common/AvatarField';
import { Box, Tooltip } from '@mui/material';

const AdminUserAvatar: React.FC = () => {
  return (
    <Tooltip
      arrow
      title={<TextField source={'fullName'} />}
    >
      <Box>
        <AvatarField
          source={'fullName'}
          center
        />
      </Box>
    </Tooltip>
  );
};

export default AdminUserAvatar;
