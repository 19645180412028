import React, { useState, ReactNode } from 'react';
import { FilterValues } from 'src/hooks/useReactAdminFilters';

import { ListExportModalContext } from './ListExportModalContext';

/**
 * Provides a modal context for exporting a filtered (or not filtered) list.
 *
 * @component
 * @param children - The child components.
 */
export const ListExportModalProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [getFilters, setGetFilters] = useState<() => FilterValues>(
    () => () => ({}) as FilterValues
  );
  const [isExportCreatedSuccessfully, setIsExportCreatedSuccessfully] = useState<
    boolean | undefined
  >(undefined);
  const [isExportDownloaded, setIsExportDownloaded] = useState<boolean>(false);
  const [isExportRetried, setIsExportRetried] = useState<boolean>(false);
  const [isExportCanceled, setIsExportCanceled] = useState<boolean>(false);

  const openModal = () => {
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
  };

  return (
    <ListExportModalContext.Provider
      value={{
        isOpen,
        openModal,
        closeModal,
        getFilters,
        setGetFilters,
        isExportCreatedSuccessfully,
        setIsExportCreatedSuccessfully,
        isExportDownloaded,
        setIsExportDownloaded,
        isExportRetried,
        setIsExportRetried,
        isExportCanceled,
        setIsExportCanceled,
      }}
    >
      {children}
    </ListExportModalContext.Provider>
  );
};
