import { Alert, Box, Fade } from '@mui/material';
import { t } from 'i18next';
import React from 'react';
import { CreateBase } from 'react-admin';
import { Navigate } from 'react-router-dom';
import { DetailLayout } from 'src/common/layouts/react-admin';
import { Header } from 'src/domains/club/contract/components/Header';
import useRole from 'src/domains/system/auth/hooks/useRole';
import { tokens } from 'src/locales/tokens';
import { UserRole } from 'src/types/enums/user-roles-enum';

import { PresidentAlert } from './components/PresidentAlert';
import { ContractProvider } from './context/ContractProvider';
import { ContractStepLayout } from './layouts/ContratStepLayout';
import { ContratStepOne } from './steps/stepOne/ContractStepOne';
import { ContratStepThree } from './steps/stepThree/ContractStepThree';
import { ContratStepTwo } from './steps/stepTwo/ContractStepTwo';

export const NewContract: React.FC = () => {
  const [step, setStep] = React.useState(1);
  const isAgency = useRole(UserRole.AGENCY);
  if (!isAgency) {
    return (
      <Navigate
        to="/401"
        replace
      />
    );
  }

  return (
    <ContractProvider>
      <DetailLayout seoTitle={t(tokens.seo.club.title)}>
        <Header />
        <CreateBase resource={'contracts'}>
          <ContractStepLayout step={step}>
            {step === 1 && (
              <Alert
                severity="warning"
                sx={{ fontWeight: 'medium', margin: '24px 24px 8px' }}
              >
                <PresidentAlert />
              </Alert>
            )}
            <Fade
              in={step === 1}
              unmountOnExit
              mountOnEnter
            >
              <Box>
                <ContratStepOne {...{ step, setStep }} />
              </Box>
            </Fade>
            <Fade
              in={step === 2}
              unmountOnExit
              mountOnEnter
            >
              <Box>
                <ContratStepTwo {...{ step, setStep }} />
              </Box>
            </Fade>
            <Fade
              in={step === 3}
              unmountOnExit
              mountOnEnter
            >
              <Box>
                <ContratStepThree />
              </Box>
            </Fade>
          </ContractStepLayout>
        </CreateBase>
      </DetailLayout>
    </ContractProvider>
  );
};
