import React from 'react';
import { RaRecord, useRecordContext, useShowContext } from 'react-admin';
import { MailtoField } from 'src/components/react-admin/core/fields';

export const TicketUserEmailField = () => {
  const record = useRecordContext();
  const { record: ticket } = useShowContext<RaRecord>();

  if (!ticket) {
    return null;
  }

  return (
    <MailtoField
      receivers={[`${record.fullName}<${record.email}>`]}
      subject={`Votre demande ${ticket.ticketNumber} - ${ticket}`}
      label={record.email as string}
    />
  );
};
