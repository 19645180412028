import React, { ForwardedRef, forwardRef } from 'react';
import { Link, LinkProps } from 'react-router-dom';

interface RouterLinkProps extends Omit<LinkProps, 'to'> {
  href: string;
}

/**
 * This is an adapted for `react-router-dom/link` component.
 * We use this to help us maintain consistency between CRA and Next.js
 */
export const RouterLink: React.FC<RouterLinkProps> = forwardRef(function RouterLink(props, ref) {
  const { href, ...other } = props;

  return (
    <Link
      ref={ref as ForwardedRef<HTMLAnchorElement>}
      to={href}
      {...other}
    />
  );
});
