import InfoIcon from '@mui/icons-material/Info';
import { Box, InputAdornment, Tooltip, Typography, useTheme } from '@mui/material';
import Chip from '@mui/material/Chip';
import React from 'react';
import { TextInput, Validator } from 'react-admin';

interface CustomInputLabelProps {
  source: string;
  name?: string;
  label: string;
  validate?: Validator | Validator[];
  disabled?: boolean;
  placeholder?: string;
  isTime?: boolean;
  tooltipTitle?: string;
  errorMessage?: string;
  endAdornment?: React.ReactNode;
  startAdornment?: React.ReactNode;
  prefix?: string;
  inputProps?: object;
}

export const CustomInputLabel: React.FC<CustomInputLabelProps> = ({
  source,
  name,
  label,
  validate,
  disabled,
  placeholder,
  isTime,
  tooltipTitle,
  endAdornment,
  startAdornment,
  prefix,
  inputProps = {},
}) => {
  const theme = useTheme();

  return (
    <Box
      sx={{
        width: '100%',
        marginLeft: '0px !important',
      }}
    >
      <Box
        sx={{
          display: 'flex',
        }}
      >
        <Typography
          variant={'body2'}
          fontWeight={600}
          gutterBottom
        >
          {label}
        </Typography>
        {tooltipTitle && (
          <Tooltip
            title={tooltipTitle}
            placement="top-end"
            arrow
            componentsProps={{
              tooltip: {
                sx: {
                  padding: '16px',
                  bgcolor: 'neutral.200',
                  '& .MuiTooltip-arrow': {
                    color: 'neutral.200',
                  },
                  color: 'neutral.500',
                },
              },
            }}
          >
            <InfoIcon
              sx={{
                color: 'neutral.500',
                fontSize: '18px',
                marginLeft: '16px',
              }}
            />
          </Tooltip>
        )}
      </Box>

      <TextInput
        source={source}
        name={name}
        validate={validate}
        disabled={disabled}
        label={false}
        placeholder={placeholder}
        helperText={false}
        type={isTime ? 'time' : 'text'}
        inputProps={{
          // type: isTime && 'time',
          step: isTime && 60,
          sx: {
            padding: startAdornment ? 0 : '0 16px',
            fontWeight: 'regular',
            // padding: '0 !important',
            minHeight: '56px !important',
          },
          ...inputProps,
        }}
        InputProps={{
          endAdornment: endAdornment && (
            <InputAdornment position="end">
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  '& svg': {
                    fontSize: '15px',
                  },
                }}
              >
                {endAdornment}
              </Box>
            </InputAdornment>
          ),
          startAdornment: startAdornment && (
            <Box
              ml={1}
              mr={1}
            >
              <Chip
                label={prefix}
                size={'small'}
                variant={'outlined'}
              />
            </Box>
          ),
        }}
        sx={{
          width: '100%',
          minHeight: '56px !important',
          color: disabled ? theme.palette.text.primary : undefined,
          '.MuiInputBase-input.Mui-disabled': {
            WebkitTextFillColor: theme.palette.text.primary,
          },
          // '.MuiInputBase-root.Mui-disabled': {
          //   color: theme.palette.text.primary,
          //   border: `1px solid ${theme.palette.action.disabled}`,
          // },
          '& input[type="time"]::-webkit-calendar-picker-indicator': {
            fontSize: '20px',
            '&:hover': {
              cursor: 'pointer',
            },
          },
          '.MuiInputBase-adornedStart': {
            paddingLeft: 0,
          },
        }}
      />
    </Box>
  );
};
