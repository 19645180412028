import { Box, Stack } from '@mui/material';
import { TaggedFilterContextProvider } from 'src/contexts/common/filters/tagged-filter-context';

interface FilterLayoutprops {
  children: React.ReactNode[];
}
export const FilterLayout: React.FC<FilterLayoutprops> = ({
  children: [filtersRow1, totalItems, filtersRow2, tags],
}) => {
  return (
    <TaggedFilterContextProvider>
      <Stack
        direction="column"
        spacing={2}
        padding={'16px 0px'}
      >
        <Stack
          justifyContent={'space-between'}
          direction="row"
          flexWrap="wrap"
        >
          <Stack
            direction="row"
            spacing={2}
          >
            {filtersRow1}
          </Stack>
          <Box>{totalItems}</Box>
        </Stack>

        <Stack
          direction="row"
          spacing={2}
          flexWrap="wrap"
        >
          {filtersRow2}
        </Stack>
      </Stack>
      {tags}
    </TaggedFilterContextProvider>
  );
};
