import { Stack } from '@mui/material';
import { format } from 'date-fns';
import { SelectorChip } from 'src/common/components/SelectorChip';
import { useTaggedFilterContext } from 'src/contexts/common/filters/tagged-filter-context';
import {
  TaggedFilterConfiguration,
  TaggedFilterOption,
} from 'src/contexts/common/filters/tagged-filter-context/types';
import { IdentifierEnum } from 'src/types/enums/identifier-enum';
import { isStringADate } from 'src/utils/isStringADate';

interface TagsProps {
  setStartDate?: (date: Date | undefined) => void;
  setEndDate?: (date: Date | undefined) => void;
  setFilters: (
    filters: { [key: string]: string[] | Date },
    displayedFilters: unknown,
    debounce?: boolean
  ) => void;
  filterValues: { [key: string]: string[] | Date };
}

const generateLabel = (
  filterConfig: TaggedFilterConfiguration,
  value: string | Date,
  options?: TaggedFilterOption[]
): string => {
  const getLabelFromOptions = () => {
    const option = options?.find((opt) => opt.value === value);
    return option ? option.label : null;
  };

  const getFormattedValue = () => {
    return isStringADate(value) ? format(new Date(value as Date), 'dd/MM/yyyy') : value;
  };

  const optionLabel = getLabelFromOptions();
  const displayValue = optionLabel ?? getFormattedValue();
  const prefix = filterConfig.showLabel ? filterConfig.label + ' : ' : '';

  return prefix + displayValue;
};

export const TagSummary: React.FC<TagsProps> = ({
  setStartDate,
  setEndDate,
  filterValues,
  setFilters,
}) => {
  const { taggedFilters } = useTaggedFilterContext();
  const handleDeleteDateFilter = (identifier: string) => {
    // Update startDate and minDate to avoid date overlap in the date picker
    if (identifier === IdentifierEnum.createdAtAfter && setStartDate) {
      setStartDate(undefined);
    } else if (identifier === IdentifierEnum.createdAtBefore && setEndDate) {
      setEndDate(undefined);
    }

    // Prepare the updated filters by removing the key corresponding to the current filter
    const updatedFilters = { ...filterValues };
    delete updatedFilters[identifier];

    setFilters(updatedFilters, {});
  };

  const handleDeleteSelectorFilter = (
    identifier: string,
    value: string | number | boolean | Date
  ) => {
    // Build the new filters state without the deleted value
    const updatedFilters = { ...filterValues } as {
      [x: string]: string[];
    };
    // If the filter is an array, remove the value from the array
    if (Array.isArray(updatedFilters[identifier])) {
      updatedFilters[identifier] = updatedFilters[identifier].filter((val) => val !== value);
      if (updatedFilters[identifier].length === 0) {
        // Delete the filter if there are no more values
        delete updatedFilters[identifier];
      }
    } else {
      delete updatedFilters[identifier];
    }

    setFilters(updatedFilters, {}); // Automatic fetch with new filters
  };
  return (
    <Stack
      spacing={1}
      alignItems={'center'}
      direction={'row'}
      padding={'0px 0px 34px'}
      gap={1}
      flexWrap={'wrap'}
    >
      {Object.entries(filterValues)
        .filter(([identifier]) => taggedFilters[identifier] !== undefined)
        .map(([identifier, value], index) => {
          const filterConfig = taggedFilters[identifier];
          if (Array.isArray(value)) {
            return value.map((val, i) => {
              const label = generateLabel(filterConfig, val, filterConfig.options);
              return (
                <SelectorChip
                  key={`${index}-${i}-${val}`}
                  {...{
                    identifier,
                    label,
                    val,
                    handleDeleteSelectorFilter: isStringADate(value)
                      ? handleDeleteDateFilter
                      : handleDeleteSelectorFilter,
                  }}
                />
              );
            });
          } else {
            const label = generateLabel(filterConfig, value, filterConfig.options);
            return (
              <SelectorChip
                val={value}
                key={`${index}-${value}`}
                {...{
                  label,
                  identifier,
                  value,
                  handleDeleteSelectorFilter: isStringADate(value)
                    ? handleDeleteDateFilter
                    : handleDeleteSelectorFilter,
                }}
              />
            );
          }
          // }
        })}
    </Stack>
  );
};
