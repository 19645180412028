import React from 'react';
import { useAsyncAlert } from 'src/common/hooks/useAsyncAlert';
import { useAsyncMessage } from 'src/common/hooks/useAsyncMessage';
import { useAsyncStatus } from 'src/common/hooks/useAsyncStatus';
import { AsyncStatusAction } from 'src/types/enums';
import { AsyncFeedback } from 'src/types/hocs';

const withAsyncFeedback = <P extends object>(
  WrappedComponent: React.ComponentType<P & { asyncFeedback: AsyncFeedback }>
) => {
  const WithAsyncFeedbackComponent = (props: P) => {
    const { isLoading, isSuccess, isError, dispatch } = useAsyncStatus();
    const {
      loadingMessage,
      successMessage,
      errorMessage,
      noUpdateMessage,
      clearMessages,
      defineLoading,
      defineSuccess,
      defineError,
      defineNoUpdate,
    } = useAsyncMessage();
    const AlertComponent = useAsyncAlert({
      isLoading,
      isSuccess,
      isError,
      loadingMessage,
      successMessage,
      errorMessage,
      clearMessages,
      resetStatus: () => dispatch({ type: AsyncStatusAction.INIT }),
    });

    return (
      <WrappedComponent
        {...props}
        asyncFeedback={{
          isLoading,
          isSuccess,
          isError,
          dispatch,
          loadingMessage,
          successMessage,
          errorMessage,
          noUpdateMessage,
          clearMessages,
          defineLoading,
          defineSuccess,
          defineError,
          defineNoUpdate,
          AlertComponent,
        }}
      />
    );
  };

  WithAsyncFeedbackComponent.displayName = `WithAsyncFeedback(${getDisplayName(WrappedComponent)})`;

  return WithAsyncFeedbackComponent;
};

function getDisplayName<P>(WrappedComponent: React.ComponentType<P>): string {
  return WrappedComponent.displayName || WrappedComponent.name || 'Component';
}

export default withAsyncFeedback;
