import { ButtonPropsColorOverrides } from '@mui/material';
import { OverridableStringUnion } from '@mui/types';
import React from 'react';
import { Button } from 'react-admin';

interface ContractButtonProps {
  color?: OverridableStringUnion<
    'inherit' | 'primary' | 'secondary' | 'error' | 'info' | 'success' | 'warning',
    ButtonPropsColorOverrides
  >;
  endIcon?: React.ReactNode;
  label: string;
  handleClick?: () => void;
  variant?: 'text' | 'outlined' | 'contained';
  underline?: boolean;
  sx?: object;
  iconStyle?: object;
  type?: 'button' | 'submit' | 'reset';
}

export const ContractButton: React.FC<ContractButtonProps> = ({
  color,
  endIcon,
  label,
  handleClick,
  variant = 'outlined',
  underline = false,
  sx = {},
  iconStyle = {},
  type = 'submit',
  ...props
}) => {
  const styledIcon = endIcon
    ? React.cloneElement(endIcon as React.ReactElement, { sx: iconStyle })
    : null;

  const additionalStyles = underline && variant === 'text' ? { textDecoration: 'underline' } : {};

  return (
    <Button
      variant={variant}
      color={color}
      onClick={handleClick}
      label={label}
      endIcon={styledIcon}
      sx={{ ...sx, ...additionalStyles }}
      type={type}
      {...props}
    />
  );
};
