import React, { useState } from 'react';
import { TaggedFilterContext } from 'src/contexts/common/filters/tagged-filter-context/context';
import {
  TaggedFilterComponentTree,
  TaggedFilterConfiguration,
  TaggedFilterOption,
  TaggedFilterProviderProps,
} from 'src/contexts/common/filters/tagged-filter-context/types';
import { compareArraysOfObjects } from 'src/utils/arrayCompare';

export const TaggedFilterContextProvider: React.FC<TaggedFilterProviderProps> = ({ children }) => {
  const [taggedFilters, setTaggedFilters] = useState<TaggedFilterComponentTree>({});

  const registerFilter = (valueKey: string, filterConfiguration: TaggedFilterConfiguration) => {
    if (taggedFilters[valueKey] === undefined) {
      setTaggedFilters((prevTaggedFilters) => ({
        ...prevTaggedFilters, // Spread the previous state
        [valueKey]: filterConfiguration, // Add the new key-value pair
      }));
    }
  };

  const setOptionsToFilter = (valueKey: string, options: TaggedFilterOption[]) => {
    if (
      taggedFilters[valueKey] !== undefined &&
      !compareArraysOfObjects(taggedFilters[valueKey].options ?? [], options)
    ) {
      setTaggedFilters((prevTaggedFilters) => ({
        ...prevTaggedFilters, // Spread the previous state
        [valueKey]: { ...prevTaggedFilters[valueKey], options: options },
      }));
    }
  };
  return (
    <TaggedFilterContext.Provider value={{ registerFilter, taggedFilters, setOptionsToFilter }}>
      {children}
    </TaggedFilterContext.Provider>
  );
};
