export enum ClubSortByEnum {
  ORDER_BY_FORMAT = 'orderFormat',
  ORDER_BY_COUNT_ACTIVE_MEMBERS = 'countActiveMembers',
  ORDER_BY_PRESIDENT_NAME = 'presidentName',
  ORDER_BY_DEPARTMENT_CODE = 'department.code',
  ORDER_BY_STATUS = 'status',
}

export enum MemberSortByEnum {
  MEMBER_TYPE = 'memberTypeOrder',
  MEMBER_NAME = 'user.profile.lastName',
  ORGANIZATION_NAME = 'organizationName',
  ORGANISATION_DEPARTMENT = 'organizationDepartment',
  CLUB_NAME = 'club.name',
  AGENCY_NAME = 'agency.name',
  PAYMENT_PERIOD = 'paymentPeriod',
  CREATED_AT = 'createdAt',
}
