import Box from '@mui/material/Box';
import React from 'react';

interface ButtonContentProps {
  startIcon: JSX.Element | undefined;
  active: boolean;
  title: string;
  label?: string;
  depth: number;
  disabled?: boolean;
}

const ButtonContent: React.FC<ButtonContentProps> = ({
  startIcon,
  active,
  title,
  label,
  depth,
  disabled,
}: ButtonContentProps): React.ReactNode => (
  <>
    {startIcon && (
      <Box
        component="span"
        sx={{
          alignItems: 'center',
          color: active ? 'var(--nav-item-icon-active-color)' : 'var(--nav-item-icon-color)',
          display: 'inline-flex',
          justifyContent: 'center',
          mr: 2,
        }}
      >
        {startIcon}
      </Box>
    )}
    <Box
      component="span"
      sx={{
        color: active ? 'var(--nav-item-active-color)' : 'var(--nav-item-color)',
        flexGrow: 1,
        fontFamily: 'inherit',
        fontSize: depth > 0 ? 13 : 14,
        fontWeight: depth > 0 ? 500 : 600,
        lineHeight: '24px',
        whiteSpace: 'nowrap',
        ...(disabled && {
          color: 'var(--nav-item-disabled-color)',
        }),
      }}
    >
      {title}
    </Box>
    {label && (
      <Box
        component="span"
        sx={{ ml: 2 }}
      >
        {label}
      </Box>
    )}
  </>
);

export default ButtonContent;
