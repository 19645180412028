import { useCallback, useState } from 'react';

import { AddressFeature, AddressResponse } from '../types/postalAddress.types';

const API_URL = 'https://api-adresse.data.gouv.fr/search/';

const useFetchAddressData = () => {
  const [choices, setChoices] = useState<AddressFeature[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  const fetchAddressData = useCallback(async (address: string) => {
    if (address.length > 3) {
      setIsLoading(true);
      try {
        const response = await fetch(`${API_URL}?q=${encodeURIComponent(address)}`);
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const data: AddressResponse = (await response.json()) as AddressResponse;
        setChoices(data.features);
      } catch (error) {
        console.error('Error fetching address data:', error);
        setChoices([]);
      } finally {
        setIsLoading(false);
      }
    }
  }, []);

  return {
    choices,
    isLoading,
    setChoices,
    fetchAddressData,
  };
};

export default useFetchAddressData;
