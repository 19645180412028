import { Box, Container, Stack } from '@mui/material';
import { Seo } from 'src/components/Seo';
import { ListExportModalProvider } from 'src/contexts/modals/ListExportModalProvider';

interface ListLayoutProps {
  children: React.ReactNode;
  seoTitle: string;
}

export const ListLayout: React.FC<ListLayoutProps> = ({ children, seoTitle }) => {
  return (
    <ListExportModalProvider>
      <Seo title={seoTitle} />
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          py: 8,
        }}
      >
        <Container maxWidth="xl">
          <Stack spacing={4}>{children}</Stack>
        </Container>
      </Box>
    </ListExportModalProvider>
  );
};
