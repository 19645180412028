import CloseIcon from '@mui/icons-material/Close';
import ReplayIcon from '@mui/icons-material/Replay';
import { SvgIconProps } from '@mui/material';
import { t } from 'i18next';
import React, { useCallback } from 'react';
import dataProvider from 'src/data/provider/remote/dataProvider';
import { tokens } from 'src/locales/tokens';
import { ListExport } from 'src/types/contexts/modals';
import { AsyncStatusAction } from 'src/types/enums';
import { ExportAction } from 'src/types/enums/export-actions-enum';

import { useAsyncFeedbackContext } from '../../hooks/useAsyncFeedbackContext';

import { useListExportModalContext } from './useListExportModalContext';

export const useExportActions = (item: ListExport, handleClose: () => void) => {
  const { clearMessages, dispatch, defineLoading, defineSuccess, defineError } =
    useAsyncFeedbackContext();
  const { setIsExportCanceled, setIsExportRetried } = useListExportModalContext();

  const executeExportAction = useCallback(
    async (url: string, actionType: ExportAction) => {
      clearMessages();
      dispatch({
        type: AsyncStatusAction.INIT,
      });
      if (actionType === ExportAction.Retry) {
        console.log('Retrying export...');
        setIsExportRetried(false);
      } else if (actionType === ExportAction.Cancel) {
        console.log('Canceling export...');
        setIsExportCanceled(false);
      }
      dispatch({ type: AsyncStatusAction.IS_LOADING, payload: true });
      if (actionType === ExportAction.Retry) {
        defineLoading('Tentative de reprise du téléchargement en cours...');
      }
      if (actionType === ExportAction.Cancel) {
        defineLoading('Annulation du téléchargement en cours...');
      }
      try {
        let response;
        if (actionType === ExportAction.Retry) {
          response = await dataProvider.retryClubExport(url);
          setIsExportRetried(true);
          defineSuccess('La reprise du téléchargement a réussi.');
        } else if (actionType === ExportAction.Cancel) {
          response = await dataProvider.cancelClubExport(url);
          setIsExportCanceled(true);
          defineSuccess('L’annulation du téléchargement a réussi.');
          console.log('Cancel successful:', response);
        }
        dispatch({ type: AsyncStatusAction.IS_SUCCESS, payload: true });
      } catch (error) {
        defineError(error as string);
        dispatch({ type: AsyncStatusAction.IS_ERROR, payload: true });
      } finally {
        dispatch({ type: AsyncStatusAction.IS_LOADING, payload: false });
        handleClose();
      }
    },
    [
      clearMessages,
      defineError,
      defineLoading,
      defineSuccess,
      dispatch,
      handleClose,
      setIsExportCanceled,
      setIsExportRetried,
    ]
  );

  const createAction = (
    labelToken: string,
    actionType: ExportAction,
    url: string,
    IconComponent: React.ElementType<SvgIconProps>
  ) => ({
    label: t(labelToken),
    action: actionType,
    disabled: url === '',
    onClick: () => executeExportAction(url, actionType),
    icon: <IconComponent />,
  });

  const exportActions = [
    createAction(tokens.modale.export.retry, ExportAction.Retry, item.retryUrl, ReplayIcon),
    createAction(tokens.modale.export.cancel, ExportAction.Cancel, item.cancelUrl, CloseIcon),
  ];

  return exportActions;
};
