export enum OrganizationSourceEnum {
  fullAddress = 'fullAddress',
  legalForm = 'legalForm',
  associationIdentifier = 'associationIdentifier',
  name = 'name',
  brand = 'brand',
  activity = 'activity',
  website = 'website',
  capital = 'capital',
  size = 'size',
  corporateName = 'corporateName',
  businessIdentifier = 'businessIdentifier',
}

export enum MemberSourceEnum {
  firstName = 'firstName',
  lastName = 'lastName',
  email = 'email',
  phone = 'phone',
  jobTitle = 'jobTitle',
}

export enum ClubSourceEnum {
  name = 'name',
  countActiveMembers = 'countActiveMembers',
  format = 'format',
  status = 'status',
}
