import { Grid } from '@mui/material';
import Stack from '@mui/material/Stack';
import { t } from 'i18next';
import React from 'react';
import { ReferenceField, TextField } from 'react-admin';
import { ContentCard } from 'src/common/layouts/material-ui';
import AdminResourceEnum from 'src/data/enum/remote/admin/adminResourceEnum';
import {
  HelpdeskLoggedUserCard,
  HelpdeskTicketInformationCard,
} from 'src/domains/club/helpdesk/components/show/content';
import { OrganizationDetailContent } from 'src/domains/club/members/detail/siege';
import { tokens } from 'src/locales/tokens';

export const HelpdeskTicketShowLayout: React.FC = () => {
  return (
    <Grid
      container
      spacing={4}
      justifyContent={'stretch'}
    >
      <Grid
        item
        lg={4}
        xl={3}
      >
        <HelpdeskTicketInformationCard />
      </Grid>
      <Grid
        item
        lg={8}
        xl={9}
      >
        <Stack
          spacing={4}
          sx={{ flexGrow: 1, height: '100%' }}
        >
          <ContentCard title={'Demandeur'}>
            <HelpdeskLoggedUserCard />
          </ContentCard>
          <ContentCard title={t(tokens.domains.helpdesk.detail.message.title)}>
            <TextField
              source={'message'}
              variant={'body2'}
            />
          </ContentCard>
        </Stack>
      </Grid>
      <Grid
        item
        lg={12}
      >
        <ReferenceField
          reference={AdminResourceEnum.ORGANIZATION}
          source={'organization'}
        >
          <OrganizationDetailContent readonly={true} />
        </ReferenceField>
      </Grid>
    </Grid>
  );
};
