import { Dispatch } from 'react';
import { AccessRightsReducerAction, AccessRightsReducerState } from 'src/types/reducers';

export const useAccessRightsHandlers = (
  accessRightState: AccessRightsReducerState,
  dispatch: Dispatch<AccessRightsReducerAction>,
  selectedGroupLabel: string | null
) => {
  const handleSwitchChange = (accessRightId: string, checked: boolean) => {
    const prevAccessRights = accessRightState.accessRights;

    if (!prevAccessRights) return;

    const updatedAccessRights = prevAccessRights.map((group) => ({
      ...group,
      accessRights: group.accessRights.map((right) =>
        right['@id'] === accessRightId ? { ...right, allowed: checked } : right
      ),
    }));

    dispatch({ type: 'setAccessRights', payload: updatedAccessRights });
  };

  const handleGlobalSwitchChange = (checked: boolean) => {
    const prevAccessRights = accessRightState.accessRights;

    if (!prevAccessRights || !selectedGroupLabel) return;
    const updatedAccessRights = prevAccessRights.map((group) => {
      if (group.label === selectedGroupLabel) {
        return {
          ...group,
          accessRights: group.accessRights.map((right) => ({
            ...right,
            allowed: checked,
          })),
        };
      }
      return group;
    });

    dispatch({ type: 'setAccessRights', payload: updatedAccessRights });
  };

  const handleAccessRightsCancel = () => {
    const initialAccessRights = accessRightState.initialAccessRights;
    dispatch({ type: 'setAccessRights', payload: initialAccessRights });
  };

  return {
    handleSwitchChange,
    handleGlobalSwitchChange,
    handleAccessRightsCancel,
  };
};
