import { t } from 'i18next';
import { tokens } from 'src/locales/tokens';
import { isEmptyOrSpaces } from 'src/utils/isEmptyOrSpaces';

import { FirstStepType, ValidationError } from '../../../context';

export const validatePresidentRemuneration = (values: FirstStepType, errors: ValidationError) => {
  if (isEmptyOrSpaces(values.ownerRate) || values.ownerRate === 'value0') {
    errors.ownerRate = t(tokens.utils.form.required);
  }
};
