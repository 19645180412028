import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import SvgIcon from '@mui/material/SvgIcon';
import Download01Icon from '@untitled-ui/icons-react/build/esm/Download01';
import { useListExportModalContext } from 'src/common/modals/hooks/useListExportModalContext';

export const ImportExportButtons = () => {
  const { openModal, getFilters } = useListExportModalContext();

  const handleExportClick = () => {
    openModal();
    getFilters();
  };
  return (
    <Stack
      alignItems="center"
      direction="row"
      spacing={1}
    >
      <Button
        color="inherit"
        size="small"
        startIcon={
          <SvgIcon>
            <Download01Icon />
          </SvgIcon>
        }
        onClick={handleExportClick}
      >
        Export
      </Button>
    </Stack>
  );
};
