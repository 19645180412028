import Divider from '@mui/material/Divider';
import { styled } from '@mui/material/styles';

export const style = {
  backgroundColor: 'neutral.100',
  position: 'absolute' as const,
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '100%',
  '@media (min-width:600px)': {
    width: 500,
  },
  maxHeight: '800px',
  boxShadow: 24,
  p: 4,
  borderRadius: '16px',
  color: 'neutral.800',
};

export const DividerStyled = styled(Divider)(({ theme }) => ({
  borderColor:
    theme.palette.mode === 'dark' ? theme.palette.neutral[800] : theme.palette.neutral[200],
  height: '2px',
}));
