import { t } from 'i18next';
import { tokens } from 'src/locales/tokens';

export const civilityOption = [
  {
    id: 'value0',
    name: t(tokens.domains.contract.stepTwo.signatory.civility.options.miss),
  },
  {
    id: 'value1',
    name: t(tokens.domains.contract.stepTwo.signatory.civility.options.mister),
  },
];
