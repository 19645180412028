import Typography from '@mui/material/Typography';
import Stack from '@mui/system/Stack';
import { Atom01 } from '@untitled-ui/icons-react';
import React from 'react';

interface UserAvatarWithNameProps {
  firstname: string;
  lastname: string;
}

export const UserAvatarWithName: React.FC<UserAvatarWithNameProps> = ({ firstname, lastname }) => (
  <Stack
    flexDirection="row"
    alignItems={{
      xs: 'center',
      md: 'baseline',
    }}
  >
    <Atom01 fontSize="large" />
    <Typography
      variant="h4"
      marginLeft="10px"
    >
      {firstname} {lastname}
    </Typography>
  </Stack>
);
