import { Card } from '@mui/material';
import React from 'react';
import { CardTitle } from 'src/domains/club/members/detail/common/CardTitle';

interface SectionLayoutProps {
  title: string;
  children: React.ReactNode;
}

export const SectionLayout: React.FC<SectionLayoutProps> = ({ title, children }) => {
  return (
    <Card
      elevation={0}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        flexBasis: '50%',
        marginTop: '16px',
        width: '100%',
        border: '1px solid #f7f7f7',
      }}
    >
      <CardTitle text={title} />
      {children}
    </Card>
  );
};
