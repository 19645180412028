import { Skeleton, Typography } from '@mui/material';
import { t } from 'i18next';
import React from 'react';
import { RaRecord, SelectInput, useGetList, Validator } from 'react-admin';
import { tokens } from 'src/locales/tokens';

interface AsyncSelectInputProps {
  source: string;
  name?: string;
  validate?: Validator | Validator[];
  dataSource: string;
  defaultValue?: string;
}

export const AsyncSelectInput: React.FC<AsyncSelectInputProps> = ({
  source,
  name,
  validate,
  dataSource,
  defaultValue = 'value0',
}) => {
  const { data, isLoading, error } = useGetList<RaRecord>(dataSource, {});

  if (isLoading) {
    return <Skeleton height={56} />;
  }

  if (error) {
    return <Typography color="error">{t(tokens.utils.form.errorOccured)}</Typography>;
  }

  const choices = [
    {
      id: defaultValue,
      name: t(tokens.domains.contract.stepTwo.companyInfos.legalForm.options.defaultOption),
      disabled: true,
    },
    ...(data || []),
  ];

  return (
    <SelectInput
      source={source}
      name={name}
      choices={choices}
      label={false}
      helperText={false}
      defaultValue={defaultValue}
      fullWidth
      sx={{
        marginTop: '0px',
        minHeight: '56px',
      }}
      inputProps={{
        sx: {
          minHeight: '56px !important',
          paddingTop: '0',
          paddingBottom: '0',
          display: 'flex',
          alignItems: 'center',
          color: 'inherit',
          fontWeight: 'normal',
        },
      }}
      validate={validate}
    />
  );
};
