import Alert from '@mui/material/Alert';

export const ErrorState: React.FC<{ message: string }> = ({ message }) => (
  <Alert
    sx={{ padding: 0 }}
    severity="error"
  >
    {message}
  </Alert>
);
