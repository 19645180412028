import { useEffect } from 'react';
import { NavigateFunction, useNavigate } from 'react-router-dom';

const MAINTENANCE_PAGE = '/maintenance';
const INTERVAL_MS = 30000;
const INTERVAL_MS_MAINTENANCE = 60000;

const isOnMaintenancePage = () => location.pathname === MAINTENANCE_PAGE;

const handleHealthcheckResponse = (response: Response, navigate: NavigateFunction) => {
  if (response.status === 503 && !isOnMaintenancePage()) {
    navigate(MAINTENANCE_PAGE);
  } else if (response.status === 200 && isOnMaintenancePage()) {
    navigate('/');
  } else if (response.status === 200 || (response.status === 503 && isOnMaintenancePage())) {
    // Everything is fine; no action needed.
    return;
  } else {
    // Server is not in maintenance, but the healthcheck route has an issue.
    console.error(
      'Server is not in maintenance, but there is an issue with the healthcheck route. Status:',
      response.status
    );
  }
};

const handleHealthcheckError = (error: unknown) => {
  // The healthcheck request failed. The server might be unavailable or the route is unreachable.
  console.error(
    'Healthcheck request failed. The server might be unavailable or the healthcheck route is unreachable.',
    error
  );
};

export const useMaintenance = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const checkSiteStatus = async () => {
      try {
        const response = await fetch(`${import.meta.env.VITE_API_ENTRYPOINT}/system/healthcheck`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
          },
        });

        handleHealthcheckResponse(response, navigate);
      } catch (error) {
        handleHealthcheckError(error);
      }
    };

    void checkSiteStatus();

    const intervalId = setInterval(
      () => {
        void checkSiteStatus();
      },
      isOnMaintenancePage() ? INTERVAL_MS_MAINTENANCE : INTERVAL_MS
    );

    return () => clearInterval(intervalId);
  }, [navigate]);
};
