import Chip from '@mui/material/Chip';
import React from 'react';
import { RaRecord, useRecordContext } from 'react-admin';

interface YesNoChipFieldProps {
  source: string;
  yesText?: string;
  noText?: string;
  yesColor?: string;
  noColor?: string;
  condition?: (value: string | boolean | object) => boolean;
  label: string;
}

export const YesNoChipField: React.FC<YesNoChipFieldProps> = ({
  source,
  yesText = 'Oui',
  noText = 'Non',
  yesColor = 'success',
  noColor = 'warning',
  condition = (value) => value === true,
}) => {
  const record: RaRecord = useRecordContext<RaRecord>();
  const conditionValue: string | boolean | object | undefined = record[source];

  const isCondition: boolean | undefined =
    conditionValue !== undefined ? condition(conditionValue) : undefined;

  const chipLabel: string = isCondition !== undefined ? (isCondition ? yesText : noText) : '?';
  const chipColor: string =
    isCondition !== undefined ? (isCondition ? yesColor : noColor) : 'error';
  return (
    <Chip
      sx={{
        borderRadius: 1,
      }}
      label={chipLabel}
      color={chipColor}
    />
  );
};
