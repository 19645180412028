import { t } from 'i18next';
import { Dispatch, useCallback, useEffect } from 'react';
import dataProvider from 'src/data/provider/remote/dataProvider';
import { tokens } from 'src/locales/tokens';
import { User } from 'src/types/contexts/auth';
import { AccessRightsAction, AccessRightsMode, TabEnum } from 'src/types/enums';
import { AccessRights } from 'src/types/pages/users';
import { AccessRightsReducerAction } from 'src/types/reducers';
import { allowedAccessRights } from 'src/utils/allowedAccessRights';
import { convertAccessRightsToMap } from 'src/utils/convertAccessRightsToMap';

export const useManageAccessRights = (
  currentTab: TabEnum,
  hasFetchedInitialRights: boolean,
  selectedGroupLabel: string | null,
  record: User,
  accessRights: AccessRights | null,
  setSelectedGroupLabel: (label: string) => void,
  dispatch: Dispatch<AccessRightsReducerAction>,
  defineError: (message: string, error?: unknown) => void
) => {
  const manageAccessRights = useCallback(
    async (mode: string) => {
      const userId = record['@id'].split('/').pop();
      try {
        let updatedAccessRight: AccessRights = [];
        if (userId) {
          if (mode === AccessRightsMode.INITIAL_LOAD) {
            updatedAccessRight = await dataProvider.getAccessRights(userId);
          } else {
            dispatch({ type: AccessRightsAction.SET_IS_FETCHING_ACCESS_RIGHTS, payload: true });
            updatedAccessRight = await dataProvider.updateAccessRights(
              userId,
              allowedAccessRights(accessRights)
            );
          }
          dispatch({
            type: AccessRightsAction.INIT,
            payload: {
              initialAccessRights: JSON.parse(JSON.stringify(updatedAccessRight)) as AccessRights,
              accessRights: updatedAccessRight,
              initialAccessRightsToMap: convertAccessRightsToMap(updatedAccessRight),
              hasFetchedInitialRights: true,
              isFetchingAccessRights: false,
              hasUpdatedAccessRights: mode === AccessRightsMode.UPDATE,
            },
          });

          if (updatedAccessRight.length > 0 && selectedGroupLabel === null) {
            setSelectedGroupLabel(updatedAccessRight[0].label);
          }
        } else {
          defineError(t(tokens.asyncMessages.UserIdMissing));
        }
      } catch (error: unknown) {
        defineError(t(tokens.asyncMessages.UserFetchError), error);
      }
    },
    [accessRights, defineError, dispatch, record, selectedGroupLabel, setSelectedGroupLabel]
  );

  useEffect(() => {
    if (currentTab === TabEnum.ACCESS_RIGHTS && !hasFetchedInitialRights) {
      manageAccessRights(AccessRightsMode.INITIAL_LOAD).catch((error: Error) => {
        defineError(t(tokens.asyncMessages.UserFetchError), error);
      });
    }
  });

  return {
    handleAccessRightsSave: () => manageAccessRights(AccessRightsMode.UPDATE),
  };
};
