const isObject = (value: any): value is Record<string, any> =>
  value !== null && typeof value === 'object' && !Array.isArray(value);

const areArraysEqual = (arr1: any[], arr2: any[]): boolean => {
  if (arr1.length !== arr2.length) return false;
  return arr1.every((value, index) => {
    if (isObject(value) && isObject(arr2[index])) {
      return areObjectsEqual(value, arr2[index]);
    }
    return value === arr2[index];
  });
};

const areObjectsEqual = (obj1: Record<string, any>, obj2: Record<string, any>): boolean => {
  const keys1 = Object.keys(obj1);
  const keys2 = Object.keys(obj2);

  if (keys1.length !== keys2.length) return false;

  return keys1.every((key) => {
    const val1 = obj1[key];
    const val2 = obj2[key];

    if (Array.isArray(val1) && Array.isArray(val2)) {
      return areArraysEqual(val1, val2);
    } else if (isObject(val1) && isObject(val2)) {
      return areObjectsEqual(val1, val2);
    } else {
      return val1 === val2;
    }
  });
};

const deepTransform = <T>(data: Partial<T>, previousData: Partial<T>): Partial<T> => {
  const patchData: Partial<T> = {};

  Object.keys(data).forEach((key) => {
    const dataValue = data[key as keyof T];
    const prevDataValue = previousData[key as keyof T];

    if (Array.isArray(dataValue) && Array.isArray(prevDataValue)) {
      if (!areArraysEqual(dataValue, prevDataValue)) {
        patchData[key as keyof T] = dataValue;
      }
    } else if (isObject(dataValue) && isObject(prevDataValue)) {
      const nestedPatch = deepTransform(dataValue, prevDataValue);
      if (Object.keys(nestedPatch).length > 0) {
        patchData[key as keyof T] = nestedPatch as unknown as T[keyof T];
      }
    } else if (dataValue !== prevDataValue) {
      patchData[key as keyof T] = dataValue;
    }
  });

  return patchData;
};

export const transform = <T>(
  data: Partial<T>,
  options?: { previousData?: Partial<T> }
): Partial<T> => {
  const previousData: Partial<T> = options?.previousData ?? {};
  return deepTransform(data, previousData);
};
