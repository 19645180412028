import { useState, useCallback } from 'react';

/**
 * Custom hook for managing async messages.
 * It provides state variables and functions to handle error, loading, and success messages.
 */
export const useAsyncMessage = () => {
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [loadingMessage, setLoadingMessage] = useState<string>('');
  const [successMessage, setSuccessMessage] = useState<string>('');
  const [noUpdateMessage, setNoUpdateMessage] = useState<string>('');

  /**
   * Defines an error message and logs the error to the console.
   * @param message - The error message.
   * @param error - The error object (optional).
   */
  const defineError = useCallback((message: string, error?: unknown) => {
    console.error(error instanceof Error ? error : new Error('Une erreur est survenue.'));
    setErrorMessage(message);
  }, []);

  /**
   * Clears all the messages.
   */
  const clearMessages = useCallback(() => {
    setErrorMessage('');
    setLoadingMessage('');
    setSuccessMessage('');
    setNoUpdateMessage('');
  }, []);

  /**
   * Defines a loading message.
   * @param message - The loading message.
   */
  const defineLoading = useCallback((message: string) => {
    setLoadingMessage(message);
  }, []);

  /**
   * Defines a success message.
   * @param message - The success message.
   */
  const defineSuccess = useCallback((message: string) => {
    setSuccessMessage(message);
  }, []);

  /**
   * Sets the no update message.
   *
   * @param {string} message - The message to set.
   */
  const defineNoUpdate = useCallback((message: string) => {
    setNoUpdateMessage(message);
  }, []);

  return {
    errorMessage,
    loadingMessage,
    successMessage,
    noUpdateMessage,
    defineError,
    defineLoading,
    defineSuccess,
    defineNoUpdate,
    clearMessages,
  };
};
