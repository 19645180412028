import { useContext } from 'react';
import { AsyncFeedbackContext } from 'src/contexts/modals/AsyncFeedbackContext';
import { AsyncFeedbackContextType } from 'src/types/contexts/async-feedback';

export const useAsyncFeedbackContext = (): AsyncFeedbackContextType => {
  const context = useContext(AsyncFeedbackContext);
  if (context === undefined) {
    throw new Error('useAsyncFeedbackContext must be used within an ExportListProvider');
  }
  return context;
};
