import { useEffect, useState, useContext } from 'react';
import { AuthContext } from 'src/contexts/auth/jwt';
import { useRouter } from 'src/hooks/useRouter';
import { paths } from 'src/paths';

export const GuestGuard = ({ children }: { children: React.ReactNode }) => {
  const { isAuthenticatedFully } = useContext(AuthContext);
  const router = useRouter();
  const [isAuthChecked, setAuthChecked] = useState(false);

  useEffect(() => {
    if (isAuthenticatedFully) {
      router.replace(paths.index);
    } else {
      setAuthChecked(true);
    }
  }, [isAuthenticatedFully, router]);

  // If authentication check is not done, don't render anything
  if (!isAuthChecked) {
    return null;
  }

  // If got here, it means that the redirect did not occur, and that tells us that the user is
  // not authenticated / authorized.
  return <>{children}</>;
};
