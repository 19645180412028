import { Card, Stack } from '@mui/material';
import { t } from 'i18next';
import React from 'react';
import { Identifier, RaRecord, TextField, useShowContext } from 'react-admin';
import { EmailField, PhoneField } from 'src/components/react-admin/core/fields';
import { LabeledField } from 'src/components/react-admin/core/fields/layout/LabeledField';
import { CardTitle } from 'src/domains/club/members/detail/common/CardTitle';
import { tokens } from 'src/locales/tokens';

import { PresidentDetailSkeleton } from '../../skeletons';

export const PresidentDetail: React.FC = () => {
  const { record, isFetching } = useShowContext<RaRecord<Identifier>>();
  if ((record && !record['president']) || isFetching) return <PresidentDetailSkeleton />;
  return (
    <Card
      sx={{
        display: 'flex',
        flexDirection: 'column',
        flexBasis: '50%',
      }}
    >
      <CardTitle text={t(tokens.domains.clubs.detail.presidentCardtitle)} />
      <Stack flexDirection={'row'}>
        <Stack
          padding={'0px 24px 30px 24px'}
          spacing={4}
          flexBasis={'50%'}
        >
          <LabeledField
            source={'president.fullName'}
            label={t(tokens.domains.members.detail.label.name)}
            component={TextField}
          />
          <LabeledField
            source={'president.phone'}
            label={t(tokens.domains.members.detail.label.phone)}
            component={PhoneField}
          />
          <LabeledField
            source={'president.email'}
            label={t(tokens.domains.members.detail.label.mail)}
            component={EmailField}
          />
          <LabeledField
            source={'postalAddress.fullAddress'}
            label={t(tokens.domains.members.detail.label.fullAddress)}
            component={TextField}
          />
        </Stack>
        <Stack
          flexGrow="1"
          padding={'0px 24px 30px 24px'}
          spacing={4}
          flexBasis={'50%'}
        >
          <LabeledField
            source={'president.jobTitle'}
            label={t(tokens.domains.members.detail.label.job)}
            component={TextField}
          />
          <LabeledField
            source={'president.organization.businessIdentifier'}
            label={t(tokens.domains.members.detail.label.siret)}
            component={TextField}
          />
          <LabeledField
            source={'president.organization.brand'}
            label={t(tokens.domains.members.detail.label.corporateName)}
            component={TextField}
          />
          <LabeledField
            source={'president.organization.legalForm.name'}
            label={t(tokens.domains.members.detail.label.legalForm)}
            component={TextField}
          />
        </Stack>
      </Stack>
    </Card>
  );
};
