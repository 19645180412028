import Paper from '@mui/material/Paper';
import Switch from '@mui/material/Switch';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import React from 'react';
import { useAuth } from 'src/domains/system/auth/hooks/useAuth';
import { AccessRights } from 'src/types/pages/users';

interface UserAccessRightsTableProps {
  accessRights: AccessRights | null;
  isFetchingAccessRights: boolean;
  selectedGroupLabel: string | null;
  handleGlobalSwitchChange: (checked: boolean) => void;
  handleSwitchChange: (id: string, checked: boolean) => void;
}

export const UserAccessRightsTable: React.FC<UserAccessRightsTableProps> = ({
  accessRights,
  isFetchingAccessRights,
  selectedGroupLabel,
  handleGlobalSwitchChange,
  handleSwitchChange,
}) => {
  const { user } = useAuth();
  const { roles } = user || {};
  const isSuperAdmin = roles ? roles[0] === 'ROLE_SUPER_ADMIN' : false;
  const areAllChildrenAllowed = () => {
    if (!selectedGroupLabel || !accessRights) return false;

    const group = accessRights.find((g) => g.label === selectedGroupLabel);
    if (!group) return false;

    return group.accessRights.every((right) => right.allowed);
  };
  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>
              <Typography fontWeight={700}>Actions</Typography>
            </TableCell>
            <TableCell>
              <Switch
                checked={areAllChildrenAllowed()}
                onChange={(e) => handleGlobalSwitchChange(e.target.checked)}
                disabled={!isSuperAdmin || isFetchingAccessRights}
              />
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {accessRights
            ?.filter((group) => group.label === selectedGroupLabel)
            .map((group, groupIndex) => (
              <React.Fragment key={groupIndex}>
                {group.accessRights.map((right, rightIndex) => (
                  <TableRow key={rightIndex}>
                    <TableCell
                      width="70%"
                      sx={{ fontSize: '1rem' }}
                    >
                      {right.label}
                    </TableCell>
                    <TableCell>
                      <Switch
                        color="success"
                        checked={right.allowed}
                        onChange={(e) => handleSwitchChange(right['@id'], e.target.checked)}
                        disabled={!isSuperAdmin || isFetchingAccessRights}
                      />
                    </TableCell>
                  </TableRow>
                ))}
              </React.Fragment>
            ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
