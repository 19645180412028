import { Box, InputLabel, Skeleton } from '@mui/material';

interface CustomInputLabelSkeletonProps {
  label: string;
}

export const CustomInputLabelSkeleton: React.FC<CustomInputLabelSkeletonProps> = ({ label }) => {
  return (
    <Box
      sx={{
        width: '100%',
      }}
    >
      <InputLabel
        sx={{
          paddingBottom: '8px',
          color: 'neutral.900',
          fontWeight: 'medium',
        }}
      >
        {label}
      </InputLabel>
      <Skeleton
        variant="text"
        sx={{ fontSize: '18px', height: '25px' }}
      />
    </Box>
  );
};
