import { Card, Stack } from '@mui/material';
import { t } from 'i18next';
import { TextField } from 'react-admin';
import { EmailField, PhoneField, TopLabelField } from 'src/components/react-admin/core/fields';
import { LabeledField } from 'src/components/react-admin/core/fields/layout/LabeledField';
import { tokens } from 'src/locales/tokens';

import { CardTitle } from '../common/CardTitle';

export const MemberDetailContent: React.FC = () => {
  return (
    <Card
      sx={{
        width: '381px',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <CardTitle text={t(tokens.domains.members.detail.memberCardTitle)} />
      <Stack
        flexGrow="1"
        padding={'0px 24px 30px 24px'}
        spacing={4}
      >
        <TopLabelField label={t(tokens.domains.members.detail.label.name)}>
          <TextField source={'user.fullName'} />
        </TopLabelField>
        <LabeledField
          source={'user.email'}
          label={t(tokens.domains.members.detail.label.mail)}
          component={EmailField}
        />
        <TopLabelField label={t(tokens.domains.members.detail.label.phone)}>
          <PhoneField source={'user.phone'} />
        </TopLabelField>
        <TopLabelField label={t(tokens.domains.members.detail.label.job)}>
          <TextField source={'user.jobTitle'} />
        </TopLabelField>
      </Stack>
    </Card>
  );
};
