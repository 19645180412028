import Card from '@mui/material/Card';
import Paper from '@mui/material/Paper';
import Skeleton from '@mui/material/Skeleton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import Stack from '@mui/system/Stack';
import React from 'react';

export const UserAccessRightsSkeleton: React.FC = () => {
  const accessRightsSkeleton = [
    {
      label: '',
      accessRights: [
        {
          '@id': '',
          label: '',
          code: '',
          allowed: false,
        },
      ],
    },
    {
      label: '',
      accessRights: [
        {
          '@id': '',
          label: '',
          code: '',
          allowed: false,
        },
      ],
    },
  ];
  return (
    <>
      <Stack
        flexDirection={{ xs: 'column', sm: 'row' }}
        gap={{ xs: '0', sm: '50px' }}
        width={{ xs: '100%', sm: 'auto' }}
      >
        <Card
          sx={{ width: { xs: '100%', sm: '200px' }, padding: '20px 20px', height: 'fit-content' }}
        >
          <Stack spacing={2}>
            {accessRightsSkeleton.map((_accessRight, index) => {
              return (
                <Skeleton
                  key={index}
                  variant="rounded"
                  sx={{ width: { xs: '100%', sm: 160 } }}
                  height={60}
                />
              );
            })}
          </Stack>
        </Card>
        <Stack flexGrow={1}>
          <Card>
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>
                      <Typography fontWeight={700}>Actions</Typography>
                    </TableCell>
                    <TableCell sx={{ display: 'flex', justifyContent: 'center' }}>
                      <Skeleton
                        variant="text"
                        width="50%"
                        height={60}
                      />
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {accessRightsSkeleton.map((group, groupIndex) => (
                    <React.Fragment key={groupIndex}>
                      {group.accessRights.map((_right, rightIndex) => (
                        <TableRow key={rightIndex}>
                          <TableCell>
                            <Skeleton
                              variant="text"
                              width="100%"
                              height={60}
                            />
                          </TableCell>
                          <TableCell sx={{ display: 'flex', justifyContent: 'center' }}>
                            <Skeleton
                              variant="text"
                              width="50%"
                              height={60}
                            />
                          </TableCell>
                        </TableRow>
                      ))}
                    </React.Fragment>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Card>
        </Stack>
      </Stack>
    </>
  );
};
