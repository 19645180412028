import { t } from 'i18next';
import { Organization } from 'src/admin-api-types';
import { ValidationError } from 'src/domains/club/contract/context';
import { tokens } from 'src/locales/tokens';
import { isEmptyOrSpaces } from 'src/utils/isEmptyOrSpaces';
import { isSiretValidLuhn } from 'src/utils/isSiretValidLuhn';

export const validateSiret = (
  record: Organization,
  values: Partial<Organization>,
  errors: ValidationError
) => {
  if (isEmptyOrSpaces(values.businessIdentifier) && isEmptyOrSpaces(values.associationIdentifier)) {
    errors.businessIdentifier = t(tokens.utils.form.required);
  } else if (
    isEmptyOrSpaces(values.businessIdentifier) &&
    !isEmptyOrSpaces(values.associationIdentifier)
  ) {
    delete errors.businessIdentifier;
  } else {
    if (!isEmptyOrSpaces(values.businessIdentifier)) {
      if (!isSiretValidLuhn(values.businessIdentifier)) {
        errors.businessIdentifier = t(tokens.utils.isValidSiret.siretFormatInvalid);
      } else {
        delete errors.businessIdentifier;
      }
      const businessIdRegex = /^\d{9}/;
      if (record.businessIdentifier && values.businessIdentifier) {
        const recordBusinessIdMatch = record.businessIdentifier.match(businessIdRegex);
        const valuesBusinessIdMatch = values.businessIdentifier.match(businessIdRegex);

        if (
          recordBusinessIdMatch &&
          valuesBusinessIdMatch &&
          recordBusinessIdMatch[0] !== valuesBusinessIdMatch[0]
        ) {
          errors.businessIdentifier = t(tokens.utils.isValidSiret.updatedSirenNotAllowed);
        }
      }
    }
  }
};
