// type.ts
import { t } from 'i18next';
import { TicketTypeValueEnum } from 'src/domains/club/helpdesk/enums';
import { TicketType } from 'src/domains/club/helpdesk/types';
import { tokens } from 'src/locales/tokens';

// Define ticket type options and translations
export const ticketTypeOptionTrad = {
  public: t(tokens.domains.helpdesk.list.filters.type.public),
  user: t(tokens.domains.helpdesk.list.filters.type.user),
} as const;

export const ticketTypeOptions: TicketType[] = [
  {
    label: ticketTypeOptionTrad.public,
    value: TicketTypeValueEnum.PUBLIC,
  },
  {
    label: ticketTypeOptionTrad.user,
    value: TicketTypeValueEnum.USER,
  },
];
