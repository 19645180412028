import { Grow } from '@mui/material';
import Chip from '@mui/material/Chip';
import React, { useCallback, useEffect, useState } from 'react';

interface DateChip {
  identifier: string;
  label: string;
  val: string | number | boolean | Date;
  handleDeleteSelectorFilter: (identifier: string, val: string | number | boolean | Date) => void;
}

export const SelectorChip: React.FC<DateChip> = ({
  identifier,
  label,
  val,
  handleDeleteSelectorFilter,
}) => {
  const [fade, setFade] = useState(false);
  useEffect(() => {
    setFade(true);
  }, [setFade]);

  const handleDeleteClick = useCallback(() => {
    setFade(false);
    handleDeleteSelectorFilter(identifier, val);
  }, [handleDeleteSelectorFilter, identifier, val]);
  return (
    <Grow in={fade}>
      <Chip
        label={`${label}`}
        onDelete={handleDeleteClick}
        size="small"
        color="primary"
        sx={{ marginLeft: '0px !important' }}
      />
    </Grow>
  );
};
