import Badge, { badgeClasses } from '@mui/material/Badge';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import SvgIcon from '@mui/material/SvgIcon';
import Typography from '@mui/material/Typography';
import RefreshCcw01Icon from '@untitled-ui/icons-react/build/esm/RefreshCcw01';
import XIcon from '@untitled-ui/icons-react/build/esm/X';
import { t } from 'i18next';
import React, { useCallback } from 'react';
import { Scrollbar } from 'src/components/scrollbar';
import {
  OptionsColorPreset,
  OptionsColorScheme,
  OptionsContrast,
  OptionsNavColor,
} from 'src/components/settings/settings-drawer/index';
import { DefaultSettings } from 'src/domains/system/settings/settingsTypes';
import { tokens } from 'src/locales/tokens';

interface SettingsDrawerProps {
  canReset: boolean;
  onClose: () => void;
  onUpdate: (newSettings: Partial<DefaultSettings>) => void;
  onReset: () => void;
  open: boolean;
  values: DefaultSettings;
}

const defaultValues = {
  colorPreset: '',
  contrast: '',
  direction: '',
  paletteMode: '',
  responsiveFontSizes: false,
  stretch: false,
  layout: '',
  navColor: '',
};

export const SettingsDrawer: React.FC<SettingsDrawerProps> = ({
  canReset,
  onClose,
  onUpdate,
  onReset,
  open,
  values = defaultValues,
  ...other
}) => {
  const handleFieldUpdate = useCallback(
    (field: keyof DefaultSettings, value: string) => {
      onUpdate?.({
        [field]: value,
      });
    },
    [onUpdate]
  );

  return (
    <Drawer
      disableScrollLock
      anchor="right"
      onClose={onClose}
      open={open}
      ModalProps={{
        BackdropProps: {
          invisible: true,
        },
        sx: { zIndex: 1400 },
      }}
      PaperProps={{
        elevation: 24,
        sx: {
          maxWidth: '100%',
          width: 440,
        },
      }}
      {...other}
    >
      <Scrollbar
        sx={{
          height: '100%',
          '& .simplebar-content': {
            height: '100%',
          },
          '& .simplebar-scrollbar:before': {
            background: 'var(--nav-scrollbar-color)',
          },
        }}
      >
        <Stack
          alignItems="center"
          direction="row"
          justifyContent="space-between"
          spacing={3}
          sx={{
            px: 3,
            pt: 2,
          }}
        >
          <Typography variant="h6">{t(tokens.settingsDrawer.title)}</Typography>
          <Stack
            alignItems="center"
            direction="row"
            spacing={0.5}
          >
            <Badge
              anchorOrigin={{
                horizontal: 'right',
                vertical: 'top',
              }}
              color="error"
              sx={{
                [`& .${badgeClasses.badge}`]: {
                  top: 6,
                  right: 6,
                  ...(!canReset && {
                    display: 'none',
                  }),
                },
              }}
              variant="dot"
            >
              <IconButton
                color="inherit"
                onClick={onReset}
              >
                <SvgIcon fontSize="small">
                  <RefreshCcw01Icon />
                </SvgIcon>
              </IconButton>
            </Badge>
            <IconButton
              color="inherit"
              onClick={onClose}
            >
              <SvgIcon>
                <XIcon />
              </SvgIcon>
            </IconButton>
          </Stack>
        </Stack>
        <Stack
          spacing={5}
          sx={{ p: 3 }}
        >
          <OptionsColorPreset
            onChange={(value: string) => handleFieldUpdate('colorPreset', value)}
            value={values.colorPreset}
          />
          <OptionsColorScheme
            onChange={(value: string) => handleFieldUpdate('paletteMode', value)}
            value={values.paletteMode}
          />
          <OptionsNavColor
            onChange={(value: string) => handleFieldUpdate('navColor', value)}
            value={values.navColor}
          />
          <OptionsContrast
            onChange={(value: string) => handleFieldUpdate('contrast', value)}
            value={values.contrast}
          />
        </Stack>
      </Scrollbar>
    </Drawer>
  );
};
