import { tokens } from '../tokens';

export const en = {
  [tokens.auth.login]: 'Sign-in',
  [tokens.auth.form.email]: 'E-mail Address',
  [tokens.auth.form.password]: 'Password',
  [tokens.auth.form.submit]: 'Login',
  [tokens.common.languageChanged]: 'Language changed',
  [tokens.nav.overview]: 'Overview',
  [tokens.nav.club.subheader]: 'Business club',
  [tokens.nav.club.clubs.list]: 'My clubs',
  [tokens.nav.admin.subheader]: 'Administration',
  [tokens.nav.admin.users.list]: 'Users',
};
