import { useContext } from 'react';
import { TaggedFilterContext } from 'src/contexts/common/filters/tagged-filter-context/context';
import { TaggedFilterContextProps } from 'src/contexts/common/filters/tagged-filter-context/types';

export const useTaggedFilterContext = (): TaggedFilterContextProps => {
  const context = useContext(TaggedFilterContext);
  if (!context) {
    throw new Error('useTaggedFilterContext must be used within a Context');
  }
  return context;
};
