import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import Grid from '@mui/material/Grid';
import { t } from 'i18next';
import React, { useEffect, useState } from 'react';
import { SimpleForm } from 'react-admin';
import { tokens } from 'src/locales/tokens';

import { ContractButton } from '../../components/ContractButton';
import { contractContext } from '../../context/contractContext';
import { FirstStepType, ValidationError } from '../../context/contractContextType';

import { GeneralInfos, MeetingPlace, Meetings } from './components';
import {
  validateAddress,
  validateClubName,
  validateMeetingName,
  validateMeetingTimes,
  validatePresidentRemuneration,
  validateSiretAndRna,
} from './validators';
import { validateDayMatches } from './validators/validateDayMatches';

interface ContratStepOneProps {
  step: number;
  setStep: (step: number) => void;
}

const validateStepOne =
  (isCheckboxChecked: boolean) =>
  (values: FirstStepType): ValidationError => {
    const errors: ValidationError = {};

    validateClubName(values, errors);
    validatePresidentRemuneration(values, errors);
    validateSiretAndRna(values, errors);
    validateMeetingTimes(values, errors);
    validateMeetingName(values, errors);
    validateAddress(values, errors, isCheckboxChecked);
    validateDayMatches(values, errors);
    return errors;
  };

export const ContratStepOne: React.FC<ContratStepOneProps> = ({ step, setStep }) => {
  const { firstStep, setFirstStep, isCheckboxChecked, setIsCheckboxChecked } =
    React.useContext(contractContext);
  const [tempAddress, setTempAddress] = useState<Partial<FirstStepType>>({});
  const [tempclosestAddress, setTempClosestAddress] = useState<Partial<FirstStepType>>({});

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [step]);

  const onSubmit = (val: FirstStepType) => {
    const {
      apiStreet: apiClosestStreet,
      apiPostalCode: apiClosestPostalCode,
      apiCity: apiClosestCity,
      apiLatitude: apiClosestLatitude,
      apiLongitude: apiClosestLongitude,
    } = tempclosestAddress;
    setFirstStep({
      ...firstStep,
      ...val,
      ...tempAddress,
      apiClosestStreet,
      apiClosestPostalCode,
      apiClosestCity,
      apiClosestLatitude,
      apiClosestLongitude,
    });
    setStep(step + 1);
  };
  return (
    <SimpleForm
      toolbar={false}
      defaultValues={firstStep}
      id={'contract_step_one'}
      onSubmit={onSubmit}
      validate={validateStepOne(isCheckboxChecked)}
      sx={{ padding: '0 24px 32px' }}
    >
      <GeneralInfos />
      <Meetings />
      <MeetingPlace
        {...{
          isCheckboxChecked,
          setIsCheckboxChecked,
          setTempAddress,
          setTempClosestAddress,
        }}
      />
      <Grid
        container
        direction={'row-reverse'}
      >
        <Grid item>
          <ContractButton
            color="primary"
            label={t(tokens.utils.step.next)}
            variant="contained"
            endIcon={<ArrowForwardIosIcon />}
            iconStyle={{ fontSize: '12px !important' }}
          />
        </Grid>
      </Grid>
    </SimpleForm>
  );
};
