/**
 * Custom hook that provides user handlers.
 * @returns An object containing user handlers.
 */
export const useUserActionsHandlers = () => {
  const handleActivateDeactivate = () => {
    console.log('handleActivateDeactivate');
    //! Waiting for the API to be ready
  };

  const handleResetPassword = () => {
    console.log('handleResetPassword');
    //! To do in dedicated US
  };

  return { handleActivateDeactivate, handleResetPassword };
};
