import { Box, CircularProgress, InputAdornment, Skeleton } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import Chip from '@mui/material/Chip';
import TextField from '@mui/material/TextField';
import { SelectableItem, useAutocompleteFilter } from 'src/common/hooks';

export interface AutocompleteFilterProps {
  resource: string;
  itemLabel: string;
  order: Order;
  filterKey: string;
  filterValue: string;
  label: string;
  placeholder: string;
}

type Order = 'ASC' | 'DESC';

export const AutocompleteFilter: React.FC<AutocompleteFilterProps> = <T extends SelectableItem>({
  resource,
  itemLabel,
  filterKey,
  filterValue,
  label,
  placeholder,
}: AutocompleteFilterProps) => {
  const { items, selectedItems, handleChange, handleDelete, isLoading } = useAutocompleteFilter({
    resource,
    filterKey,
    filterValue,
    itemLabel,
  });
  return (
    <Box
      sx={{
        pointerEvents: isLoading ? 'none' : 'auto',
        width: '250px',
      }}
    >
      {isLoading ? (
        <Skeleton
          variant="rounded"
          width="100%"
          height={56}
        />
      ) : (
        <Autocomplete
          multiple
          id="tags-outlined"
          limitTags={1}
          options={items as T[]}
          value={selectedItems as T[]}
          getOptionLabel={(option: T) => {
            const label = option[itemLabel as keyof T];
            return typeof label === 'string' ? label : String(label);
          }}
          filterSelectedOptions
          loading={isLoading}
          renderTags={(value, getTagProps) =>
            items && items.length > 0
              ? value.map((option, index) => (
                  // eslint-disable-next-line react/jsx-key
                  <Chip
                    variant="outlined"
                    label={option[itemLabel as keyof SelectableItem]}
                    {...getTagProps({ index })}
                    style={{ backgroundColor: 'primary' }}
                    onDelete={() => handleDelete(option.id)}
                  />
                ))
              : []
          }
          renderInput={(params) => (
            <TextField
              {...params}
              label={label}
              placeholder={placeholder}
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <InputAdornment
                    position="end"
                    sx={{ marginRight: '-12px' }}
                  >
                    {isLoading ? (
                      <Box
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          marginBottom: '15px',
                        }}
                      >
                        <CircularProgress
                          color="primary"
                          size={20}
                        />
                      </Box>
                    ) : (
                      params.InputProps.endAdornment
                    )}
                  </InputAdornment>
                ),
              }}
            />
          )}
          onChange={handleChange}
        />
      )}
    </Box>
  );
};
