import { Box, InputLabel, Skeleton, Stack } from '@mui/material';
import { t } from 'i18next';
import { SimpleForm } from 'react-admin';
import { CustomInputLabelSkeleton } from 'src/domains/club/members/skeletons';
import { CustomTextLabelSkeleton } from 'src/domains/club/members/skeletons/CustomTextLabelSkeleton';
import { tokens } from 'src/locales/tokens';

export const ClubInfoContentSkeleton: React.FC = () => {
  return (
    <SimpleForm
      toolbar={false}
      sx={{
        padding: '0px !important',
      }}
    >
      <Stack
        alignItems={'stretch'}
        width={'100%'}
      >
        <Stack
          flexDirection={'row'}
          gap={4}
        >
          <Box
            flexBasis={'50%'}
            padding={'0px 24px 30px 0px'}
          >
            <CustomInputLabelSkeleton label={t(tokens.domains.members.detail.label.clubName)} />
          </Box>

          <Box
            flexBasis={'50%'}
            padding={'0px 24px 30px 0px'}
          >
            <CustomTextLabelSkeleton label={t(tokens.domains.members.detail.label.format)} />
          </Box>
        </Stack>
        <Stack
          flexDirection={'row'}
          gap={4}
        >
          <Box
            flexBasis={'50%'}
            padding={'0px 24px 30px 0px'}
          >
            <CustomInputLabelSkeleton label={t(tokens.domains.members.detail.label.agency)} />
          </Box>
          <Box
            sx={{
              width: '100%',
              flexBasis: '50%',
            }}
          >
            <InputLabel
              sx={{
                paddingBottom: '8px',
                color: 'neutral.900',
                fontWeight: 'medium',
              }}
            >
              {t(tokens.domains.members.detail.label.clubContract)}
            </InputLabel>
            <Skeleton
              variant="text"
              sx={{ fontSize: '18px', height: '25px', width: '40%' }}
            />
            <Skeleton
              variant="text"
              sx={{ fontSize: '18px', height: '25px', width: '40%' }}
            />
          </Box>
        </Stack>
        <Stack
          flexDirection={'row'}
          gap={4}
        >
          <Box
            flexBasis={'50%'}
            padding={'0px 24px 30px 0px'}
          >
            <CustomInputLabelSkeleton label={t(tokens.domains.members.detail.label.subAgency)} />
          </Box>
          <Stack
            flexDirection={'row'}
            gap={2}
            flexBasis={'50%'}
          >
            <Box>
              <CustomTextLabelSkeleton label={t(tokens.domains.members.detail.label.createdAt)} />
            </Box>
            <Box>
              <CustomTextLabelSkeleton label={t(tokens.domains.members.detail.label.signedAt)} />
            </Box>
            <Box>
              <CustomTextLabelSkeleton label={t(tokens.domains.members.detail.label.disabledAt)} />
            </Box>
          </Stack>
        </Stack>
      </Stack>

      <Box
        display={'flex'}
        justifyContent={'end'}
        width={'100%'}
        margin="24px 0px"
      >
        <Skeleton
          variant="rounded"
          width={160}
          height={40}
          sx={{ marginRight: '60px' }}
        />
      </Box>
    </SimpleForm>
  );
};
