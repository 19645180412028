import { Stack, Link, SvgIcon, Typography } from '@mui/material';
import { ArrowLeftIcon } from '@mui/x-date-pickers/icons';
import React from 'react';
import { Link as RouterLink } from 'react-router-dom';

type CustomBreadCrumbProps = {
  linkText: string;
  to: string;
  Icon?: typeof ArrowLeftIcon;
};

export const BreadCrumb: React.FC<CustomBreadCrumbProps> = ({
  linkText,
  to,
  Icon = ArrowLeftIcon,
}) => {
  return (
    <Stack width="fit-content">
      <Link
        component={RouterLink}
        to={to}
        sx={{
          alignItems: 'center',
          display: 'inline-flex',
          textDecoration: 'none',
          color: 'inherit',
        }}
      >
        <SvgIcon sx={{ mr: 1 }}>
          <Icon />
        </SvgIcon>
        <Typography variant="subtitle2">{linkText}</Typography>
      </Link>
    </Stack>
  );
};
