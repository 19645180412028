import Typography from '@mui/material/Typography';
import React from 'react';

interface TopLabelInputProps {
  children: React.ReactNode;
  label: string;
}

export const TopLabelInput: React.FC<TopLabelInputProps> = ({ label, children }) => {
  return (
    <>
      <Typography
        variant={'body2'}
        fontWeight={600}
        gutterBottom
      >
        {label}
      </Typography>
      {children}
    </>
  );
};
