enum MemberSearchFilterEnum {
  MEMBER_TYPE_FILTER = 'memberTypeFilter',
  CLUB_IDS = 'club.id',
  MEMBER = 'id',
  AGENCY = 'club.agencyId',
  ORGANIZATION = 'organization',
  STATUS = 'status',
  CREATED_AT_AFTER = 'createdAt[after]',
  CREATED_AT_BEFORE = 'createdAt[before]',
}

export enum ClubSearchFilterEnum {
  AGENCY = 'agencyId',
  FILTER_NEW = 'new',
  FILTER_CLUB_STATUS = 'activeStatus',
  FILTER_FORMAT = 'format',
  SEARCH_TERM = 'searchTerm',
}

export default MemberSearchFilterEnum;
