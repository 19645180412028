import { ACCESS_TOKEN_STORAGE_KEY } from 'src/contexts/auth/jwt/AuthProvider';

export const getRequestHeaders = (contentType = 'application/ld+json') => {
  const headers = new Headers({
    Accept: 'application/ld+json',
    'Content-Type': contentType,
  });

  const token = localStorage.getItem(ACCESS_TOKEN_STORAGE_KEY);
  if (token) {
    headers.append('Authorization', `Bearer ${token}`);
  }

  return headers;
};
