import { t } from 'i18next';
import { tokens } from 'src/locales/tokens';
import { isEmptyOrSpaces } from 'src/utils/isEmptyOrSpaces';

import { SecondStepType, ValidationError } from '../../../context';

const DB_FLOAT_MAX_LENGTH = 18;

export const validateCompanyDetails = (values: SecondStepType, errors: ValidationError) => {
  if (isEmptyOrSpaces(values.size)) {
    errors.size = t(tokens.utils.form.required);
  } else if (!Number.isInteger(Number(values.size))) {
    errors.size = t(tokens.utils.form.mustBeAnInteger);
  }

  if (isEmptyOrSpaces(values.capital)) {
    errors.capital = t(tokens.utils.form.required);
  } else {
    if (values.capital) {
      const capitalNumber = parseFloat(values.capital);

      if (isNaN(capitalNumber)) {
        errors.capital = t(tokens.utils.form.mustBeANumber);
      } else {
        if (values.capital.length > DB_FLOAT_MAX_LENGTH) {
          errors.capital = t(tokens.utils.form.maxLengthExceeded, {
            maxLength: DB_FLOAT_MAX_LENGTH,
          });
        }
      }
    }
  }
};
