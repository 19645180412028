import Alert from '@mui/material/Alert';
import React from 'react';

interface UserAlertProps {
  active: boolean;
  date: Date;
}

export const UserDeactivatedAlert: React.FC<UserAlertProps> = ({ active, date }) => {
  if (active) {
    return null;
  }

  return (
    <Alert
      severity="error"
      sx={{
        '&.MuiAlert-root': {
          marginTop: '0px',
          paddingLeft: '0px',
          width: { xs: 'auto', sm: 'fit-content' },
        },
      }}
    >
      Cet utilisateur a été désactivé le {date.toLocaleDateString()}
    </Alert>
  );
};
