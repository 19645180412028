import { t } from 'i18next';
import { tokens } from 'src/locales/tokens';
import { capitalizeFirstLetter } from 'src/utils/capitalizeFirstLetter';

export const dayOption = [
  {
    id: 'value0',
    name: t(tokens.domains.contract.stepOne.meetings.meetingDay.options.defaultOption),
    disabled: true,
  },
  { id: 'value1', name: capitalizeFirstLetter(t(tokens.utils.days.monday)) },
  { id: 'value2', name: capitalizeFirstLetter(t(tokens.utils.days.tuesday)) },
  { id: 'value3', name: capitalizeFirstLetter(t(tokens.utils.days.wednesday)) },
  { id: 'value4', name: capitalizeFirstLetter(t(tokens.utils.days.thursday)) },
  { id: 'value5', name: capitalizeFirstLetter(t(tokens.utils.days.friday)) },
  { id: 'value6', name: capitalizeFirstLetter(t(tokens.utils.days.saturday)) },
  { id: 'value7', name: capitalizeFirstLetter(t(tokens.utils.days.sunday)) },
];
