import { t } from 'i18next';
import { tokens } from 'src/locales/tokens';
import { isEmptyOrSpaces } from 'src/utils/isEmptyOrSpaces';
import { isValidPostalCode } from 'src/utils/isValidPostalCode';

import { SecondStepType, ValidationError } from '../../../context';

export const validateAddress = (values: SecondStepType, errors: ValidationError) => {
  if (isEmptyOrSpaces(values.street)) {
    errors.street = t(tokens.utils.form.required);
  }

  if (isEmptyOrSpaces(values.zipCode)) {
    errors.zipCode = t(tokens.utils.form.required);
  } else if (!isValidPostalCode(values.zipCode)) {
    errors.zipCode = t(tokens.domains.contract.validators.invalidPostalcode);
  }

  if (isEmptyOrSpaces(values.city)) {
    errors.city = t(tokens.utils.form.required);
  }
};
