import { AccessRights } from 'src/types/pages/users';

export const allowedAccessRights = (accessRights: AccessRights | null) => {
  if (!accessRights) {
    return { accessRights: [] };
  }
  const codes = accessRights.reduce((acc: AccessRights, group) => {
    const allowedRightsInGroup = group.accessRights
      .filter((right) => right.allowed)
      .map((right) => '/access_rights/' + right.code);
    return acc.concat(allowedRightsInGroup as unknown as AccessRights);
  }, []);

  return { accessRights: codes };
};
