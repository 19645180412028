import { Box, Typography } from '@mui/material';
import React from 'react';
import { RaRecord, useRecordContext } from 'react-admin';

export interface EmailFieldProps {
  source: string;
}

export const EmailField: React.FC<EmailFieldProps> = ({ source }) => {
  const record = useRecordContext<RaRecord>();

  if (!record) {
    return null;
  }

  const email = source
    .split('.')
    .reduce((obj, key) => obj && obj[key], record) as unknown as string;

  if (!email) {
    return null;
  }

  return (
    <Box
      display="flex"
      flexDirection="row"
      alignItems="center"
    >
      <Typography
        variant="body2"
        sx={{
          color: 'text.primary',
          '&:hover': {
            color: 'primary.main',
          },
        }}
      >
        <a
          href={`mailto:${email}`}
          style={{ textDecoration: 'none', color: 'inherit' }}
        >
          {email}
        </a>
      </Typography>
    </Box>
  );
};
