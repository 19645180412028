import { IconButton, Box } from '@mui/material';
import React from 'react';
import { GenericMenu } from 'src/common/components/GenericMenu';
import { ActionItem } from 'src/types/sections/generic-types';

interface IconButtonWithMenuProps {
  icon: JSX.Element;
  actions: ActionItem[];
  anchorRef: React.RefObject<HTMLButtonElement>;
  handleClose: () => void;
  open: boolean;
  handleOpen: () => void;
}

export const IconButtonWithMenu: React.FC<IconButtonWithMenuProps> = ({
  icon,
  actions,
  anchorRef,
  handleClose,
  open,
  handleOpen,
}) => {
  return (
    <Box
      display="flex"
      alignItems="center"
    >
      <IconButton
        aria-label="more"
        onClick={handleOpen}
        ref={anchorRef}
      >
        {icon}
      </IconButton>
      <GenericMenu
        anchorEl={anchorRef.current}
        open={open}
        handleClose={handleClose}
        actions={actions}
      />
    </Box>
  );
};
