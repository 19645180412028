import { lazy } from 'react';
import { RouteObject } from 'react-router';
import MaintenancePage from 'src/common/components/MaintenancePage';
import AdminContainer from 'src/react-admin/AdminContainer';

import { authRoutes } from './auth';

const Error401Page = lazy(() => import('src/domains/system/error/401'));
const Error404Page = lazy(() => import('src/domains/system/error/404'));
const Error500Page = lazy(() => import('src/domains/system/error/500'));

/**
 * Array of routes for the application.
 * Each route object contains a path and an element component.
 * The path specifies the URL path for the route.
 * The element component represents the component to render when the route is matched.
 *
 * @type {Array}
 */
export const routes: RouteObject[] = [
  ...authRoutes,
  {
    path: '/maintenance',
    element: <MaintenancePage />,
  },
  {
    path: '/*',
    element: <AdminContainer />,
  },
  {
    path: '401',
    element: <Error401Page />,
  },
  {
    path: '404',
    element: <Error404Page />,
  },
  {
    path: '500',
    element: <Error500Page />,
  },
  {
    path: '*',
    element: <Error404Page />,
  },
];
