import { Box, Card, Skeleton, Stack } from '@mui/material';
import { t } from 'i18next';
import { SimpleForm } from 'react-admin';
import { tokens } from 'src/locales/tokens';

import { CustomTextLabelSkeleton } from './CustomTextLabelSkeleton';

import { CardTitleSkeleton } from '.';

export const OrganizationCardEditContentSkeleton: React.FC = () => {
  return (
    <Card
      sx={{
        width: '100%',
      }}
    >
      <CardTitleSkeleton />
      <SimpleForm
        toolbar={false}
        sx={{
          padding: '0px 24px !important',
        }}
      >
        <Stack
          direction={'column'}
          spacing={4}
          sx={{
            width: '100%',
          }}
        >
          <Stack
            direction={'row'}
            spacing={3}
          >
            <CustomTextLabelSkeleton label={t(tokens.domains.members.detail.label.name)} />
            <CustomTextLabelSkeleton label={t(tokens.domains.members.detail.label.brand)} />
            <CustomTextLabelSkeleton label={t(tokens.domains.members.detail.label.activity)} />
          </Stack>
          <Stack
            direction={'row'}
            spacing={3}
          >
            <CustomTextLabelSkeleton label={t(tokens.domains.members.detail.label.siret)} />
            <CustomTextLabelSkeleton label={t(tokens.domains.members.detail.label.fullAddress)} />
            <CustomTextLabelSkeleton label={t(tokens.domains.members.detail.label.website)} />
          </Stack>
          <Stack
            direction={'row'}
            spacing={3}
          >
            <CustomTextLabelSkeleton label={t(tokens.domains.members.detail.label.rna)} />
            <CustomTextLabelSkeleton label={t(tokens.domains.members.detail.label.capital)} />
            <CustomTextLabelSkeleton label={t(tokens.domains.members.detail.label.size)} />
          </Stack>
        </Stack>
        <Box
          display={'flex'}
          justifyContent={'end'}
          width={'100%'}
          margin="24px 0px"
        >
          <Skeleton
            variant="rounded"
            width={210}
            height={60}
            sx={{ marginRight: '60px' }}
          />
        </Box>
      </SimpleForm>
    </Card>
  );
};
