import { Card, Stack, Typography } from '@mui/material';
import { t } from 'i18next';
import React from 'react';
import { tokens } from 'src/locales/tokens';

import { VerticalLinearStepper } from '../components/ContractStepper';

interface ContractStepLayoutProps {
  step: number;
  children: React.ReactNode[];
}

export const ContractStepLayout: React.FC<ContractStepLayoutProps> = ({ step, children }) => {
  return (
    <Stack
      direction="row"
      gap="24px"
    >
      <Stack
        gap={'24px'}
        width="390px"
        marginBottom="24px"
        sx={{
          backgroundColor: 'primary.lightest',
        }}
        borderRadius="20px"
        padding="24px"
      >
        <Typography
          variant="h6"
          gutterBottom
          color="neutral.800"
        >
          {t(tokens.domains.contract.stepper.title)}
        </Typography>
        <VerticalLinearStepper {...{ step }} />
      </Stack>
      <Card
        sx={{
          flexGrow: 1,
          flexShrink: 1,
          height: '100%',
        }}
      >
        {children}
      </Card>
    </Stack>
  );
};
