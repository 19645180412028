import { Box } from '@mui/material';
import React, { useCallback } from 'react';
import { useFileDownloader } from 'src/hooks/useFileDownloader';
import { ListExport } from 'src/types/contexts/modals';
import { isValidExportStatus } from 'src/utils/isValidExportStatus';

import { DividerStyled } from '../styles/style';

import { ExportListItemDetails } from './ExportListItemDetails';
import { ExportListItemHeader } from './ExportListItemHeader';
import { exportStateConfig } from './exportStateConfig';

interface ExportListItemProps {
  item: ListExport;
  resource: string;
}

export const ExportListItem: React.FC<ExportListItemProps> = ({ item }) => {
  const downloadFile = useFileDownloader();

  const getDownloadLink = useCallback(() => {
    downloadFile(item.downloadUrl, item.name).catch((error) => {
      console.error(error);
    });
  }, [downloadFile, item.downloadUrl, item.name]);
  if (!isValidExportStatus(item.status)) return null;
  const { statusIcon } = exportStateConfig[item.status](item, getDownloadLink);
  return (
    <Box
      sx={{ backgroundColor: 'white' }}
      width="100%"
    >
      <ExportListItemHeader item={item} />
      <DividerStyled sx={{ my: 2, width: '100%', marginY: 0 }} />
      <ExportListItemDetails
        item={item}
        statusIcon={statusIcon}
      />
    </Box>
  );
};
