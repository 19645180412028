import ListItemText from '@mui/material/ListItemText';
import React from 'react';
import { AsSplitButtonFilterRenderOption } from 'src/common/components/filters/SplitButtonFilter/SplitButtonFilterRenderOption';

export const HelpdeskTopicSplitButtonFilterRenderOption: React.FC<
  AsSplitButtonFilterRenderOption
> = ({ option: { item } }) => {
  return (
    <ListItemText
      primary={item.label}
      secondary={item.type}
    />
  );
};
