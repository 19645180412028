import { useEffect, useState } from 'react';
import { AccessRightsToMap } from 'src/types/pages/users';
import { AccessRightsReducerState } from 'src/types/reducers';
import { checkIfAccessRightsModified } from 'src/utils/checkIfAccessRightsModified';
import { convertAccessRightsToMap } from 'src/utils/convertAccessRightsToMap';

export const useCheckAccessRightsModified = (accessRightState: AccessRightsReducerState) => {
  const [areAccessRightsModified, setAreAccessRightsModified] = useState<boolean>(false);

  useEffect(() => {
    const currentRightsMap: AccessRightsToMap = convertAccessRightsToMap(
      accessRightState.accessRights
    );
    if (accessRightState.initialAccessRightsToMap) {
      const modified = checkIfAccessRightsModified(
        currentRightsMap,
        accessRightState.initialAccessRightsToMap
      );
      setAreAccessRightsModified(modified);
    }
  }, [accessRightState.accessRights, accessRightState.initialAccessRightsToMap]);

  return areAccessRightsModified;
};
