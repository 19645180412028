import { Box, Card, Stack } from '@mui/material';
import { t } from 'i18next';
import { SaveButton, SimpleForm } from 'react-admin';
import { Member } from 'src/admin-api-types';
import { OrganizationSourceEnum } from 'src/common/enums/react-admin/source-enum';
import { tokens } from 'src/locales/tokens';

import { MemberShowContentAgencySkeleton } from '../../skeletons';
import { CardTitle } from '../common/CardTitle';
import { ConcatAddressLabel } from '../fields/ConcatAddressLabel';
import { CustomInputLabel } from '../fields/CustomInputLabel';
import { CustomTextLabel } from '../fields/CustomTextLabel';

import { MemberDetail } from './MemberDetail';

interface MemberDetailAgencyContentProps {
  record: Member;
}

export const MemberDetailAgencyContent: React.FC<MemberDetailAgencyContentProps> = ({ record }) => {
  if (!record) return <MemberShowContentAgencySkeleton />;
  return (
    <Card sx={{ width: '1222px' }}>
      <CardTitle text={t(tokens.domains.members.detail.memberCardTitle)} />
      <SimpleForm
        toolbar={false}
        sx={{
          padding: '0px 24px !important',
          width: '1222px',
        }}
      >
        <Stack
          direction={'column'}
          spacing={4}
          sx={{
            width: '1222px',
          }}
        >
          <Stack
            direction={'row'}
            spacing={3}
          >
            <MemberDetail {...{ record }} />
          </Stack>
          <Stack
            direction={'row'}
            spacing={3}
          >
            <ConcatAddressLabel label={t(tokens.domains.members.detail.label.fullAddress)} />
            <CustomTextLabel
              source={'legalForm.name'}
              label={t(tokens.domains.members.detail.label.legalForm)}
            />
            <CustomTextLabel
              source={OrganizationSourceEnum.businessIdentifier}
              label={t(tokens.domains.members.detail.label.siret)}
            />
            <CustomTextLabel
              source=""
              label={''}
            />
          </Stack>
          <Stack
            direction={'row'}
            spacing={3}
            sx={{
              width: '431px',
            }}
          >
            <CustomInputLabel
              source={OrganizationSourceEnum.name}
              label={t(tokens.domains.members.detail.label.companyName)}
            />
          </Stack>
        </Stack>
        <Box
          display={'flex'}
          justifyContent={'end'}
          width={'100%'}
          margin="24px 0px"
        >
          <SaveButton
            label={t(tokens.common.button.validate)}
            type="button"
            icon={<></>}
            sx={{
              marginRight: '50px',
            }}
          />
        </Box>
      </SimpleForm>
    </Card>
  );
};
