import { ReactNode } from 'react';

import { TopLabelField } from './TopLabelField';

interface LabeledFieldProps<T = object> {
  label: string;
  source?: string;
  component?: React.FC<T>;
  children?: ReactNode;
  componentProps?: T;
}

export const LabeledField = <T extends object>({
  label,
  component: Component,
  source,
  componentProps = {} as T,
  children,
}: LabeledFieldProps<T>) => {
  return (
    <TopLabelField
      component={Component as React.FC<unknown>}
      componentProps={{
        variant: 'body2',
        fontWeight: '400',
        gutterBottom: true,
        ...componentProps,
      }}
      label={label}
      source={source}
    >
      {children}
    </TopLabelField>
  );
};
