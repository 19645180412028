import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { t } from 'i18next';
import React from 'react';
import { tokens } from 'src/locales/tokens';

const options = [
  {
    label: t(tokens.settingsDrawer.contrast.normal),
    value: 'normal',
  },
  {
    label: t(tokens.settingsDrawer.contrast.high),
    value: 'high',
  },
];

interface OptionsContrastProps {
  onChange: (value: string) => void;
  value: string;
}

export const OptionsContrast: React.FC<OptionsContrastProps> = (props) => {
  const { onChange, value } = props;

  return (
    <Stack spacing={1}>
      <Typography
        color="text.secondary"
        variant="overline"
      >
        {t(tokens.settingsDrawer.contrast.title)}
      </Typography>
      <Stack
        alignItems="center"
        direction="row"
        flexWrap="wrap"
        gap={2}
      >
        {options.map((option) => (
          <Chip
            key={option.label}
            label={option.label}
            onClick={() => onChange?.(option.value)}
            sx={{
              borderColor: 'transparent',
              borderRadius: 1.5,
              borderStyle: 'solid',
              borderWidth: 2,
              ...(option.value === value && {
                borderColor: 'primary.main',
              }),
            }}
          />
        ))}
      </Stack>
    </Stack>
  );
};
