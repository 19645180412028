import React from 'react';
import { ShowBase, useRecordContext } from 'react-admin';
import { Club } from 'src/admin-api-types';

import { Header } from '../detail/common/Header';
import { ClubDetailHeaderSkeleton } from '../skeletons';

export const ClubDetailHeader: React.FC = () => {
  const record = useRecordContext<Club>();

  if (!record) {
    return <ClubDetailHeaderSkeleton />;
  }
  return (
    <ShowBase>
      <Header
        name={record['name']}
        resource="clubs"
        hasExport={false}
      />
    </ShowBase>
  );
};
