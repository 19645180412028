import React from 'react';
import { PropertyList } from 'src/components/PropertyList';
import { PropertyListItem } from 'src/components/PropertyListItem';

interface GeneralInformationProps {
  details: {
    label: string;
    value: string;
    divider?: boolean;
  }[];
}

export const GeneralInformation: React.FC<GeneralInformationProps> = ({ details }) => {
  return (
    <PropertyList>
      {details.map((detail, index) => (
        <PropertyListItem
          key={index}
          divider={detail.divider !== false}
          label={detail.label}
        >
          {detail.value}
        </PropertyListItem>
      ))}
    </PropertyList>
  );
};
