import Alert from '@mui/material/Alert';
import Box from '@mui/system/Box';
import Container from '@mui/system/Container';
import { Reducer, useEffect, useReducer, useState } from 'react';
import { ShowProps, useShowController } from 'react-admin';
import { AdminTabs, TabItem } from 'src/common/components/AdminTabs';
import { GeneralInformation } from 'src/common/components/GeneralInformation';
import { GeneralInformationWrapper } from 'src/common/components/GeneralInformationWrapper';
import { useAsyncMessage } from 'src/common/hooks/useAsyncMessage';
import { Seo } from 'src/components/Seo';
import UserAccessRights from 'src/domains/admin/users/components/UserAccessRights';
import { UserAccessRightsAlert } from 'src/domains/admin/users/components/UserAccessRightsAlert';
import { UserAccessRightsSkeleton } from 'src/domains/admin/users/components/UserAccessRightsSkeleton';
import { UserHeader } from 'src/domains/admin/users/components/UserHeader';
import { useAccessRightsHandlers } from 'src/domains/admin/users/hooks/useAccessRightsHandlers';
import { useCheckAccessRightsModified } from 'src/domains/admin/users/hooks/useCheckAccessRightsModified';
import withAccessGuard from 'src/domains/system/auth/hocs/withAccessGuard';
import { useManageAccessRights } from 'src/hooks/useManageAccessRights';
import { EnhancedUser, User } from 'src/types/contexts/auth';
import { TabEnum } from 'src/types/enums';
import { AccessCodes } from 'src/types/enums/access-rights-codes-enum';
import { AccessRightsReducerAction, AccessRightsReducerState } from 'src/types/reducers';

import {
  accessRightsReducer,
  accessRightsReducerInitialState,
} from './reducers/access-rights-reducer';

export type TabValue = TabEnum.GENERAL_INFOS | TabEnum.ACCESS_RIGHTS;

const tabs: TabItem<TabValue>[] = [
  { label: 'Informations générales', value: TabEnum.GENERAL_INFOS },
  { label: "Droits d'accès", value: TabEnum.ACCESS_RIGHTS },
];

const UserShow = (props: ShowProps) => {
  const controllerProps = useShowController(props);
  const record = controllerProps.record as User;

  const [accessRightState, dispatch] = useReducer<
    Reducer<AccessRightsReducerState, AccessRightsReducerAction>
  >(accessRightsReducer, accessRightsReducerInitialState);
  const [selectedGroupLabel, setSelectedGroupLabel] = useState<string | null>(null);
  const { handleGlobalSwitchChange, handleSwitchChange, handleAccessRightsCancel } =
    useAccessRightsHandlers(accessRightState, dispatch, selectedGroupLabel);

  const [currentTab, setCurrentTab] = useState<TabValue>(TabEnum.GENERAL_INFOS);
  const { errorMessage, defineError } = useAsyncMessage();

  const [recordCopy, setRecordCopy] = useState<EnhancedUser | null>(null); // to be removed

  const handleAccessRightChange = (label: string) => {
    setSelectedGroupLabel(label);
  };

  const handleTabsChange = (_event: React.SyntheticEvent, newValue: TabValue) => {
    setCurrentTab(newValue);
  };

  const { handleAccessRightsSave } = useManageAccessRights(
    currentTab,
    accessRightState.hasFetchedInitialRights,
    selectedGroupLabel,
    record,
    accessRightState.accessRights,
    setSelectedGroupLabel,
    dispatch,
    defineError
  );

  const areAccessRightsModified = useCheckAccessRightsModified(accessRightState);

  useEffect(() => {
    if (record) {
      const recordCopy = JSON.parse(JSON.stringify(record)) as EnhancedUser;

      if (!recordCopy.active) {
        recordCopy.date = new Date();
      }

      setRecordCopy(recordCopy);
    }
  }, [record]);

  if (!record) {
    return null;
  }

  if (!recordCopy) {
    return null;
  }

  return (
    <>
      <Seo title="Tableau de bord : détails de l'utilisateur" />
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          py: 8,
        }}
      >
        <Container maxWidth="xl">
          <UserHeader
            record={record}
            recordCopy={recordCopy}
          />
          <AdminTabs
            tabs={tabs}
            currentTab={currentTab}
            onChange={handleTabsChange}
          />
          <UserAccessRightsAlert
            areAccessRightsModified={areAccessRightsModified}
            isFetchingAccessRights={accessRightState.isFetchingAccessRights}
            hasUpdatedAccessRights={accessRightState.hasUpdatedAccessRights}
            handleAccessRightsCancel={handleAccessRightsCancel}
            handleAccessRightsSave={handleAccessRightsSave}
            dispatch={dispatch}
          />
          {currentTab === TabEnum.GENERAL_INFOS && (
            <GeneralInformationWrapper>
              <GeneralInformation
                details={[
                  { label: 'Prénom', value: record.firstname },
                  { label: 'Nom', value: record.lastname },
                  { label: 'E-mail', value: record.email },
                ]}
              />
            </GeneralInformationWrapper>
          )}
          {currentTab === TabEnum.ACCESS_RIGHTS &&
            !errorMessage &&
            (accessRightState.initialAccessRights ? (
              <UserAccessRights
                accessRights={accessRightState.accessRights}
                isFetchingAccessRights={accessRightState.isFetchingAccessRights}
                selectedGroupLabel={selectedGroupLabel}
                handleAccessRightChange={handleAccessRightChange}
                handleGlobalSwitchChange={handleGlobalSwitchChange}
                handleSwitchChange={handleSwitchChange}
              />
            ) : (
              <UserAccessRightsSkeleton />
            ))}
          {errorMessage && <Alert severity="error">{errorMessage}</Alert>}
        </Container>
      </Box>
    </>
  );
};

export const UserShowWithAccess = withAccessGuard<ShowProps>(UserShow, AccessCodes.ADMIN_USER_VIEW);
