import { t } from 'i18next';
import { tokens } from 'src/locales/tokens';

export const formatContent = (format: string | null | undefined) => {
  if (!format) return '';
  switch (format) {
    case 'fortnightly':
      return t(tokens.utils.format.fortnightly);
    case 'monthly':
      return t(tokens.utils.format.monthly);
    case 'weekly':
      return t(tokens.utils.format.weekly);
    default:
      return '';
  }
};
