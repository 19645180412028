import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import React from 'react';

import { Item } from '../config';

import { SideNavItem } from './SideNavItem';

interface NavItemProps {
  item: Item;
  depth: number;
  pathname: string;
}

const NavItem: React.FC<NavItemProps> = ({ item, depth, pathname }) => {
  const partialMatch = item.path && pathname ? pathname.includes(item.path) : false;

  return (
    <SideNavItem
      active={partialMatch}
      depth={depth}
      disabled={item.disabled}
      icon={item.icon}
      key={item.title}
      label={item.label}
      path={item.path}
      title={item.title}
      component={item.component}
    >
      {item.items && (
        <Stack
          component="ul"
          spacing={0.5}
          sx={{ listStyle: 'none', m: 0, p: 0 }}
        >
          {item.items.map((subItem: Item) => (
            <NavItem
              item={subItem}
              depth={depth + 1}
              pathname={pathname}
              key={subItem.title}
            />
          ))}
        </Stack>
      )}
    </SideNavItem>
  );
};

interface SideNavSectionProps {
  items: Item[];
  pathname: string;
  subheader?: string;
}

export const SideNavSection: React.FC<SideNavSectionProps> = ({
  items = [],
  pathname,
  subheader = '',
  ...other
}) => (
  <Stack
    component="ul"
    spacing={0.5}
    sx={{ listStyle: 'none', m: 0, p: 0 }}
    {...other}
  >
    {subheader && (
      <Box
        component="li"
        sx={{
          color: 'var(--nav-section-title-color)',
          fontSize: 12,
          fontWeight: 700,
          lineHeight: 1.66,
          mb: 1,
          ml: 1,
          textTransform: 'uppercase',
        }}
      >
        <Typography>{subheader}</Typography>
      </Box>
    )}
    {items.map((item: Item) => (
      <NavItem
        item={item}
        depth={0}
        pathname={pathname}
        key={item.title}
      />
    ))}
  </Stack>
);
