import { Grid, Stack, Typography } from '@mui/material';
import { t } from 'i18next';
import { tokens } from 'src/locales/tokens';
import { ListExport } from 'src/types/contexts/modals';
import { formatDateTime } from 'src/utils/formatDateTime';

interface ExportListItemDetailsProps {
  item: ListExport;
  statusIcon: JSX.Element;
}

export const ExportListItemDetails: React.FC<ExportListItemDetailsProps> = ({
  item,
  statusIcon,
}) => (
  <Grid
    container
    flexDirection="row"
    justifyContent="space-between"
    alignItems="center"
  >
    <Grid
      container
      item
      xs={8}
    >
      <Stack
        flexDirection="row"
        height="30px"
        alignItems={'center'}
      >
        <Typography
          variant="body2"
          fontWeight={600}
          marginLeft={2}
        >
          {t(tokens.modale.export.createdAt)}
        </Typography>
        <Typography
          variant="body2"
          marginLeft={1}
        >
          {formatDateTime(item.createdAt)}
        </Typography>
      </Stack>
      <Stack
        flexDirection="row"
        height="30px"
        alignItems={'center'}
        justifyContent={'space-between'}
      >
        <Stack
          alignItems={'center'}
          flexDirection="row"
        >
          <Typography
            variant="body2"
            fontWeight={600}
            marginLeft={2}
          >
            {t(tokens.modale.export.status)}
          </Typography>
          <Typography
            variant="body2"
            marginLeft={1}
          >
            {item.translatedStatus}
          </Typography>
        </Stack>
      </Stack>
    </Grid>
    <Grid>{statusIcon}</Grid>
  </Grid>
);
