import React from 'react';
import { Pagination } from 'react-admin';
import { ListBaseLayout } from 'src/common/layouts/react-admin/ListBaseLayout';

import { ClubFilters } from './filters/ClubFilters';
import { ClubGrid } from './grid/ClubGrid';

export const ClubListTable: React.FC = () => {
  return (
    <ListBaseLayout>
      <ClubFilters />
      <ClubGrid />
      <Pagination />
    </ListBaseLayout>
  );
};
