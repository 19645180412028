import { useListContext } from 'react-admin';

export type FilterValues = { [key: string]: string[] | Date };

interface SetFilters {
  (filters: FilterValues, displayedFilters: unknown, debounce?: boolean): void;
}

export const useReactAdminFilters = () => {
  const context = useListContext();

  const filterValues = context.filterValues as FilterValues;
  const setFilters = context.setFilters as SetFilters;

  return { filterValues, setFilters };
};
