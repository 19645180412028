import { Typography } from '@mui/material';
import React from 'react';
import { ImportExportButtons } from 'src/common/components/ImportExportButtons';
import { HeaderLayout } from 'src/common/layouts/react-admin/HeaderLayout';
import { ListExportModal } from 'src/common/modals/export/ListExportModal';

interface HeaderProps {
  title: string;
  resource: string;
  hasExport: boolean;
}

export const Header: React.FC<HeaderProps> = ({ title, resource, hasExport }) => {
  return (
    <HeaderLayout>
      <>
        <Typography variant="h4">{title}</Typography>
      </>
      <>
        {hasExport && <ImportExportButtons />}

        {hasExport && <ListExportModal resource={resource} />}
      </>
    </HeaderLayout>
  );
};
