interface ResourceDefinitionInterface {
  name: string;
  filters: string[];
}

// Define the class implementing the interface
class ApiResourceDefinition implements ResourceDefinitionInterface {
  name: string;
  filters: string[];

  constructor(name: string, filters: string[]) {
    this.name = name;
    this.filters = filters;
  }
}

export default ApiResourceDefinition;
