import React from 'react';
import { Helmet } from 'react-helmet-async';

export const APP_TITLE = 'Dynabuy';

interface SeoProps {
  title: string;
}

export const Seo: React.FC<SeoProps> = (props) => {
  const { title } = props;

  const fullTitle = title ? title + ' | ' + APP_TITLE : APP_TITLE;

  return (
    <Helmet>
      <title>{fullTitle}</title>
    </Helmet>
  );
};
