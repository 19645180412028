import { createContext } from 'react';
import {
  ACCESS_TOKEN_STORAGE_KEY,
  REFRESH_TOKEN_STORAGE_KEY,
  TWO_FACTOR_IN_PROGRESS,
} from 'src/contexts/auth/jwt/AuthProvider';
import { AuthContextValue } from 'src/types/contexts/auth';

import { TwoFactorVerificationError } from './authTypes';

export const checkLocalFullAuthentication = () =>
  localStorage.getItem(ACCESS_TOKEN_STORAGE_KEY) !== null &&
  localStorage.getItem(REFRESH_TOKEN_STORAGE_KEY) !== null;

export const checkLocalTwoFactorProcess = () =>
  localStorage.getItem(TWO_FACTOR_IN_PROGRESS) !== null;

const authContextInitialValue: AuthContextValue = {
  isInitialized: false,
  isAuthenticatedFully: checkLocalFullAuthentication(),
  isAuthenticatedTwoFactorInProgress: checkLocalTwoFactorProcess(),
  user: null,
  issuer: null,
  login: () => Promise.resolve(),
  resetForgotPassword: () => Promise.resolve({ passwordReset: false }),
  requestForgotPassword: () =>
    Promise.resolve({ email: 'example@example.fr', expiresAt: new Date() }),
  verifyTwoFactor: () =>
    Promise.resolve({
      error: '2fa_failed',
      two_factor_complete: false,
    } as TwoFactorVerificationError),
  logout: () => Promise.resolve(),
  email: '',
  passwordReset: false,
};

export const AuthContext = createContext(authContextInitialValue);
