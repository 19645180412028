import { Box, Skeleton } from '@mui/material';

export const CardTitleSkeleton: React.FC = () => {
  return (
    <Box padding="32px 24px">
      <Skeleton
        variant="text"
        sx={{ fontSize: '18px', height: '50px', width: '300px' }}
      />
    </Box>
  );
};
