import { User } from 'src/types/contexts/auth';

export type ActionMenuItem = {
  label: string;
  onClick: () => void;
};
interface UserActionsProps {
  record: User;
  handleActivateDeactivate: () => void;
  handleResetPassword: () => void;
}

export const useUserActions = ({
  record,
  handleActivateDeactivate,
  handleResetPassword,
}: UserActionsProps) => {
  return [
    {
      label: !record.active ? "Activer l'utilisateur" : "Désactiver l'utilisateur",
      onClick: handleActivateDeactivate,
    },
    record.active && {
      label: 'Réinitialiser le mot de passe',
      onClick: handleResetPassword,
    },
  ].filter(Boolean) as ActionMenuItem[];
};
