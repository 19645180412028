import { Stack, Tabs, Tab, Divider } from '@mui/material';
import React from 'react';

export type TabItem<T> = {
  label: string;
  value: T;
};

interface CustomTabsProps<T extends string> {
  tabs: TabItem<T>[];
  currentTab: T;
  onChange: (event: React.SyntheticEvent, newValue: T) => void;
}

export const AdminTabs = <T extends string>({ tabs, currentTab, onChange }: CustomTabsProps<T>) => {
  return (
    <Stack>
      <Tabs
        indicatorColor="primary"
        onChange={onChange}
        scrollButtons="auto"
        sx={{ mt: 3 }}
        textColor="primary"
        value={currentTab}
        variant="scrollable"
      >
        {tabs.map((tab) => (
          <Tab
            key={tab.value}
            label={tab.label}
            value={tab.value}
            data-id={tab.value}
          />
        ))}
      </Tabs>
      <Divider />
    </Stack>
  );
};
