/**
 * Checks if the current access rights map is modified compared to the initial access rights map.
 * @param currentAccessRightsMap - The current access rights map.
 * @param initialAccessRightsMap - The initial access rights map.
 * @returns A boolean indicating whether the current access rights map is modified.
 */
export const checkIfAccessRightsModified = (
  currentAccessRightsMap: Map<string, Map<string, { allowed: boolean }>>,
  initialAccessRightsMap: Map<string, Map<string, { allowed: boolean }>>
): boolean => {
  if (currentAccessRightsMap.size !== initialAccessRightsMap.size) return true;

  for (const [label, currentGroupMap] of currentAccessRightsMap) {
    const initialGroupMap = initialAccessRightsMap.get(label);
    if (!initialGroupMap || currentGroupMap.size !== initialGroupMap.size) return true;

    for (const [id, right] of currentGroupMap) {
      const initialRight = initialGroupMap.get(id);
      if (!initialRight || right.allowed !== initialRight.allowed) return true;
    }
  }

  return false;
};
