import { t } from 'i18next';
import { tokens } from 'src/locales/tokens';

export const formatNbToDay = (nb: number | null | undefined) => {
  if (!nb) return '';
  // translate nb to day, ie 1 -> lundi, 2 -> mardi, etc
  switch (nb) {
    case 1:
      return t(tokens.utils.days.monday);
    case 2:
      return t(tokens.utils.days.tuesday);
    case 3:
      return t(tokens.utils.days.wednesday);
    case 4:
      return t(tokens.utils.days.thursday);
    case 5:
      return t(tokens.utils.days.friday);
    case 6:
      return t(tokens.utils.days.saturday);
    case 7:
      return t(tokens.utils.days.sunday);
    default:
      return '';
  }
};
