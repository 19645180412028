import { t } from 'i18next';
import { tokens } from 'src/locales/tokens';
import { isEmptyOrSpaces } from 'src/utils/isEmptyOrSpaces';

import { FirstStepType, ValidationError } from '../../../context';

export const validateMeetingTimes = (values: FirstStepType, errors: ValidationError) => {
  if (isEmptyOrSpaces(values.dayWeek) || values.dayWeek === 'value0') {
    errors.dayWeek = t(tokens.utils.form.required);
  }

  if (isEmptyOrSpaces(values.startEvent)) {
    errors.startEvent = t(tokens.utils.form.required);
  }

  if (isEmptyOrSpaces(values.openingTime)) {
    errors.openingTime = t(tokens.utils.form.required);
  }

  if (isEmptyOrSpaces(values.closingTime)) {
    errors.closingTime = t(tokens.utils.form.required);
  }

  if (!isEmptyOrSpaces(values.openingTime) && !isEmptyOrSpaces(values.closingTime)) {
    const startingHour = new Date(`1970-01-01T${values.openingTime}:00`);
    const endingHour = new Date(`1970-01-01T${values.closingTime}:00`);
    if (endingHour <= startingHour) {
      errors.openingTime = t(tokens.utils.form.endHourBeforeStartHour);
    }
  }
};
