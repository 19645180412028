import Typography from '@mui/material/Typography';
import Stack from '@mui/system/Stack';
import { parsePhoneNumber } from 'libphonenumber-js';
import React from 'react';
import { RaRecord, useRecordContext } from 'react-admin';
import ReactCountryFlag from 'react-country-flag';
import { getNestedProperty } from 'src/utils/getNestedProperty';

export interface PhoneFieldProps {
  source: string;
  withFlag?: boolean;
  withCountryCode?: boolean;
}

export const PhoneField: React.FC<PhoneFieldProps> = ({
  source,
  withFlag = false,
  withCountryCode = false,
}) => {
  const record = useRecordContext<RaRecord>();

  const value = getNestedProperty<string>(record, source);
  if (value === null) {
    return null;
  }
  const phone = parsePhoneNumber(value);
  return (
    <Stack
      direction={'row'}
      spacing={1}
      alignItems={'center'}
    >
      {withFlag && (
        <ReactCountryFlag
          svg
          countryCode={phone.country as string}
          style={{
            width: '1.5em',
            height: '1.5em',
          }}
        />
      )}

      <Stack
        direction={'row'}
        spacing={1}
        alignItems={'center'}
      >
        {withCountryCode && (
          <Typography variant={'body2'}>(+{phone.countryCallingCode})</Typography>
        )}
        <Typography variant={'body2'}>{phone.formatNational()}</Typography>
      </Stack>
    </Stack>
  );
};
