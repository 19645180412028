import { Fade, Grow } from '@mui/material';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { keyframes, styled } from '@mui/material/styles';
import SvgIcon from '@mui/material/SvgIcon';
import Typography from '@mui/material/Typography';
import React, { useCallback, useEffect, useState } from 'react';
import { LogoDynabuyIco } from 'src/components/logos/logo_dynabuy_ico';
import { useAuth } from 'src/domains/system/auth/hooks/useAuth';

const pulse = keyframes`
  0% {
    opacity: 1;
    transform: scale(1);
  }
  50% {
    opacity: 0.5;
    transform: scale(1.05);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
`;

const StyledSVG = styled(SvgIcon)`
  overflow: visible;

  #top_logo {
    animation: ${pulse} 2s infinite;
    opacity: 0.5;
    transform-origin: 75px 75px;
  }
`;

interface SplashScreenProps {
  subtitle: string;
  open: boolean;
}

export const SplashScreen: React.FC<SplashScreenProps> = ({ subtitle, open }) => {
  const [showSplashscreen, setShowSplashscreen] = useState(open);
  const [showStatus, setShowStatus] = useState(open);
  const [showWelcome, setShowWelcome] = useState(false);
  const { user } = useAuth();
  useEffect(() => {
    if (!open) {
      setShowStatus(false);
    }
  }, [open, setShowSplashscreen, setShowStatus, setShowWelcome]);

  const showWelcomeMessage = useCallback(() => {
    setShowWelcome(true);
    setTimeout(() => {
      setShowSplashscreen(false);
    }, 1000);
  }, [setShowWelcome, setShowSplashscreen]);

  return (
    <Fade
      in={showSplashscreen}
      mountOnEnter
      unmountOnExit
    >
      <Box
        sx={{
          alignItems: 'center',
          backgroundColor: 'background.paper',
          display: 'flex',
          flexDirection: 'column',
          height: '100vh',
          justifyContent: 'center',
          left: 0,
          p: 3,
          position: 'fixed',
          top: 0,
          width: '100vw',
          zIndex: 1400,
        }}
      >
        <Grid
          alignItems={'center'}
          container
          direction={'column'}
          justifyContent={'center'}
        >
          <Grid item>
            <Box
              position={'relative'}
              width={200}
            >
              <StyledSVG
                sx={{
                  fontSize: 200,
                }}
              >
                <LogoDynabuyIco overflow={'visible'} />
              </StyledSVG>
            </Box>
          </Grid>
          <Grid item>
            <Box height={100}>
              <Grow
                in={showStatus}
                mountOnEnter
                unmountOnExit
                onExited={showWelcomeMessage}
              >
                <Typography
                  variant={'h4'}
                  color={'inherit'}
                >
                  {subtitle}
                </Typography>
              </Grow>
              <Grow
                in={showWelcome}
                mountOnEnter
                unmountOnExit
              >
                <Typography
                  variant={'h4'}
                  color={'inherit'}
                >
                  {showWelcome ? `Bonjour ${user?.firstname} !` : subtitle}
                </Typography>
              </Grow>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Fade>
  );
};
