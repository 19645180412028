import { Stack } from '@mui/material';
import { t } from 'i18next';
import React from 'react';
import { Identifier, RaRecord, ShowBase, useShowContext } from 'react-admin';
import { Navigate } from 'react-router-dom';
import { BreadCrumb } from 'src/common/components/breadcrumb';
import { Header } from 'src/common/components/grid/Header';
import { DetailLayout } from 'src/common/layouts/react-admin';
import { HelpdeskTicketShowLayout } from 'src/domains/club/helpdesk/components/show/layout';
import useRole from 'src/domains/system/auth/hooks/useRole';
import { tokens } from 'src/locales/tokens';
import { paths } from 'src/paths';
import { UserRole } from 'src/types/enums/user-roles-enum';

const HelpdeskTicketShow: React.FC = () => {
  return (
    <ShowBase>
      <HelpdeskTicketShowContent />
    </ShowBase>
  );
};

export default HelpdeskTicketShow;

const generateTicketName = (record: RaRecord<Identifier> | undefined) => {
  if (!record) {
    return 'Demande';
  }
  return `Demande ${record.ticketNumber}`;
};

const HelpdeskTicketShowContent: React.FC = () => {
  const { record, isLoading } = useShowContext<RaRecord<Identifier>>();
  const isAgency = useRole(UserRole.AGENCY);
  if (isAgency) {
    return (
      <Navigate
        to="/401"
        replace
      />
    );
  }
  const path = paths.club.helpdesk.list;
  return (
    <DetailLayout seoTitle={isLoading ? 'Chargement' : generateTicketName(record)}>
      <BreadCrumb
        linkText={t(tokens.breadcrumb.helpdeskList)}
        to={path}
      />
      <Header
        title={generateTicketName(record || undefined)}
        resource="clubs"
        hasExport={false}
      />
      <Stack
        flexDirection={'row'}
        gap={'24px'}
      >
        <HelpdeskTicketShowLayout />
      </Stack>
    </DetailLayout>
  );
};
