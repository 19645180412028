import Stack from '@mui/system/Stack';
import { t } from 'i18next';
import { ShowBase } from 'react-admin';
import { BreadCrumb } from 'src/common/components/breadcrumb';
import { DetailLayout } from 'src/common/layouts/react-admin/DetailLayout';
import { useAuth } from 'src/domains/system/auth/hooks/useAuth';
import { tokens } from 'src/locales/tokens';
import { paths } from 'src/paths';
import { UserRole } from 'src/types/enums/user-roles-enum';

import { ClubSourceEnum } from '../clubs/enums/club-source-enum';

import { DetailHeader } from './components/DetailHeader';
import { MemberDetailAgency } from './detail/agence';
import { MemberDetailContent, OrganizationDetail } from './detail/siege';
import { ClubDetailContent } from './detail/siege/ClubDetailContent';

export const MemberShow = () => {
  const { user } = useAuth();
  const { roles } = user || {};
  const isSuperAdmin = roles ? roles.includes(UserRole.SUPER_ADMIN) : false;
  const path = paths.club.members.list;

  return (
    <ShowBase>
      <DetailLayout seoTitle={t(tokens.seo.club.title)}>
        <BreadCrumb
          linkText={t(tokens.breadcrumb.memberList)}
          to={path}
        />
        <DetailHeader />

        {isSuperAdmin && (
          <>
            <Stack
              direction="row"
              spacing={4}
            >
              <MemberDetailContent />
              <OrganizationDetail source={ClubSourceEnum.ORGANIZATION} />
            </Stack>
            <ClubDetailContent />
          </>
        )}
        {!isSuperAdmin && (
          <>
            <MemberDetailAgency source={ClubSourceEnum.ORGANIZATION} />
            <ClubDetailContent />
          </>
        )}
      </DetailLayout>
    </ShowBase>
  );
};
