import React from 'react';
import { Pagination } from 'react-admin';
import { ListBaseLayout } from 'src/common/layouts/react-admin/ListBaseLayout';

import { MemberFilters } from '../filters/MemberFilters';
import { MemberGrid } from '../grid/MemberGrid';

export const MemberListTable: React.FC = () => {
  return (
    <ListBaseLayout>
      <MemberFilters />
      <MemberGrid />
      <Pagination />
    </ListBaseLayout>
  );
};
