import { Skeleton } from '@mui/material';
import React from 'react';
import { ShowBase } from 'react-admin';
import { HeaderLayout } from 'src/common/layouts/react-admin/HeaderLayout';

export const ClubDetailHeaderSkeleton: React.FC = () => {
  return (
    <ShowBase>
      <HeaderLayout>
        <>
          <Skeleton
            variant="text"
            sx={{ fontSize: '18px', height: '25px', width: '200px' }}
          />
        </>
        <></>
      </HeaderLayout>
    </ShowBase>
  );
};
