import { Box, Card, Skeleton, Stack } from '@mui/material';
import { t } from 'i18next';
import { tokens } from 'src/locales/tokens';

import { CustomTextLabel } from '../detail/fields/CustomTextLabel';

import { CustomTextLabelSkeleton } from './CustomTextLabelSkeleton';

import { CardTitleSkeleton } from '.';

export const MemberShowContentAgencySkeleton: React.FC = () => {
  return (
    <Card sx={{ width: '1222px' }}>
      <CardTitleSkeleton />

      <Stack
        direction={'column'}
        spacing={4}
        sx={{
          width: '1222px',
        }}
      >
        <Stack
          direction={'row'}
          spacing={3}
          marginLeft={'24px'}
        >
          <CustomTextLabelSkeleton label={t(tokens.domains.members.detail.label.name)} />
          <CustomTextLabelSkeleton label={t(tokens.domains.members.detail.label.phone)} />
          <CustomTextLabelSkeleton label={t(tokens.domains.members.detail.label.mail)} />
          <CustomTextLabelSkeleton label={t(tokens.domains.members.detail.label.job)} />
        </Stack>
        <Stack
          direction={'row'}
          spacing={3}
          marginLeft={'24px !important'}
        >
          <CustomTextLabelSkeleton label={t(tokens.domains.members.detail.label.fullAddress)} />
          <CustomTextLabelSkeleton label={t(tokens.domains.members.detail.label.legalForm)} />
          <CustomTextLabelSkeleton label={t(tokens.domains.members.detail.label.siret)} />
          <CustomTextLabel
            source=""
            label={''}
          />
        </Stack>
        <Stack
          direction={'row'}
          spacing={3}
          marginLeft={'24px !important'}
          sx={{
            width: '431px',
          }}
        >
          <CustomTextLabelSkeleton label={t(tokens.domains.members.detail.label.name)} />
        </Stack>
      </Stack>
      <Box
        display={'flex'}
        justifyContent={'end'}
        width={'100%'}
        margin="24px 0px"
      >
        <Skeleton
          variant="rounded"
          width={210}
          height={60}
          sx={{ marginRight: '60px' }}
        />
      </Box>
    </Card>
  );
};
