import { User } from '.';

export enum ActionType {
  INITIALIZE = 'INITIALIZE',
  SIGN_IN = 'SIGN_IN',
  SIGN_IN_2FA = 'SIGN_IN_2FA',
  SIGN_OUT = 'SIGN_OUT',
  REQUEST_PASSWORD = 'REQUEST_PASSWORD',
  RESET_PASSWORD = 'RESET_PASSWORD',
}

export interface InitializeAction {
  type: typeof ActionType.INITIALIZE;
  payload: { isAuthenticatedFully: boolean; user: User | null };
}

export interface SignInAction {
  type: typeof ActionType.SIGN_IN;
  payload: { isAuthenticatedTwoFactorInProgress: boolean };
}

export interface SignIn2FAAction {
  type: typeof ActionType.SIGN_IN_2FA;
  payload: { user: User };
}

export interface SignOutAction {
  type: typeof ActionType.SIGN_OUT;
}

export interface RequestForgotPasswordAction {
  type: typeof ActionType.REQUEST_PASSWORD;
}

export interface ResetForgotPasswordAction {
  type: typeof ActionType.RESET_PASSWORD;
  payload: { email: string; passwordReset: boolean };
}

export type AuthAction =
  | InitializeAction
  | SignInAction
  | SignIn2FAAction
  | SignOutAction
  | RequestForgotPasswordAction
  | ResetForgotPasswordAction;
