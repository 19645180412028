import { Container, Typography, Box } from '@mui/material';
import { t } from 'i18next';

import maintenanceImage from '/public/assets/maintenance/maintenance.png';

import { tokens } from 'src/locales/tokens';

const MaintenancePage: React.FC = () => {
  return (
    <Container
      maxWidth="md"
      style={{ textAlign: 'center', marginTop: '80px' }}
    >
      <Typography
        variant="h1"
        component="h1"
        fontWeight="bold"
        gutterBottom
        mt={[4, 12]}
      >
        {t(tokens.utils.maintenance.title)}
      </Typography>
      <Typography
        variant="h4"
        color="textSecondary"
        fontWeight="bold"
        gutterBottom
      >
        {t(tokens.utils.maintenance.description)}
      </Typography>
      <Box my={4}>
        <img
          src={maintenanceImage}
          alt="Maintenance"
          style={{ maxWidth: '100%' }}
        />
      </Box>
    </Container>
  );
};

export default MaintenancePage;
