import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { t } from 'i18next';
import React from 'react';
import { tokens } from 'src/locales/tokens';
import { blue, green, indigo, purple } from 'src/theme/colors';

interface OptionsColorPresetProps {
  onChange: (value: string) => void;
  value: string;
}

export const OptionsColorPreset: React.FC<OptionsColorPresetProps> = ({ onChange, value }) => {
  const options = [
    {
      label: t(tokens.settingsDrawer.color.green),
      value: 'green',
      color: green.main,
    },
    {
      label: t(tokens.settingsDrawer.color.blue),
      value: 'blue',
      color: blue.main,
    },
    {
      label: t(tokens.settingsDrawer.color.indigo),
      value: 'indigo',
      color: indigo.main,
    },
    {
      label: t(tokens.settingsDrawer.color.purple),
      value: 'purple',
      color: purple.main,
    },
  ];

  return (
    <Stack spacing={1}>
      <Typography
        color="text.secondary"
        variant="overline"
      >
        {t(tokens.settingsDrawer.color.title)}
      </Typography>
      <Stack
        alignItems="center"
        direction="row"
        flexWrap="wrap"
        gap={2}
      >
        {options.map((option) => (
          <Chip
            icon={
              <Box
                sx={{
                  backgroundColor: option.color,
                  borderRadius: '50%',
                  flexShrink: 0,
                  height: 24,
                  width: 24,
                }}
              />
            }
            key={option.value}
            label={option.label}
            onClick={() => onChange?.(option.value)}
            sx={{
              borderColor: 'transparent',
              borderRadius: 1.5,
              borderStyle: 'solid',
              borderWidth: 2,
              ...(option.value === value && {
                borderColor: 'primary.main',
              }),
            }}
          />
        ))}
      </Stack>
    </Stack>
  );
};
