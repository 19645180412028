export const DatePickerComponentStyles = {
  textField: {
    sx: {
      '.MuiInputBase-input': {
        padding: '10px',
        color: 'inherit',
      },
      '.MuiInputBase-root': {
        height: '100%',
        borderColor: 'neutral.200',
        '&:hover': {
          borderColor: 'neutral.200',
        },
        '&.Mui-focused': {
          borderColor: 'primary.main',
        },
      },
      '.MuiInputLabel-root': {
        color: 'inherit',
        top: 0,
        transform: 'translate(14px, 8px) scale(1) !important', // Position initiale du label
        '&.Mui-focused': {
          color: 'neutral.500',
          transform: 'translate(14px, -15px) scale(0.75) !important', // Position du label lorsqu'il est focalisé/réduit
        },
        '&.MuiInputLabel-shrink': {
          color: 'neutral.500',
          transform: 'translate(14px, -15px) scale(0.75) !important', // Positionnement réduit
        },
      },
      '.MuiSvgIcon-root': {
        color: 'neutral.500',
      },
    },
  },
};
