import createCache from '@emotion/cache';
import { CacheProvider } from '@emotion/react';
import React, { useEffect } from 'react';
import stylisRTLPlugin from 'stylis-plugin-rtl';

interface RTLProps {
  children: React.ReactNode;
  direction: string;
}

const styleCache = () =>
  createCache({
    key: 'rtl',
    prepend: true,
    stylisPlugins: [stylisRTLPlugin],
  });

export const RTL: React.FC<RTLProps> = (props) => {
  const { children, direction = 'ltr' } = props;

  useEffect(() => {
    document.dir = direction;
  }, [direction]);

  if (direction === 'rtl') {
    return <CacheProvider value={styleCache()}>{children}</CacheProvider>;
  }

  return <>{children}</>;
};
