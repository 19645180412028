import Autocomplete from '@mui/material/Autocomplete';
import { debounce } from '@mui/material/utils';
import { t } from 'i18next';
import React, { useCallback } from 'react';
import { TextInput } from 'react-admin';
import AddressSearchResult from 'src/common/components/react-admin/inputs/generic/search/address/AddressSearchResult';
import AddressSearchSkeleton from 'src/common/components/react-admin/inputs/generic/search/address/AddressSearchSkeleton';
import useFetchAddressData from 'src/domains/club/contract/hooks/useFetchAddressData';
import { AddressFeature } from 'src/domains/club/contract/types/postalAddress.types';
import { tokens } from 'src/locales/tokens';

interface AddressSearchInputProps {
  placeholder: string;
  source: string;
  onOptionChange: (option: AddressFeature) => void;
}

const AddressSearchInput: React.FC<AddressSearchInputProps> = ({
  onOptionChange,
  placeholder,
  source,
}) => {
  const { choices, isLoading, fetchAddressData, setChoices } = useFetchAddressData();
  const [inputValue, setInputValue] = React.useState('');
  const [value, setValue] = React.useState<AddressFeature>(null);

  const handleChange = useCallback(
    (newValue: AddressFeature) => {
      if (newValue) {
        setValue(newValue);
        setInputValue(newValue.properties.label);
        onOptionChange(newValue);
      } else {
        setValue(null);
        setInputValue('');
        onOptionChange(null);
      }
    },
    [onOptionChange, setInputValue]
  );

  const fetch = React.useMemo(
    () =>
      debounce((request: { input: string }) => {
        const { input } = request;
        void fetchAddressData(input);
      }, 400),
    [fetchAddressData]
  );

  React.useEffect(() => {
    if (inputValue === '') {
      setChoices(value ? [value] : []);
      return undefined;
    }

    fetch({ input: inputValue });
  }, [inputValue, fetch, setChoices]);

  return (
    <Autocomplete
      getOptionLabel={(option) => (typeof option === 'string' ? option : option.properties.label)}
      filterOptions={(x) => x}
      options={choices}
      autoComplete={false}
      loadingText={<AddressSearchSkeleton />}
      loading={isLoading}
      clearOnBlur={false}
      includeInputInList
      filterSelectedOptions
      noOptionsText={t(tokens.utils.form.noOptionsText)}
      onChange={(event: any, newValue: AddressFeature | null) => {
        handleChange(newValue);
      }}
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue);
      }}
      renderInput={(params) => (
        <TextInput
          {...params}
          source={source}
          label={false}
          InputLabelProps={{ shrink: true }}
          placeholder={placeholder}
          fullWidth
          autoComplete="off"
          sx={{
            width: '100%',
            minHeight: '56px !important',
            '& .MuiInputBase-root': {
              fontSize: '15px',
              display: 'flex',
              alignItems: 'center',
              paddingTop: '10px',
              paddingBottom: '10px',
            },
            '& .MuiAutocomplete-input': {
              fontSize: '15px',
              fontWeight: 400,
            },
          }}
        />
      )}
      renderOption={(props: { key: string; optionProps: object }, option: AddressFeature) => {
        const { key, ...optionProps } = props;
        return (
          <li
            key={key}
            {...optionProps}
          >
            <AddressSearchResult address={option} />
          </li>
        );
      }}
    />
  );
};

export default AddressSearchInput;
