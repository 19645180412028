import { AccessRightsAction } from 'src/types/enums';
import { AccessRightsReducerAction, AccessRightsReducerState } from 'src/types/reducers';

export const accessRightsReducerInitialState: AccessRightsReducerState = {
  initialAccessRights: null,
  accessRights: null,
  initialAccessRightsToMap: null,
  hasFetchedInitialRights: false,
  isFetchingAccessRights: false,
  hasUpdatedAccessRights: false,
};

export const accessRightsReducer = (
  state: AccessRightsReducerState,
  action: AccessRightsReducerAction
): AccessRightsReducerState => {
  switch (action.type) {
    case AccessRightsAction.INIT:
      return {
        ...state,
        ...action.payload,
      };
    case AccessRightsAction.SET_INITIAL_ACCESS_RIGHTS:
      return { ...state, initialAccessRights: action.payload };
    case AccessRightsAction.SET_ACCESS_RIGHTS:
      return { ...state, accessRights: action.payload };
    case AccessRightsAction.SET_INITIAL_ACCESS_RIGHTS_TO_MAP:
      return { ...state, initialAccessRightsToMap: action.payload };
    case AccessRightsAction.SET_HAS_FETCHED_INITIAL_RIGHTS:
      return { ...state, hasFetchedInitialRights: action.payload };
    case AccessRightsAction.SET_IS_FETCHING_ACCESS_RIGHTS:
      return { ...state, isFetchingAccessRights: action.payload };
    case AccessRightsAction.SET_HAS_UPDATED_ACCESS_RIGHTS:
      return { ...state, hasUpdatedAccessRights: action.payload };
    default:
      return state;
  }
};
