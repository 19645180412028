import { ArrowBack } from '@mui/icons-material';
import { Alert, Card } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import SvgIcon from '@mui/material/SvgIcon';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { SimpleForm, TextInput, SelectInput, CreateProps } from 'react-admin';
import { Link as RouterLink } from 'react-router-dom';
import { Seo } from 'src/components/Seo';
import { useCreate } from 'src/hooks/useCreate';

import CreateBaseWrapper from './layouts/CreateBaseWrapper';

const formatChoices = [
  { id: 'monthly', name: 'Mensuel' },
  { id: 'fortnightly', name: 'Bimensuel' },
  { id: 'weekly', name: 'Hebdomadaire' },
];

export const ClubCreate: React.FC = (props: CreateProps) => {
  const { successMessage, errorMessage, transform, onSuccess, onError } = useCreate();

  return (
    <>
      <Seo title="Créer un club" />
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          py: 8,
        }}
      >
        <Container maxWidth="xl">
          <Stack spacing={4}>
            <Typography variant="h4">Créer un club</Typography>
            <Link
              component={RouterLink}
              to="/clubs"
              sx={{ alignItems: 'center', display: 'inline-flex' }}
            >
              <SvgIcon sx={{ mr: 1 }}>
                <ArrowBack />
              </SvgIcon>
              <Typography variant="subtitle2">Retour aux clubs</Typography>
            </Link>
            <Stack alignItems="center">
              <Card sx={{ p: 4, width: '500px', boxShadow: 3 }}>
                <CreateBaseWrapper
                  {...props}
                  transform={transform}
                  onSuccess={onSuccess}
                  onError={onError}
                >
                  <SimpleForm toolbar={<></>}>
                    <Typography
                      sx={{ marginBottom: '20px' }}
                      variant="h6"
                    >
                      Créer un nouveau club
                    </Typography>
                    <TextInput
                      source="name"
                      label="Nom du club"
                      fullWidth
                    />
                    <TextInput
                      source="agencyResource"
                      label="Ressource de l'agence"
                      fullWidth
                    />
                    <SelectInput
                      source="format"
                      label="Format"
                      choices={formatChoices}
                      fullWidth
                    />
                    <TextInput
                      source="originId"
                      label="ID Origine"
                      fullWidth
                    />
                    <Stack
                      direction="row"
                      spacing={2}
                      sx={{ paddingTop: '16px' }}
                    >
                      <Toolbar sx={{ padding: '0px !important' }}>
                        <Button
                          variant="contained"
                          type="submit"
                          sx={{ marginRight: '16px' }}
                        >
                          Créer
                        </Button>
                        <Button
                          variant="contained"
                          color="error"
                          component={RouterLink}
                          to="/clubs"
                        >
                          Annuler
                        </Button>
                      </Toolbar>
                    </Stack>
                    {successMessage && <Alert severity="success">{successMessage}</Alert>}
                    {errorMessage && <Alert severity="error">{errorMessage}</Alert>}
                  </SimpleForm>
                </CreateBaseWrapper>
              </Card>
            </Stack>
          </Stack>
        </Container>
      </Box>
      <Box
        component="main"
        sx={{ flexGrow: 1, py: 8 }}
      >
        <Container maxWidth="xl">
          <Box
            display="flex"
            justifyContent="center"
          ></Box>
        </Container>
      </Box>
    </>
  );
};
