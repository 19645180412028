import InputAdornment from '@mui/material/InputAdornment';
import OutlinedInput from '@mui/material/OutlinedInput';
import SvgIcon from '@mui/material/SvgIcon';
import SearchMdIcon from '@untitled-ui/icons-react/build/esm/SearchMd';
import { t } from 'i18next';
import React, { useEffect } from 'react';
import { useListFilterContext } from 'react-admin';
import { tokens } from 'src/locales/tokens';
import { Filters } from 'src/types/contexts/modals';
import { FilterValues } from 'src/types/enums';

interface ListSearchProps {
  filterName: string;
  filterValues: FilterValues;
  placeholder?: string;
}

export const SearchFilter: React.FC<ListSearchProps> = ({
  filterName,
  filterValues,
  placeholder = t(tokens.placeholder.globalSearch),
}) => {
  const [search, setSearch] = React.useState<string>('');
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const { setFilters } = useListFilterContext();

  useEffect(() => {
    if (filterValues[filterName]) {
      setSearch(filterValues[filterName] as unknown as string);
    } else {
      setSearch('');
    }
  }, [filterValues]);

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(event.target.value);
    const updatedFilterValues = { ...filterValues, [filterName]: event.target.value } as Filters;
    setFilters(updatedFilterValues, {});
  };

  const handleKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === 'Enter') {
      event.preventDefault();
    }
  };

  return (
    <OutlinedInput
      sx={{
        height: '56px',
        width: '447px',
      }}
      onChange={handleSearch}
      onKeyDown={handleKeyDown}
      value={search}
      fullWidth
      type="text"
      id="standard-basic"
      placeholder={placeholder}
      startAdornment={
        <InputAdornment position="start">
          <SvgIcon>
            <SearchMdIcon />
          </SvgIcon>
        </InputAdornment>
      }
    />
  );
};
