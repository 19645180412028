import { AccessRights } from 'src/types/pages/users';

/**
 * Converts an array of access rights into a nested Map structure.
 * @param rights - The array of access rights.
 * @returns A Map containing the converted access rights.
 *
 * @example
 * Input:
 * const initialAccessRights = [
 *   {
 *     label: "Groupe 1",
 *     accessRights: [
 *       { '@id': '1', label: "Droit 1", code: "code1", allowed: true },
 *       { '@id': '2', label: "Droit 2", code: "code2", allowed: false }
 *     ]
 *   },
 *   {
 *     label: "Groupe 2",
 *     accessRights: [
 *       { '@id': '3', label: "Droit 3", code: "code3", allowed: true }
 *     ]
 *   }
 * ];
 *
 * Output:
 * Map {
 *   "Groupe 1" => Map {
 *     "1" => { '@id': '1', label: "Droit 1", code: "code1", allowed: true },
 *     "2" => { '@id': '2', label: "Droit 2", code: "code2", allowed: false }
 *   },
 *   "Groupe 2" => Map {
 *     "3" => { '@id': '3', label: "Droit 3", code: "code3", allowed: true }
 *   }
 * }
 */
export const convertAccessRightsToMap = (
  rights: AccessRights | null
): Map<string, Map<string, { label: string; code: string; allowed: boolean }>> => {
  const rightsMap = new Map();
  if (!rights) return rightsMap;
  rights.forEach((group) => {
    const accessRightsMap = new Map();
    group.accessRights.forEach((right) => {
      accessRightsMap.set(right['@id'], right);
    });
    rightsMap.set(group.label, accessRightsMap);
  });

  return rightsMap;
};
