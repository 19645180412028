import React from 'react';
import { CreateBase } from 'react-admin';

interface CreateBaseWrapperProps<T> {
  transform?: (newData: Partial<T>) => Partial<T>;
  onSuccess?: () => void;
  onError?: () => void;
  children?: React.ReactNode;
}

export const CreateBaseWrapper = <T,>({
  transform,
  onSuccess,
  onError,
  children,
  ...props
}: React.PropsWithChildren<CreateBaseWrapperProps<T>>) => {
  return (
    <CreateBase
      {...props}
      transform={transform}
      redirect={false}
      mutationOptions={{ onSuccess, onError }}
    >
      {children}
    </CreateBase>
  );
};

export default CreateBaseWrapper;
