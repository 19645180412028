export enum MemberColumnSourceEnum {
  NAME = 'name',
  FORMAT = 'format',
  ORGANIZATION = 'organizationName',
  CLUB_NAME = 'clubName',
  TYPE = 'president',
  STATUS = 'clubStatus',
  CREATED_AT = 'createdAt',
  SIGNED_AT = 'signedAt',
  DISABLED_AT = 'disabledAt',
  DEPARTMENT = 'department',
}
