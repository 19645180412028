import { t } from 'i18next';
import { Member } from 'src/admin-api-types';
import { tokens } from 'src/locales/tokens';

import { CustomTextLabel } from '../fields/CustomTextLabel';
interface MemberDetailProps {
  record: Member;
}

export const MemberDetail: React.FC<MemberDetailProps> = ({ record }) => {
  return (
    <>
      <CustomTextLabel
        value={record.user?.fullName || ''}
        label={t(tokens.domains.members.detail.label.name)}
      />
      <CustomTextLabel
        value={record.user?.phone || ''}
        label={t(tokens.domains.members.detail.label.phone)}
      />
      <CustomTextLabel
        value={record.user?.email || ''}
        label={t(tokens.domains.members.detail.label.mail)}
      />
      <CustomTextLabel
        value={record.user?.jobTitle || ''}
        label={t(tokens.domains.members.detail.label.job)}
      />
    </>
  );
};
