import { useCallback } from 'react';
import dataProvider from 'src/data/provider/remote/dataProvider';
import { AsyncStatusAction } from 'src/types/enums';
import { createAndTriggerDownload } from 'src/utils/createAndTriggerDownload';

import { useAsyncFeedbackContext } from '../common/hooks/useAsyncFeedbackContext';

import { useListExportModalContext } from '../common/modals/hooks/useListExportModalContext';

export const useFileDownloader = () => {
  const { setIsExportDownloaded } = useListExportModalContext();
  const { dispatch, clearMessages, defineError, defineSuccess, defineLoading } =
    useAsyncFeedbackContext();

  const downloadFile = useCallback(
    async (url: string, filename: string) => {
      clearMessages();
      dispatch({
        type: AsyncStatusAction.INIT,
      });
      setIsExportDownloaded(false);
      defineLoading('Téléchargement en cours...');
      dispatch({ type: AsyncStatusAction.IS_LOADING, payload: true });
      try {
        const downloadUrl = await dataProvider.downloadExport(url);
        if (downloadUrl) {
          createAndTriggerDownload(downloadUrl, filename);
          setIsExportDownloaded(true);
          defineSuccess('Le téléchargement a réussi.');
          dispatch({ type: AsyncStatusAction.IS_SUCCESS, payload: true });
        } else {
          console.error('No download URL returned.');
        }
      } catch (error) {
        console.error(error);
        defineError(error as string);
        dispatch({ type: AsyncStatusAction.IS_ERROR, payload: true });
      } finally {
        dispatch({ type: AsyncStatusAction.IS_LOADING, payload: false });
      }
    },
    [clearMessages, defineError, defineLoading, defineSuccess, dispatch, setIsExportDownloaded]
  );

  return downloadFile;
};
