import { Box, InputLabel, Skeleton } from '@mui/material';
import React from 'react';

interface CustomTextLabelSkeletonProps {
  label: string;
}

export const CustomTextLabelSkeleton: React.FC<CustomTextLabelSkeletonProps> = ({ label }) => {
  return (
    <Box
      sx={{
        width: '100%',
      }}
    >
      <InputLabel
        sx={{
          paddingBottom: '8px',
          color: 'neutral.900',
          fontWeight: 'medium',
        }}
      >
        {label}
      </InputLabel>
      <Skeleton
        variant="text"
        sx={{ fontSize: '18px', height: '25px', width: '80%' }}
      />
    </Box>
  );
};
