import { Alert, Box, Stack, Typography } from '@mui/material';
import { t } from 'i18next';
import React from 'react';
import { CheckboxGroupInput, RadioButtonGroupInput } from 'react-admin';
import { SectionLayout } from 'src/domains/club/contract/layouts/SectionLayout';
import { civilityOption } from 'src/domains/club/contract/steps/stepTwo/utils';
import { CustomInputLabel } from 'src/domains/club/members/detail/fields/CustomInputLabel';
import { tokens } from 'src/locales/tokens';

import { PresidentAlert } from '../../../components/PresidentAlert';

export const Signatory: React.FC = ({}) => {
  return (
    <SectionLayout title={t(tokens.domains.contract.stepTwo.signatory.title)}>
      <Alert
        severity="warning"
        sx={{ fontWeight: 'medium', margin: '0px 24px 24px' }}
      >
        <PresidentAlert />
      </Alert>
      <Box
        sx={{
          padding: '0px 24px',
        }}
      >
        <Typography
          variant={'body2'}
          fontWeight={600}
          gutterBottom
          marginBottom="0px"
        >
          {t(tokens.domains.contract.stepTwo.signatory.civility.label) + ' *'}
        </Typography>
        <RadioButtonGroupInput
          source="genre"
          name="genre"
          label=""
          choices={civilityOption}
          sx={{
            marginTop: '0px',
            '& .MuiFormControlLabel-label': {
              fontSize: '14px',
            },
          }}
        />
      </Box>
      <Stack flexDirection={'row'}>
        <Stack
          flexGrow="1"
          padding={'0px 24px 30px 24px'}
          spacing={4}
          flexBasis={'50%'}
        >
          <Stack
            direction={'row'}
            spacing={3}
            gap="55px"
          >
            <CustomInputLabel
              source="firstName"
              name="firstName"
              label={t(tokens.domains.contract.stepTwo.signatory.signatoryFirstName.label) + ' *'}
              placeholder={t(
                tokens.domains.contract.stepTwo.signatory.signatoryFirstName.placeholder
              )}
            />
            <CustomInputLabel
              source="lastName"
              name="lastName"
              label={t(tokens.domains.contract.stepTwo.signatory.signatoryLastName.label) + ' *'}
              placeholder={t(
                tokens.domains.contract.stepTwo.signatory.signatoryLastName.placeholder
              )}
            />
          </Stack>
          <Stack
            direction={'row'}
            spacing={3}
            gap="55px"
          >
            <CustomInputLabel
              source="email"
              name="email"
              label={t(tokens.domains.contract.stepTwo.signatory.signatoryEmail.label) + ' *'}
              placeholder={t(tokens.domains.contract.stepTwo.signatory.signatoryEmail.placeholder)}
            />
            <CustomInputLabel
              source="number"
              name="number"
              label={t(tokens.domains.contract.stepTwo.signatory.signatoryPhone.label) + ' *'}
              placeholder={t(tokens.domains.contract.stepTwo.signatory.signatoryPhone.placeholder)}
            />
          </Stack>
          <Stack
            direction={'row'}
            spacing={3}
            gap="55px"
            marginTop={'16px !important'}
          >
            {/* <FormGroup
              sx={{
                marginTop: '0px !important',
                '& .MuiFormControlLabel-label': {
                  fontSize: '14px',
                },
              }}
            >
              <FormControlLabel
                control={<Checkbox />}
                label={t(tokens.domains.contract.stepTwo.signatory.signatoryEmail.emailVisibility)}
                checked={isEmailDisplayedChecked}
                onChange={() => {
                  setisEmailDisplayedChecked(!isEmailDisplayedChecked);
                }}
              />
            </FormGroup> */}
            <CheckboxGroupInput
              source="emailDisplayed"
              label={false}
              choices={[
                {
                  id: 'admin',
                  name: t(tokens.domains.contract.stepTwo.signatory.signatoryEmail.emailVisibility),
                },
              ]}
              sx={{
                marginTop: '0px !important',
                '& .MuiFormControlLabel-label': {
                  fontSize: '14px',
                },
              }}
            />
            {/* <FormGroup
              sx={{
                marginTop: '0px !important',
                '& .MuiFormControlLabel-label': {
                  fontSize: '14px',
                },
              }}
            >
              <FormControlLabel
                control={<Checkbox />}
                label={t(tokens.domains.contract.stepTwo.signatory.signatoryPhone.phoneVisibility)}
                checked={isPhoneDisplayedChecked}
                onChange={() => {
                  setisPhoneDisplayedChecked(!isPhoneDisplayedChecked);
                }}
              />
            </FormGroup> */}
            <CheckboxGroupInput
              source="phoneDisplayed"
              label={false}
              choices={[
                {
                  id: 'admin',
                  name: t(tokens.domains.contract.stepTwo.signatory.signatoryPhone.phoneVisibility),
                },
              ]}
              sx={{
                marginTop: '0px !important',
                '& .MuiFormControlLabel-label': {
                  fontSize: '14px',
                },
              }}
            />
          </Stack>
        </Stack>
      </Stack>
    </SectionLayout>
  );
};
