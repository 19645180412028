import React from 'react';
import { TopLabelReferenceInput } from 'src/components/react-admin/core/inputs';
import AdminResourceEnum from 'src/data/enum/remote/admin/adminResourceEnum';

interface AdminUserSearchInputProps {
  source: string;
  label: string;
  filterToQuery?: (searchText: string) => object;
}

export const AdminUserSearchInput: React.FC<AdminUserSearchInputProps> = ({
  source,
  label,
  filterToQuery,
}) => {
  return (
    <TopLabelReferenceInput
      reference={AdminResourceEnum.ADMIN_USER}
      source={source}
      label={label}
      name={source}
      filterToQuery={filterToQuery ? filterToQuery : (search: string) => ({ fullname: search })}
    />
  );
};
