import { t } from 'i18next';
import { useCallback, useEffect, useState } from 'react';
import dataProvider from 'src/data/provider/remote/dataProvider';
import { tokens } from 'src/locales/tokens';
import { ListExport } from 'src/types/contexts/modals';
import { ExportStatusEnum } from 'src/types/enums';
import { AsyncStatusAction } from 'src/types/enums/async-status-action-enum';
import { AsyncStatusReducerAction } from 'src/types/reducers/async-status-reducer-types';

import { useAsyncMessage } from '../../hooks/useAsyncMessage';

import { useListExportModalContext } from './useListExportModalContext';

export const useExportList = (
  dispatch: (value: AsyncStatusReducerAction) => void,
  resource: string
) => {
  const [exportHistory, setExportHistory] = useState<ListExport[] | undefined>(undefined);
  const { errorMessage, defineError } = useAsyncMessage();
  const {
    isOpen,
    isExportCreatedSuccessfully,
    setIsExportCreatedSuccessfully,
    isExportDownloaded,
    isExportRetried,
    isExportCanceled,
  } = useListExportModalContext();

  const getExport = useCallback(async () => {
    dispatch({ type: AsyncStatusAction.IS_LOADING, payload: true });
    try {
      const response = await dataProvider.getExport(resource);
      dispatch({ type: AsyncStatusAction.IS_SUCCESS, payload: true });
      setExportHistory(response);
      dispatch({ type: AsyncStatusAction.IS_LOADING, payload: false });
      setIsExportCreatedSuccessfully(false);
    } catch (error) {
      defineError(t(tokens.asyncMessages.CSVImportFailed), error);
      dispatch({ type: AsyncStatusAction.IS_LOADING, payload: false });
      dispatch({ type: AsyncStatusAction.IS_ERROR, payload: true });
    }
  }, [defineError, dispatch, resource, setIsExportCreatedSuccessfully]);

  useEffect(() => {
    if (isExportCreatedSuccessfully || isExportDownloaded || isExportRetried || isExportCanceled) {
      void getExport();
    }
  }, [
    isExportCreatedSuccessfully,
    getExport,
    isExportRetried,
    isExportCanceled,
    isExportDownloaded,
  ]);

  useEffect(() => {
    if (isOpen && !exportHistory) {
      void getExport();
    }
  }, [isOpen, getExport, exportHistory]);

  useEffect(() => {
    let intervalId: NodeJS.Timeout;

    const shouldPoll =
      isOpen &&
      exportHistory &&
      exportHistory.some(
        (item) =>
          item.status === ExportStatusEnum.Waiting || item.status === ExportStatusEnum.In_Progress
      );

    if (shouldPoll && exportHistory) {
      intervalId = setInterval(() => {
        void getExport();
      }, 2000);
    }

    return () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
  }, [isOpen, getExport, exportHistory]);

  return { exportHistory, errorMessage };
};
