import React, { ReactNode } from 'react';

import { useContractState } from '../hooks/useContractState';

import { contractContext } from './contractContext';

export const ContractProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const state = useContractState();

  return <contractContext.Provider value={state}>{children}</contractContext.Provider>;
};
