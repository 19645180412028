import { t } from 'i18next';
import { Header } from 'src/common/components/grid/Header';
import { ListLayout } from 'src/common/layouts/react-admin/ListLayout';
import { tokens } from 'src/locales/tokens';

import { ClubListTable } from './components/ClubListTable';

export const ClubList = () => {
  return (
    <ListLayout seoTitle={t(tokens.seo.club.title)}>
      <Header
        title={t(tokens.domains.clubs.clubHeaderTitle)}
        resource="clubs"
        hasExport={true}
      />
      <ClubListTable />
    </ListLayout>
  );
};
