import { GetListParams } from 'react-admin';

/**
 * Adds new filters to the existing filter object in the GetListParams.
 * @param params - The original GetListParams object.
 * @param newFilters - The new filters to be added.
 * @returns The modified GetListParams object with the new filters added.
 */
const addFilters = (
  params: GetListParams,
  newFilters: { [filterName: string]: string | number | boolean }
): GetListParams => {
  const modifiedParams: GetListParams = {
    ...params,
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    filter: { ...params.filter, ...newFilters },
  };

  return modifiedParams;
};

type ResourceConfigType = {
  [resource: string]: ((params: GetListParams) => GetListParams)[];
};

/**
 * Configuration for dataprovider resources.
 * @example
 * ```typescript
 * ['ressourceName']: [
 *   (params: GetListParams) => addFilters(params, { newFilter1: 'filterValue1' }),
 *   (params: GetListParams) => addFilters(params, { newFilter2: 'filterValue2' }),
 * ],
 * ```
 */
export const resourceConfig: ResourceConfigType = {
  ['ressourceName']: [
    (params: GetListParams) => addFilters(params, { newFilter1: 'filterValue1' }),
    (params: GetListParams) => addFilters(params, { newFilter2: 'filterValue2' }),
  ],
};
